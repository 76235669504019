<script setup>
import {defineModel, defineProps, watch} from "vue";
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";
import DownloadableItem from "@/components/content/ContentDownloadableItem.vue";
import ImageBox from "@/components/content/ContentImageBox.vue";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import Word from "@/components/glossary/GlossaryWordTooltip.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore()

const readChapters = defineModel()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;

watch(UserProgression.glossaryWords, () => {
    if(!UserProgression.isAchievementUnlocked(15) &&
        UserProgression.allLevelGlossaryWordFound(operaIndex, levelIndex)){
        UserProgression.unlockAchievementById(15);
        UserNotifications.pushNotification("achievement", 15);
    }
})
</script>

<template>

    <Title>La firma del contratto da cui nascerà <i>La Cenerentola</i>.</Title>
    <Textbox>
        La storia della <i>Cenerentola</i> comincia il 29 febbraio 1816, quando <Word :word-id="3">Rossini</Word>,
        trovandosi a Roma per allestire
        il <Word :word-id="96"><i>Barbiere di Siviglia</i></Word>, firmò un contratto con
        <Word :word-id="89">Pietro Cartoni</Word>, impresario del <Word :word-id="26">Teatro Valle</Word>.
    </Textbox>
    <Textbox>
        Nel contratto, <Word :word-id="3">Rossini</Word> si impegnava a comporre un’<Word :word-id="2">opera</Word>
        da rappresentare il 26 dicembre,
        in apertura della stagione di <Word :word-id="31">Carnevale</Word>.
    </Textbox>
    <Textbox>
        Il <Word :word-id="6">libretto</Word>, in base a quanto pattuito, sarebbe stato sottoposto da
        <Word :word-id="89">Cartoni</Word> a <Word :word-id="3">Rossini</Word> nel mese di ottobre.
    </Textbox>

    <ImageBox>
        <img src="/assets/operas/cenerentola/level-1/content-image-2-O2L1C0.png" alt="" />
    </ImageBox>

    <Title right>
        Il solito ritardo sulla tabella di marcia.
    </Title>
    <Textbox>
        Le scadenze previste nel contratto, però, non vennero rispettate.
    </Textbox>
    <Textbox>
        Dal canto suo, <Word :word-id="89">Cartoni</Word> aveva tenuto fede al proprio impegno facendo pervenire
        a <Word :word-id="3">Rossini</Word> il <Word :word-id="6">libretto</Word> da <Word :word-id="90">Gaetano Rossi</Word> nei tempi prestabiliti.
    </Textbox>
    <ImageBox class="h-80">
        <img src="/assets/operas/cenerentola/level-1/content-image-3-O2L1C0.png" alt="" />
    </ImageBox>
    <Textbox>
        Il compositore, invece, pressato dagli impegni napoletani, non solo non si presentò all’appuntamento fissato
        a Roma a fine ottobre, ma neppure il 3 dicembre, come preannunciato all’impresario in una lettera dell’ 8 novembre.
    </Textbox>

    <Title>
        <Word :word-id="89">Cartoni</Word> fu costretto a far intervenire la Polizia.
    </Title>
    <Textbox>
        <Word :word-id="3">Rossini</Word> non mostrava segno di decidersi per la trasferta romana,
        costringendo così <Word :word-id="89">Cartoni</Word> a far intervenire
        la Polizia in modo tale che il compositore rispettasse gli impegni contrattuali.
    </Textbox>

    <Title right>
        L’idea di cambiare soggetto all’ultimo minuto.
    </Title>
    <Textbox>
        Giunto a Roma, verso Natale, a pochi giorni da quella che avrebbe dovuto essere la data della prima
        rappresentazione dell’<Word :word-id="2">opera</Word>, nacque subito  il problema del soggetto.
    </Textbox>
    <ImageBox align="right">
        <img src="/assets/operas/cenerentola/level-1/content-image-4-O2L1C0.png" alt="" />
    </ImageBox>
    <Textbox>
        Su quanto avvenne nei giorni successivi, abbiamo testimonianza nello scritto autobiografico di
        <Word :word-id="25">Jacopo Ferretti</Word>,
        <i>Alcune pagine della mia vita</i>, dove  il librettista racconta che incontrò, con <Word :word-id="3">Rossini</Word>
        e <Word :word-id="89">Cartoni</Word>,
        il censore ecclesiastico per rendere accettabile il <Word :word-id="6">libretto</Word>
        prescelto, <i>Ninetta alla corte</i> di <Word :word-id="90">Gaetano Rossi</Word>,
        tratto dalla <Word :word-id="97"><i>Francesca di Foix</i></Word>, “una delle meno morali commedie del teatro francese”.
    </Textbox>
    <Textbox>
        Le modifiche proposte, però, avrebbero snaturato troppo la versione originale, così si decise di ripartire
        da zero e affidare l’individuazione di un nuovo soggetto a <Word :word-id="25">Ferretti</Word>.
    </Textbox>
    <Textbox>
        Come racconta il librettista, “io proposi un venti o trenta soggetti da melodramma [...] stanco dal proporre
        e mezzo cascante dal sonno, sillabai in mezzo ad uno sbadiglio <Word :word-id="98"><i>Cendrillon</i></Word>” e
        <Word :word-id="3">Rossini</Word> dimostrò la sua approvazione “rizzandosi su come il Farinata dell’Alighieri”.
    </Textbox>

    <Title right>
        Un’opera da scrivere in tempi incredibilmente brevi.
    </Title>
    <Textbox>
        La stesura del <Word :word-id="6">libretto</Word> e della musica avvenne in tempi molto ristretti.
    </Textbox>
    <Textbox>
        Man mano che venivano consegnati i versi a <Word :word-id="3">Rossini</Word>, il Maestro componeva la musica,
        in un processo creativo frenetico.
    </Textbox>
    <Textbox>
        A conferma di ciò, si può citare la premessa stampata nella prima edizione del <Word :word-id="6">libretto</Word>
        che <Word :word-id="25">Ferretti</Word> indirizza “ai miei cortesi fratelli drammatici”.
    </Textbox>
    <ImageBox >
        <img src="/assets/operas/cenerentola/level-1/content-image-5-O2L1C0.png" alt="" />
    </ImageBox>
    <Textbox>
        Qui, il librettista descrive <i>La Cenerentola</i> come una “figlia inaspettata e lavoro di pochi giorni” e,
        preoccupato che dalla velocità di stesura potessero essere scaturiti errori, sottolinea come
        “La precipitanza in cui fu di mestieri scegliere l’argomento, e sceneggiarlo, per presentarlo di tratto
        in tratto versificato al Maestro avrà forse tolto la possibilità di evitare qualcuno de’ soliti difetti
        de’ Drammi Buffi”.
    </Textbox>
    <FunFact>
        <template #title>
            Debutto fallimentare o successo immediato?
        </template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/cenerentola/level-1/funfact-image-3-O2L0C2.png" alt="" />
                </template>
                <template #image-description>
                    <p>
                        Se diamo retta a <Word theme="white" :word-id="25">Ferretti</Word>, il debutto della <i>Cenerentola</i>
                        al <Word theme="white" :word-id="26">Teatro</Word> Valle di Roma del 25 gennaio 1817 fu un fallimento, più che un successo.
                    </p>
                    <p>
                        Il poeta, infatti, racconta di una ”tempestosissima sera”,
                        descrivendo un’atmosfera simile a quella dell’esordio del
                        <Word theme="white" :word-id="96"><i>Barbiere di Siviglia</i></Word>.
                    </p>
                </template>
            </FunfactParagraph>
            <Textbox>
                Diverse testimonianze attendibili, però, vanno nella direzione opposta e  decretano il successo
                immediato ottenuto dalla <i>Cenerentola</i>.
            </Textbox>
            <Textbox>
                Di certo i cantanti arrivarono stanchi dalla frettolosa preparazione di
                un’<Word theme="white" :word-id="2">opera</Word> tanto impegnativa,
                come scrive lo stesso <Word theme="white" :word-id="3">Rossini</Word>; ciononostante l’opera fu replicata una ventina di volte e cominciò
                subito una marcia trionfale attraverso l’Italia, e poi in Europa.
            </Textbox>
            <Textbox>
                Da dove deriva allora  il risentimento di <Word theme="white" :word-id="25">Ferretti</Word>?
            </Textbox>
            <Textbox>
                È probabile che fosse stato innescato dalle critiche mosse al suo <Word theme="white" :word-id="6">libretto</Word>
                , le quali lo portarono a sviluppare una sensazione di negatività generalizzata,
                oltre che da un carattere piuttosto difficile
                (aveva già litigato con <Word theme="white" :word-id="3">Rossini</Word> ai tempi del <Word theme="white" :word-id="96"><i>Barbiere</i></Word>).
            </Textbox>
            <DownloadableItem n-pages="3" src="https://io-bunny-cdn.b-cdn.net/ROF/documents/Lettera_Successo_Cenerentola.pdf">
                <template #title>Scarica la lettera in cui <Word theme="white" :word-id="3">Rossini</Word> racconta il successo al debutto della <i>Cenerentola!</i></template>
                <template #description></template>
            </DownloadableItem>
        </template>
    </FunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
