<script setup>
import {defineModel, defineProps} from "vue";
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import ContentImageBox from "@/components/content/ContentImageBox.vue";
import NextChapter from "@/components/content/ContentNextChapterCard.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import ContentFunFact from "@/components/content/ContentFunFact.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()
</script>

<template>
    <Title>Rossini nasce a Pesaro il 29 febbraio 1792.</Title>
    <Textbox>
        La madre, <GlossaryWord :word-id="55">Anna Guidarini</GlossaryWord>, inizialmente sarta,
        svolge successivamente una breve carriera come cantante,
        mentre il padre, Giuseppe Rossini, soprannominato "Vivazza" per il suo temperamento esuberante,
        è un suonatore di tromba e corno nella banda cittadina e nei teatri.
    </Textbox>
    <ContentImageBox>
        <img src="/assets/operas/rossini/content-image-0-O1L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>
    <Title>Durante l'infanzia segue i genitori nei teatri.</Title>
    <Textbox>
        Rossini vive un'infanzia movimentata, non solo perché segue i genitori negli impegni, ma anche a causa
        delle disavventure politiche del padre, che viene estradato da Pesaro e incarcerato per dieci mesi.
    </Textbox>
    <Title>Nel 1800 inizia i suoi studi musicali a Bologna. </Title>
    <Textbox>
        Rossini inizia la sua formazione con <GlossaryWord :word-id="56">Giuseppe Prinetti</GlossaryWord>,
        che gli impartisce lezioni di musica su una <GlossaryWord :word-id="57">spinetta</GlossaryWord>.
    </Textbox>
    <Textbox>
        Poco dopo, i Rossini si trasferiscono a Lugo, dove Gioachino studia basso cifrato
        e composizione con i <GlossaryWord :word-id="58">fratelli
        Malerbi</GlossaryWord> e si avvicina alle opere di Haydn e Mozart.
    </Textbox>
    <ContentImageBox height="h-100" align="right">
        <img src="/assets/operas/rossini/content-image-1-O1L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>
    <Title>
        Nel 1806 viene ammesso al Liceo Musicale di Bologna.
    </Title>
    <Textbox>
        Studia violoncello, pianoforte e contrappunto sotto la guida severa di
        <GlossaryWord :word-id="59">padre Stanislao Mattei</GlossaryWord>.
    </Textbox>
    <ContentFunFact theme="green" hidden-legacy-p>
        <template #title>Ai tempi del Liceo Rossini era soprannominato “il Tedeschino”.</template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/rossini/funfact-image-0-O1L0C0.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Quando era studente al Liceo Musicale di Bologna, Rossini fu soprannominato "il Tedeschino"
                        dai suoi compagni di scuola, per la sua grande passione per le composizioni di Haydn e Mozart:
                        tanto da farsele prestare per copiarle e studiarle approfonditamente.
                    </p>
                </template>
            </FunfactParagraph>
        </template>
        <template #bottom-description>
            <Textbox>
                Così raccontava a Richard Wagner: “Un amatore di musica bolognese mi dava in prestito alcune partiture
                di musica tedesca come <i class="italic">La Creazione</i>, <i class="italic">Le nozze di Figaro</i>,
                <i class="italic">Il flauto magico</i> … Poiché, a quindici anni,
                io non avevo i mezzi per farmele arrivare dalla Germania, le copiavo con accanimento. Vi dirò che
                sovente mi capitava di non trascrivere lì per lì che le parti vocali, senza esaminare l’accompagnamento
                orchestrale. Allora, sopra un foglio volante, io immaginavo un accompagnamento di testa mia, che poi
                raffrontavo con quelli di Haydn e di Mozart, aggiungendo i quali completavo in seguito la mia copia.”
            </Textbox>
        </template>
    </ContentFunFact>

    <Title>
        Nel 1810 debutta a Venezia.
    </Title>

    <Textbox>
        Costretto a interrompere gli studi per le difficoltà economiche della famiglia, Rossini, scritturato dal
        Teatro <GlossaryWord :word-id="66">San Moisè di Venezia</GlossaryWord>, ottiene
        successo con la <GlossaryWord :word-id="60">farsa comica</GlossaryWord>
        <GlossaryWord :word-id="61"><i class="italic">La cambiale di matrimonio</i></GlossaryWord>.
    </Textbox>

    <ContentImageBox height="h-140">
        <img src="/assets/operas/rossini/content-image-2-O1L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>

    <Title>
        Tra il 1812 e il 1814 registra successi e insuccessi.
    </Title>
    <Textbox>
        In questi anni compone <GlossaryWord :word-id="62"><i class="italic">L'inganno felice</i></GlossaryWord>
        e <GlossaryWord :word-id="27"><i class="italic">L’Italiana in Algeri</i></GlossaryWord>.
    </Textbox>
    <Textbox>
        Non tutte le sue opere, però, vengono apprezzate dal pubblico:
        <GlossaryWord :word-id="47"><i class="italic">Aureliano in Palmira</i></GlossaryWord> e
        <GlossaryWord :word-id="63"><i class="italic">Il Turco in Italia</i></GlossaryWord>,
        ad esempio, ricevono una tiepida accoglienza.
    </Textbox>

    <Title>
        L’incontro decisivo con <GlossaryWord :word-id="64">Domenico Barbaja</GlossaryWord>.
    </Title>
    <Textbox>
        L’incontro con l’impresario dei Teatri Reali di Napoli Barbaja,
        che gli offre un vantaggioso contratto, dà una svolta alla carriera del compositore.
    </Textbox>
    <Textbox>
        Rossini rimane a Napoli dal 1815 al 1822, componendo opere di grande
        successo come <GlossaryWord :word-id="48"><i class="italic">Elisabetta regina d’Inghilterra</i></GlossaryWord> e
        <GlossaryWord :word-id="69"><i class="italic">Otello</i></GlossaryWord>.
    </Textbox>
    <Textbox>
        In quegli anni il rapporto professionale e sentimentale con la cantante
        <GlossaryWord :word-id="65">Isabella Colbran</GlossaryWord>,si trasforma in una relazione sentimentale.
    </Textbox>

    <ContentImageBox align="right">
        <img src="/assets/operas/rossini/content-image-3-O1L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>

    <Title>
        Nel 1816 compone la sua opera più famosa di sempre.
    </Title>
    <Textbox>
        Rossini si divide tra Napoli, Milano e Roma, dove, al Teatro Argentina, nel febbraio del 1816, va in scena
        <i class="italic">Il barbiere di Siviglia (Almaviva, ossia L’inutile precauzione)</i>, il capolavoro che, da fiasco iniziale,
        si tramuterà in un successo clamoroso.
    </Textbox>
    <Textbox>
        Nel 1817 viene rappresentata <GlossaryWord :word-id="70"><i class="italic">La Cenerentola</i></GlossaryWord>,
        altra celebre opera di Rossini.
    </Textbox>

    <Title>Nel 1821 lascia Napoli per trasferirsi a Vienna.</Title>
    <Textbox>
        A Napoli scoppia un’insurrezione dei Carbonari, patrioti rivoluzionari, Barbaja comprende
        che il potere dei Borboni è minacciato e che è il momento di lasciare la città partenopea, quindi
        firma un contratto con cui assume la direzione del celebre Kärntnertortheater a Vienna e vi trasferisce
        la sua compagnia napoletana. Rossini lo segue e  si congeda da Napoli con
        <GlossaryWord :word-id="71"><i class="italic">Zelmira</i></GlossaryWord> (Teatro San Carlo, 16 febbraio 1822).
    </Textbox>
    <ContentImageBox class="h-124" align="right">
        <img src="/assets/operas/rossini/content-image-4-O1L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>
    <Title>
        Nel 1822 sposa <GlossaryWord :word-id="65">Isabella Colbran</GlossaryWord>.
    </Title>
    <Textbox>
        La cerimonia si svolge a Castenaso, vicino a Bologna, ufficializzando una relazione che durava da tempo.
        <GlossaryWord :word-id="65">Isabella Colbran</GlossaryWord> è stata la prima donna delle più importanti
        opere napoletane di Rossini.
    </Textbox>
    <Title>
        Nel febbraio 1823 conclude la sua carriera italiana con
        <GlossaryWord :word-id="72"><i class="italic">Semiramide</i></GlossaryWord>.
    </Title>
    <Textbox>
        Nel febbraio 1823 vede la luce, al Teatro La Fenice di Venezia,
        <GlossaryWord :word-id="72"><i class="italic">Semiramide</i></GlossaryWord>, su libretto di Gaetano Rossi,
        lo stesso librettista della <i class="italic">Cambiale di matrimonio</i> e di <i class="italic">Tancredi</i>.
    </Textbox>
    <Textbox>
        Il cerchio si chiude: Venezia segna il debutto di Rossini con <i class="italic">La cambiale di matrimonio</i> e
        anche la conclusione della sua carriera italiana.
    </Textbox>
    <ContentImageBox class="h-88">
        <img src="/assets/operas/rossini/content-image-5-O1L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>

    <Title>
        Nel 1824 si trasferisce a Parigi.
    </Title>
    <Textbox>
        Dopo un periodo a Londra, Rossini accetta la direzione del Théâtre Italien di Parigi.
    </Textbox>
    <Textbox>
        Nella capitale francese compone nuove opere di successo, come
        <GlossaryWord :word-id="73"><i class="italic">Il viaggio a Reims</i></GlossaryWord>
        e <GlossaryWord :word-id="74"><i class="italic">Le Siège de Corinthe</i></GlossaryWord>,
        rifacimento del <i class="italic">Maometto II</i>.
    </Textbox>
    <FunFact hidden-bot-desc theme="green">
        <template #title><i class="italic">Il viaggio a Reims:</i> un'opera ritrovata.</template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/rossini/funfact-image-1-O1L0C0.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        <i class="italic">Il viaggio a Reims</i> fu eseguito in forma di cantata scenica la prima volta al Théâtre Italien
                        di Parigi il 19 giugno 1825, alla presenza di Carlo X in occasione della sua incoronazione.
                    </p>
                    <p>
                        Con questa rappresentazione Rossini ritenne conclusa la storia del <i class="italic">Viaggio,</i>
                        non più proponibile per una questione di eleganza nei confronti del Monarca, destinatario esclusivo dell’omaggio.
                    </p>
                </template>
            </FunfactParagraph>
            <Textbox>
                Di fatto Rossini smembrò la partitura del <i class="italic">Viaggio,</i> trasferendo tutte le pagine non collegate alla
                situazione di circostanza in quella che doveva divenire la prima opera espressamente
                composta per Parigi: <GlossaryWord :word-id="75" theme="white"><i class="italic">Le Comte Ory</i></GlossaryWord>.
            </Textbox>
            <Textbox>
                Sulla base del lavoro degli studiosi della Fondazione Rossini,
                che pubblicano le opere nella versione originaria del compositore in edizione critica, il 18 agosto 1984
                <i class="italic">Il viaggio a Reims</i> è stato rappresentato al Rossini Opera Festival
                con la direzione di Claudio Abbado in una produzione di Luca Ronconi e Gae Aulenti.
            </Textbox>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/rossini/funfact-image-2-O1L0C0.png"  alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Da allora è un appuntamento fisso del Rossini Opera Festival e ha come
                        interpreti gli allievi dell'<GlossaryWord :word-id="67" theme="white">Accademia Rossiniana “Alberto Zedda"</GlossaryWord>.
                    </p>
                </template>
            </FunfactParagraph>
        </template>
    </FunFact>
    <Title>
        Nel 1829  si ritira dal teatro musicale.
    </Title>
    <Textbox>
        <GlossaryWord :word-id="76"><i class="italic">Guillaume Tell</i></GlossaryWord>, rappresentato a Parigi nel 1829,
        ottiene solo il favore della critica, ma non incontra quello del pubblico. Con quest’opera Rossini si congeda dal teatro musicale.
        Si dedicherà in seguito alla composizione di musica religiosa e da camera.
    </Textbox>
    <FunFact theme="green">
        <template #title>
            Un’ironica dedica a Dio.
        </template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/rossini/funfact-image-3-O1L0C0.png"  alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Nel 1863, Gioachino Rossini scrive la <i class="italic">Petite messe solennelle,</i> composizione sacra,
                        eseguita in forma privata, il 14 marzo 1864 nel palazzo della contessa Louise Pillett-Will,
                        alla quale la messa è dedicata, e di suo marito, il banchiere Alexis Pillet-Will.
                    </p>
                </template>
            </FunfactParagraph>
            <Textbox>
                Con il suo tipico umorismo, Rossini dedicò quest’opera a Dio, scrivendo sulla partitura autografa della
                Messa: “Ecco qui terminata, Buon Dio, questa povera <i class="italic">Petite Messe.</i>
                Sarà della musica sacra quella che ho appena creato o della musica dannata? Sono nato per l’Opera Buffa,
                lo sai bene! Poco sapere e un po’ di cuore, ecco tutto. Sii dunque benigno, e accordami il Paradiso”.
            </Textbox>
        </template>
    </FunFact>
    <ContentImageBox class="h-88">
        <img src="/assets/operas/rossini/content-image-6-O1L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>
    <Title>
        Muore il 13 novembre 1868.
    </Title>
    <Textbox>
        Rossini trascorre gli ultimi anni  tra Parigi e la sua villa di Passy, dove vive insieme a
        <GlossaryWord :word-id="77">Olympe Pélissier</GlossaryWord>,
        sua seconda moglie, sposata dopo la scomparsa della Colbran.
    </Textbox>
    <Textbox>
        Continua a comporre fino a poco prima della morte, che avviene il 13 novembre 1868.
    </Textbox>
    <Textbox>
        Nel testamento lascia la sua eredità al Comune di Pesaro per fondare un Liceo Musicale, oggi Conservatorio Gioachino Rossini.
    </Textbox>
    <NextChapter v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
