<script setup>
import { useAppStateStore } from "@/stores/appState.js";
import {onMounted, useModel, watch} from "vue";

const props = defineProps({
    hasHalftoneVariant : {type: Boolean, required : false, default: false}
})

const AppState = useAppStateStore();

const operaTheme = defineModel()

onMounted(() => {
    props.hasHalftoneVariant ? AppState.setPrimaryVariationTheme(operaTheme.value) : AppState.setPrimaryTheme(operaTheme.value);
});

watch(operaTheme, value => {
    props.hasHalftoneVariant ? AppState.setPrimaryVariationTheme(value) : AppState.setPrimaryTheme(value);
})
</script>

<template>
    <div class="primary-page">
        <slot></slot>
    </div>
</template>

<style scoped>
.primary-page {
    @apply flex flex-col flex-grow overflow-auto;
    /* @apply h-auto; */
    /* @apply min-h-128; */
}
</style>
