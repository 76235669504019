<script setup>
import OperaStats from "@/components/operas/OperaStats.vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {extractOperaRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {computed} from "vue";
import {AppContent} from "@/content/data/content.js";
import {glossaryWordsMap} from "@/content/data/glossaryWords.js";
import AppButton from "@/components/utilities/AppButton.vue";
const UserProgression = useUserProgressionStore();

const operaRouteInfo = extractOperaRouteInfo()

const operaId = operaRouteInfo.id;
const operaIndex = operaRouteInfo.index;

const opera = computed(() => {
    return AppContent.operas[operaIndex]
})

const data = {
    progression : {
        glossaryWords : UserProgression.glossaryWords.map(el => glossaryWordsMap[el.id]).filter(el => el.operaId === opera.value.id),
        achievements : UserProgression.operaUnlockedAchievements(operaIndex),
    },
    opera : {
        glossaryWords : AppContent.glossary.reduce((res, next) => res.concat(next.items), []).filter(el => el.operaId === opera.value.id),
        achievements : opera.value.levels.reduce((res, next) => res.concat(next.achievements), []),
    }

}
</script>

<template>
    <div class="container max-w-screen-sm flex flex-col px-0">
        <div class="w-full relative h-full">
            <img class="w-full top-0" src="/assets/operas/barbieredisiviglia/barbiere-end.png" alt="opera end picture">
            <div class="absolute -bottom-5 w-full">
                <img class="w-full" src="/assets/operas/barbieredisiviglia/opera-end-banner.png" alt="white banner">
                <div class="absolute inset-x-0 inset-y-0 flex justify-center items-center">
                    <h2 class="opera-completed-text">Hai completato il percorso!</h2>
                </div>
            </div>
        </div>
        <div class="mt-8">
            <OperaStats :data="data"></OperaStats>
        </div>
        <div class="mx-auto mt-5">
            <RouterLink to="/app">
                <AppButton text="TORNA ALLE OPERE" theme="roundedWhiteBgRedText">

                </AppButton>
            </RouterLink>
        </div>
    </div>
</template>

<style scoped>
.opera-completed-text{
    @apply text-warm font-medium
}
</style>
