<script setup>

</script>

<template>
    <svg width="336" height="336" viewBox="0 0 336 336" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2093_5101)">
            <rect x="0.142822" y="167.571" width="236.982" height="236.982" rx="118.491" transform="rotate(-45 0.142822 167.571)" fill="#3C57B6"/>
            <g clip-path="url(#clip1_2093_5101)">
                <path d="M144.04 127.45C145.23 126.76 146.62 126.38 148.09 126.38C151.26 126.38 154.02 128.17 155.4 130.81C155.99 131.95 156.33 133.25 156.33 134.63C156.33 134.63 148.97 137.3 147.36 137.3C144.85 137.3 139.84 134.63 139.84 134.63C139.84 131.54 141.53 128.86 144.04 127.45Z" fill="#F7F0F5"/>
                <path d="M177.67 127.3C178.8 126.71 180.09 126.38 181.45 126.38C184.7 126.38 187.51 128.26 188.85 131C189.39 132.1 189.69 133.33 189.69 134.63L181.13 137.3L173.21 134.63C173.21 131.44 175.02 128.67 177.67 127.3Z" fill="#F7F0F5"/>
                <path d="M178.42 172.62L226.23 188.68C230.83 190.22 235.15 192.33 239.12 194.9C253.92 204.51 262.9 220.93 263.87 238.54L266.7 290.32H68.28L76.36 228.04C78.94 208.14 94.39 192.35 114.23 189.33L178.42 172.61V172.62Z" fill="#131200"/>
                <mask id="mask0_2093_5101" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="68" y="172" width="199" height="119">
                    <path d="M263.87 238.54C262.91 220.92 253.92 204.51 239.12 194.9C235.15 192.32 230.83 190.22 226.23 188.68L178.42 172.62L114.23 189.34C94.39 192.36 78.94 208.15 76.36 228.05L68.28 290.32H266.7L263.87 238.54Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_2093_5101)">
                    <path d="M216.07 183.36C216.098 183.36 216.12 183.337 216.12 183.31C216.12 183.282 216.098 183.26 216.07 183.26C216.042 183.26 216.02 183.282 216.02 183.31C216.02 183.337 216.042 183.36 216.07 183.36Z" fill="#434391"/>
                    <path d="M221.27 186.42C221.331 186.42 221.38 186.37 221.38 186.31C221.38 186.249 221.331 186.2 221.27 186.2C221.209 186.2 221.16 186.249 221.16 186.31C221.16 186.37 221.209 186.42 221.27 186.42Z" fill="#434391"/>
                    <path d="M226.47 189.49C226.569 189.49 226.65 189.409 226.65 189.31C226.65 189.21 226.569 189.13 226.47 189.13C226.371 189.13 226.29 189.21 226.29 189.31C226.29 189.409 226.371 189.49 226.47 189.49Z" fill="#434391"/>
                    <path d="M231.66 192.56C231.798 192.56 231.91 192.448 231.91 192.31C231.91 192.172 231.798 192.06 231.66 192.06C231.522 192.06 231.41 192.172 231.41 192.31C231.41 192.448 231.522 192.56 231.66 192.56Z" fill="#434391"/>
                    <path d="M236.86 195.61C237.026 195.61 237.16 195.475 237.16 195.31C237.16 195.144 237.026 195.01 236.86 195.01C236.694 195.01 236.56 195.144 236.56 195.31C236.56 195.475 236.694 195.61 236.86 195.61Z" fill="#434391"/>
                    <path d="M242.05 198.62C242.221 198.62 242.36 198.481 242.36 198.31C242.36 198.139 242.221 198 242.05 198C241.879 198 241.74 198.139 241.74 198.31C241.74 198.481 241.879 198.62 242.05 198.62Z" fill="#434391"/>
                    <path d="M247.25 201.57C247.394 201.57 247.51 201.453 247.51 201.31C247.51 201.166 247.394 201.05 247.25 201.05C247.106 201.05 246.99 201.166 246.99 201.31C246.99 201.453 247.106 201.57 247.25 201.57Z" fill="#434391"/>
                    <path d="M252.45 204.43C252.516 204.43 252.57 204.376 252.57 204.31C252.57 204.243 252.516 204.19 252.45 204.19C252.384 204.19 252.33 204.243 252.33 204.31C252.33 204.376 252.384 204.43 252.45 204.43Z" fill="#434391"/>
                    <path d="M192.29 176.55C192.312 176.55 192.33 176.532 192.33 176.51C192.33 176.488 192.312 176.47 192.29 176.47C192.268 176.47 192.25 176.488 192.25 176.51C192.25 176.532 192.268 176.55 192.29 176.55Z" fill="#434391"/>
                    <path d="M197.49 179.58C197.529 179.58 197.56 179.548 197.56 179.51C197.56 179.471 197.529 179.44 197.49 179.44C197.451 179.44 197.42 179.471 197.42 179.51C197.42 179.548 197.451 179.58 197.49 179.58Z" fill="#434391"/>
                    <path d="M202.68 182.64C202.752 182.64 202.81 182.581 202.81 182.51C202.81 182.438 202.752 182.38 202.68 182.38C202.608 182.38 202.55 182.438 202.55 182.51C202.55 182.581 202.608 182.64 202.68 182.64Z" fill="#434391"/>
                    <path d="M207.88 185.71C207.99 185.71 208.08 185.62 208.08 185.51C208.08 185.399 207.99 185.31 207.88 185.31C207.77 185.31 207.68 185.399 207.68 185.51C207.68 185.62 207.77 185.71 207.88 185.71Z" fill="#434391"/>
                    <path d="M213.07 188.76C213.208 188.76 213.32 188.648 213.32 188.51C213.32 188.372 213.208 188.26 213.07 188.26C212.932 188.26 212.82 188.372 212.82 188.51C212.82 188.648 212.932 188.76 213.07 188.76Z" fill="#434391"/>
                    <path d="M218.27 191.81C218.436 191.81 218.57 191.675 218.57 191.51C218.57 191.344 218.436 191.21 218.27 191.21C218.104 191.21 217.97 191.344 217.97 191.51C217.97 191.675 218.104 191.81 218.27 191.81Z" fill="#434391"/>
                    <path d="M223.47 194.86C223.663 194.86 223.82 194.703 223.82 194.51C223.82 194.316 223.663 194.16 223.47 194.16C223.277 194.16 223.12 194.316 223.12 194.51C223.12 194.703 223.277 194.86 223.47 194.86Z" fill="#434391"/>
                    <path d="M228.66 197.9C228.875 197.9 229.05 197.725 229.05 197.51C229.05 197.294 228.875 197.12 228.66 197.12C228.445 197.12 228.27 197.294 228.27 197.51C228.27 197.725 228.445 197.9 228.66 197.9Z" fill="#434391"/>
                    <path d="M233.86 200.95C234.103 200.95 234.3 200.753 234.3 200.51C234.3 200.267 234.103 200.07 233.86 200.07C233.617 200.07 233.42 200.267 233.42 200.51C233.42 200.753 233.617 200.95 233.86 200.95Z" fill="#434391"/>
                    <path d="M239.05 204C239.321 204 239.54 203.78 239.54 203.51C239.54 203.239 239.321 203.02 239.05 203.02C238.779 203.02 238.56 203.239 238.56 203.51C238.56 203.78 238.779 204 239.05 204Z" fill="#434391"/>
                    <path d="M244.25 207.05C244.548 207.05 244.79 206.808 244.79 206.51C244.79 206.211 244.548 205.97 244.25 205.97C243.952 205.97 243.71 206.211 243.71 206.51C243.71 206.808 243.952 207.05 244.25 207.05Z" fill="#434391"/>
                    <path d="M249.45 210.09C249.77 210.09 250.03 209.83 250.03 209.51C250.03 209.189 249.77 208.93 249.45 208.93C249.13 208.93 248.87 209.189 248.87 209.51C248.87 209.83 249.13 210.09 249.45 210.09Z" fill="#434391"/>
                    <path d="M254.64 213.01C254.916 213.01 255.14 212.786 255.14 212.51C255.14 212.234 254.916 212.01 254.64 212.01C254.364 212.01 254.14 212.234 254.14 212.51C254.14 212.786 254.364 213.01 254.64 213.01Z" fill="#434391"/>
                    <path d="M259.84 215.64C259.912 215.64 259.97 215.581 259.97 215.51C259.97 215.438 259.912 215.38 259.84 215.38C259.768 215.38 259.71 215.438 259.71 215.51C259.71 215.581 259.768 215.64 259.84 215.64Z" fill="#434391"/>
                    <path d="M178.9 175.75C178.928 175.75 178.95 175.727 178.95 175.7C178.95 175.672 178.928 175.65 178.9 175.65C178.872 175.65 178.85 175.672 178.85 175.7C178.85 175.727 178.872 175.75 178.9 175.75Z" fill="#434391"/>
                    <path d="M184.09 178.79C184.14 178.79 184.18 178.75 184.18 178.7C184.18 178.65 184.14 178.61 184.09 178.61C184.04 178.61 184 178.65 184 178.7C184 178.75 184.04 178.79 184.09 178.79Z" fill="#434391"/>
                    <path d="M189.29 181.84C189.367 181.84 189.43 181.777 189.43 181.7C189.43 181.622 189.367 181.56 189.29 181.56C189.213 181.56 189.15 181.622 189.15 181.7C189.15 181.777 189.213 181.84 189.29 181.84Z" fill="#434391"/>
                    <path d="M194.49 184.89C194.595 184.89 194.68 184.805 194.68 184.7C194.68 184.595 194.595 184.51 194.49 184.51C194.385 184.51 194.3 184.595 194.3 184.7C194.3 184.805 194.385 184.89 194.49 184.89Z" fill="#434391"/>
                    <path d="M199.68 187.94C199.813 187.94 199.92 187.832 199.92 187.7C199.92 187.567 199.813 187.46 199.68 187.46C199.547 187.46 199.44 187.567 199.44 187.7C199.44 187.832 199.547 187.94 199.68 187.94Z" fill="#434391"/>
                    <path d="M204.88 190.98C205.035 190.98 205.16 190.854 205.16 190.7C205.16 190.545 205.035 190.42 204.88 190.42C204.725 190.42 204.6 190.545 204.6 190.7C204.6 190.854 204.725 190.98 204.88 190.98Z" fill="#434391"/>
                    <path d="M210.07 194.03C210.252 194.03 210.4 193.882 210.4 193.7C210.4 193.518 210.252 193.37 210.07 193.37C209.888 193.37 209.74 193.518 209.74 193.7C209.74 193.882 209.888 194.03 210.07 194.03Z" fill="#434391"/>
                    <path d="M215.27 197.08C215.48 197.08 215.65 196.91 215.65 196.7C215.65 196.49 215.48 196.32 215.27 196.32C215.06 196.32 214.89 196.49 214.89 196.7C214.89 196.91 215.06 197.08 215.27 197.08Z" fill="#434391"/>
                    <path d="M220.47 200.13C220.707 200.13 220.9 199.937 220.9 199.7C220.9 199.462 220.707 199.27 220.47 199.27C220.233 199.27 220.04 199.462 220.04 199.7C220.04 199.937 220.233 200.13 220.47 200.13Z" fill="#434391"/>
                    <path d="M225.66 203.17C225.92 203.17 226.13 202.959 226.13 202.7C226.13 202.44 225.92 202.23 225.66 202.23C225.4 202.23 225.19 202.44 225.19 202.7C225.19 202.959 225.4 203.17 225.66 203.17Z" fill="#434391"/>
                    <path d="M230.86 206.22C231.147 206.22 231.38 205.987 231.38 205.7C231.38 205.412 231.147 205.18 230.86 205.18C230.573 205.18 230.34 205.412 230.34 205.7C230.34 205.987 230.573 206.22 230.86 206.22Z" fill="#434391"/>
                    <path d="M236.05 209.27C236.365 209.27 236.62 209.014 236.62 208.7C236.62 208.385 236.365 208.13 236.05 208.13C235.735 208.13 235.48 208.385 235.48 208.7C235.48 209.014 235.735 209.27 236.05 209.27Z" fill="#434391"/>
                    <path d="M241.25 212.32C241.592 212.32 241.87 212.042 241.87 211.7C241.87 211.357 241.592 211.08 241.25 211.08C240.908 211.08 240.63 211.357 240.63 211.7C240.63 212.042 240.908 212.32 241.25 212.32Z" fill="#434391"/>
                    <path d="M246.45 215.37C246.82 215.37 247.12 215.07 247.12 214.7C247.12 214.33 246.82 214.03 246.45 214.03C246.08 214.03 245.78 214.33 245.78 214.7C245.78 215.07 246.08 215.37 246.45 215.37Z" fill="#434391"/>
                    <path d="M251.64 218.42C252.038 218.42 252.36 218.097 252.36 217.7C252.36 217.302 252.038 216.98 251.64 216.98C251.242 216.98 250.92 217.302 250.92 217.7C250.92 218.097 251.242 218.42 251.64 218.42Z" fill="#434391"/>
                    <path d="M256.84 221.44C257.249 221.44 257.58 221.108 257.58 220.7C257.58 220.291 257.249 219.96 256.84 219.96C256.431 219.96 256.1 220.291 256.1 220.7C256.1 221.108 256.431 221.44 256.84 221.44Z" fill="#434391"/>
                    <path d="M262.04 224.01C262.211 224.01 262.35 223.871 262.35 223.7C262.35 223.528 262.211 223.39 262.04 223.39C261.869 223.39 261.73 223.528 261.73 223.7C261.73 223.871 261.869 224.01 262.04 224.01Z" fill="#434391"/>
                    <path d="M165.5 174.92C165.511 174.92 165.52 174.911 165.52 174.9C165.52 174.889 165.511 174.88 165.5 174.88C165.489 174.88 165.48 174.889 165.48 174.9C165.48 174.911 165.489 174.92 165.5 174.92Z" fill="#434391"/>
                    <path d="M170.7 177.98C170.744 177.98 170.78 177.944 170.78 177.9C170.78 177.856 170.744 177.82 170.7 177.82C170.656 177.82 170.62 177.856 170.62 177.9C170.62 177.944 170.656 177.98 170.7 177.98Z" fill="#434391"/>
                    <path d="M175.9 181.02C175.966 181.02 176.02 180.966 176.02 180.9C176.02 180.834 175.966 180.78 175.9 180.78C175.834 180.78 175.78 180.834 175.78 180.9C175.78 180.966 175.834 181.02 175.9 181.02Z" fill="#434391"/>
                    <path d="M181.09 184.07C181.184 184.07 181.26 183.994 181.26 183.9C181.26 183.806 181.184 183.73 181.09 183.73C180.996 183.73 180.92 183.806 180.92 183.9C180.92 183.994 180.996 184.07 181.09 184.07Z" fill="#434391"/>
                    <path d="M186.29 187.12C186.411 187.12 186.51 187.021 186.51 186.9C186.51 186.778 186.411 186.68 186.29 186.68C186.168 186.68 186.07 186.778 186.07 186.9C186.07 187.021 186.168 187.12 186.29 187.12Z" fill="#434391"/>
                    <path d="M191.49 190.17C191.639 190.17 191.76 190.049 191.76 189.9C191.76 189.751 191.639 189.63 191.49 189.63C191.341 189.63 191.22 189.751 191.22 189.9C191.22 190.049 191.341 190.17 191.49 190.17Z" fill="#434391"/>
                    <path d="M196.68 193.22C196.857 193.22 197 193.077 197 192.9C197 192.723 196.857 192.58 196.68 192.58C196.503 192.58 196.36 192.723 196.36 192.9C196.36 193.077 196.503 193.22 196.68 193.22Z" fill="#434391"/>
                    <path d="M201.88 196.27C202.084 196.27 202.25 196.104 202.25 195.9C202.25 195.695 202.084 195.53 201.88 195.53C201.676 195.53 201.51 195.695 201.51 195.9C201.51 196.104 201.676 196.27 201.88 196.27Z" fill="#434391"/>
                    <path d="M207.07 199.32C207.302 199.32 207.49 199.132 207.49 198.9C207.49 198.668 207.302 198.48 207.07 198.48C206.838 198.48 206.65 198.668 206.65 198.9C206.65 199.132 206.838 199.32 207.07 199.32Z" fill="#434391"/>
                    <path d="M212.27 202.37C212.53 202.37 212.74 202.159 212.74 201.9C212.74 201.64 212.53 201.43 212.27 201.43C212.01 201.43 211.8 201.64 211.8 201.9C211.8 202.159 212.01 202.37 212.27 202.37Z" fill="#434391"/>
                    <path d="M217.47 205.41C217.752 205.41 217.98 205.181 217.98 204.9C217.98 204.618 217.752 204.39 217.47 204.39C217.188 204.39 216.96 204.618 216.96 204.9C216.96 205.181 217.188 205.41 217.47 205.41Z" fill="#434391"/>
                    <path d="M222.66 208.46C222.969 208.46 223.22 208.209 223.22 207.9C223.22 207.591 222.969 207.34 222.66 207.34C222.351 207.34 222.1 207.591 222.1 207.9C222.1 208.209 222.351 208.46 222.66 208.46Z" fill="#434391"/>
                    <path d="M227.86 211.51C228.197 211.51 228.47 211.237 228.47 210.9C228.47 210.563 228.197 210.29 227.86 210.29C227.523 210.29 227.25 210.563 227.25 210.9C227.25 211.237 227.523 211.51 227.86 211.51Z" fill="#434391"/>
                    <path d="M233.05 214.56C233.414 214.56 233.71 214.264 233.71 213.9C233.71 213.535 233.414 213.24 233.05 213.24C232.685 213.24 232.39 213.535 232.39 213.9C232.39 214.264 232.685 214.56 233.05 214.56Z" fill="#434391"/>
                    <path d="M238.25 217.61C238.642 217.61 238.96 217.292 238.96 216.9C238.96 216.508 238.642 216.19 238.25 216.19C237.858 216.19 237.54 216.508 237.54 216.9C237.54 217.292 237.858 217.61 238.25 217.61Z" fill="#434391"/>
                    <path d="M243.45 220.65C243.864 220.65 244.2 220.314 244.2 219.9C244.2 219.485 243.864 219.15 243.45 219.15C243.036 219.15 242.7 219.485 242.7 219.9C242.7 220.314 243.036 220.65 243.45 220.65Z" fill="#434391"/>
                    <path d="M248.64 223.7C249.082 223.7 249.44 223.342 249.44 222.9C249.44 222.458 249.082 222.1 248.64 222.1C248.198 222.1 247.84 222.458 247.84 222.9C247.84 223.342 248.198 223.7 248.64 223.7Z" fill="#434391"/>
                    <path d="M253.84 226.75C254.309 226.75 254.69 226.369 254.69 225.9C254.69 225.43 254.309 225.05 253.84 225.05C253.371 225.05 252.99 225.43 252.99 225.9C252.99 226.369 253.371 226.75 253.84 226.75Z" fill="#434391"/>
                    <path d="M259.04 229.77C259.52 229.77 259.91 229.38 259.91 228.9C259.91 228.419 259.52 228.03 259.04 228.03C258.559 228.03 258.17 228.419 258.17 228.9C258.17 229.38 258.559 229.77 259.04 229.77Z" fill="#434391"/>
                    <path d="M264.23 232.22C264.407 232.22 264.55 232.077 264.55 231.9C264.55 231.723 264.407 231.58 264.23 231.58C264.053 231.58 263.91 231.723 263.91 231.9C263.91 232.077 264.053 232.22 264.23 232.22Z" fill="#434391"/>
                    <path d="M157.31 177.13C157.327 177.13 157.34 177.116 157.34 177.1C157.34 177.083 157.327 177.07 157.31 177.07C157.293 177.07 157.28 177.083 157.28 177.1C157.28 177.116 157.293 177.13 157.31 177.13Z" fill="#434391"/>
                    <path d="M162.5 180.22C162.566 180.22 162.62 180.166 162.62 180.1C162.62 180.033 162.566 179.98 162.5 179.98C162.434 179.98 162.38 180.033 162.38 180.1C162.38 180.166 162.434 180.22 162.5 180.22Z" fill="#434391"/>
                    <path d="M167.7 183.26C167.788 183.26 167.86 183.188 167.86 183.1C167.86 183.011 167.788 182.94 167.7 182.94C167.612 182.94 167.54 183.011 167.54 183.1C167.54 183.188 167.612 183.26 167.7 183.26Z" fill="#434391"/>
                    <path d="M172.9 186.31C173.016 186.31 173.11 186.216 173.11 186.1C173.11 185.984 173.016 185.89 172.9 185.89C172.784 185.89 172.69 185.984 172.69 186.1C172.69 186.216 172.784 186.31 172.9 186.31Z" fill="#434391"/>
                    <path d="M178.09 189.36C178.234 189.36 178.35 189.243 178.35 189.1C178.35 188.956 178.234 188.84 178.09 188.84C177.946 188.84 177.83 188.956 177.83 189.1C177.83 189.243 177.946 189.36 178.09 189.36Z" fill="#434391"/>
                    <path d="M183.29 192.41C183.461 192.41 183.6 192.271 183.6 192.1C183.6 191.929 183.461 191.79 183.29 191.79C183.119 191.79 182.98 191.929 182.98 192.1C182.98 192.271 183.119 192.41 183.29 192.41Z" fill="#434391"/>
                    <path d="M188.49 195.46C188.689 195.46 188.85 195.299 188.85 195.1C188.85 194.901 188.689 194.74 188.49 194.74C188.291 194.74 188.13 194.901 188.13 195.1C188.13 195.299 188.291 195.46 188.49 195.46Z" fill="#434391"/>
                    <path d="M193.68 198.51C193.906 198.51 194.09 198.326 194.09 198.1C194.09 197.873 193.906 197.69 193.68 197.69C193.454 197.69 193.27 197.873 193.27 198.1C193.27 198.326 193.454 198.51 193.68 198.51Z" fill="#434391"/>
                    <path d="M198.88 201.55C199.129 201.55 199.33 201.348 199.33 201.1C199.33 200.851 199.129 200.65 198.88 200.65C198.631 200.65 198.43 200.851 198.43 201.1C198.43 201.348 198.631 201.55 198.88 201.55Z" fill="#434391"/>
                    <path d="M204.07 204.6C204.346 204.6 204.57 204.376 204.57 204.1C204.57 203.824 204.346 203.6 204.07 203.6C203.794 203.6 203.57 203.824 203.57 204.1C203.57 204.376 203.794 204.6 204.07 204.6Z" fill="#434391"/>
                    <path d="M209.27 207.65C209.574 207.65 209.82 207.404 209.82 207.1C209.82 206.796 209.574 206.55 209.27 206.55C208.966 206.55 208.72 206.796 208.72 207.1C208.72 207.404 208.966 207.65 209.27 207.65Z" fill="#434391"/>
                    <path d="M214.47 210.69C214.796 210.69 215.06 210.426 215.06 210.1C215.06 209.774 214.796 209.51 214.47 209.51C214.144 209.51 213.88 209.774 213.88 210.1C213.88 210.426 214.144 210.69 214.47 210.69Z" fill="#434391"/>
                    <path d="M219.66 213.74C220.013 213.74 220.3 213.453 220.3 213.1C220.3 212.746 220.013 212.46 219.66 212.46C219.307 212.46 219.02 212.746 219.02 213.1C219.02 213.453 219.307 213.74 219.66 213.74Z" fill="#434391"/>
                    <path d="M224.86 216.79C225.241 216.79 225.55 216.481 225.55 216.1C225.55 215.719 225.241 215.41 224.86 215.41C224.479 215.41 224.17 215.719 224.17 216.1C224.17 216.481 224.479 216.79 224.86 216.79Z" fill="#434391"/>
                    <path d="M230.05 219.84C230.459 219.84 230.79 219.509 230.79 219.1C230.79 218.691 230.459 218.36 230.05 218.36C229.641 218.36 229.31 218.691 229.31 219.1C229.31 219.509 229.641 219.84 230.05 219.84Z" fill="#434391"/>
                    <path d="M235.25 222.88C235.681 222.88 236.03 222.531 236.03 222.1C236.03 221.669 235.681 221.32 235.25 221.32C234.819 221.32 234.47 221.669 234.47 222.1C234.47 222.531 234.819 222.88 235.25 222.88Z" fill="#434391"/>
                    <path d="M240.45 225.93C240.908 225.93 241.28 225.558 241.28 225.1C241.28 224.641 240.908 224.27 240.45 224.27C239.992 224.27 239.62 224.641 239.62 225.1C239.62 225.558 239.992 225.93 240.45 225.93Z" fill="#434391"/>
                    <path d="M245.64 228.98C246.126 228.98 246.52 228.586 246.52 228.1C246.52 227.614 246.126 227.22 245.64 227.22C245.154 227.22 244.76 227.614 244.76 228.1C244.76 228.586 245.154 228.98 245.64 228.98Z" fill="#434391"/>
                    <path d="M250.84 232.03C251.354 232.03 251.77 231.613 251.77 231.1C251.77 230.586 251.354 230.17 250.84 230.17C250.326 230.17 249.91 230.586 249.91 231.1C249.91 231.613 250.326 232.03 250.84 232.03Z" fill="#434391"/>
                    <path d="M256.04 235.08C256.581 235.08 257.02 234.641 257.02 234.1C257.02 233.558 256.581 233.12 256.04 233.12C255.499 233.12 255.06 233.558 255.06 234.1C255.06 234.641 255.499 235.08 256.04 235.08Z" fill="#434391"/>
                    <path d="M261.23 238.06C261.76 238.06 262.19 237.63 262.19 237.1C262.19 236.569 261.76 236.14 261.23 236.14C260.7 236.14 260.27 236.569 260.27 237.1C260.27 237.63 260.7 238.06 261.23 238.06Z" fill="#434391"/>
                    <path d="M266.43 240.25C266.513 240.25 266.58 240.183 266.58 240.1C266.58 240.017 266.513 239.95 266.43 239.95C266.347 239.95 266.28 240.017 266.28 240.1C266.28 240.183 266.347 240.25 266.43 240.25Z" fill="#434391"/>
                    <path d="M149.11 179.34C149.138 179.34 149.16 179.317 149.16 179.29C149.16 179.262 149.138 179.24 149.11 179.24C149.082 179.24 149.06 179.262 149.06 179.29C149.06 179.317 149.082 179.34 149.11 179.34Z" fill="#434391"/>
                    <path d="M154.31 182.44C154.393 182.44 154.46 182.372 154.46 182.29C154.46 182.207 154.393 182.14 154.31 182.14C154.227 182.14 154.16 182.207 154.16 182.29C154.16 182.372 154.227 182.44 154.31 182.44Z" fill="#434391"/>
                    <path d="M159.5 185.49C159.61 185.49 159.7 185.4 159.7 185.29C159.7 185.179 159.61 185.09 159.5 185.09C159.39 185.09 159.3 185.179 159.3 185.29C159.3 185.4 159.39 185.49 159.5 185.49Z" fill="#434391"/>
                    <path d="M164.7 188.54C164.838 188.54 164.95 188.428 164.95 188.29C164.95 188.152 164.838 188.04 164.7 188.04C164.562 188.04 164.45 188.152 164.45 188.29C164.45 188.428 164.562 188.54 164.7 188.54Z" fill="#434391"/>
                    <path d="M169.9 191.58C170.06 191.58 170.19 191.45 170.19 191.29C170.19 191.13 170.06 191 169.9 191C169.74 191 169.61 191.13 169.61 191.29C169.61 191.45 169.74 191.58 169.9 191.58Z" fill="#434391"/>
                    <path d="M175.09 194.63C175.278 194.63 175.43 194.477 175.43 194.29C175.43 194.102 175.278 193.95 175.09 193.95C174.902 193.95 174.75 194.102 174.75 194.29C174.75 194.477 174.902 194.63 175.09 194.63Z" fill="#434391"/>
                    <path d="M180.29 197.68C180.505 197.68 180.68 197.505 180.68 197.29C180.68 197.074 180.505 196.9 180.29 196.9C180.075 196.9 179.9 197.074 179.9 197.29C179.9 197.505 180.075 197.68 180.29 197.68Z" fill="#434391"/>
                    <path d="M185.49 200.73C185.733 200.73 185.93 200.533 185.93 200.29C185.93 200.047 185.733 199.85 185.49 199.85C185.247 199.85 185.05 200.047 185.05 200.29C185.05 200.533 185.247 200.73 185.49 200.73Z" fill="#434391"/>
                    <path d="M190.68 203.77C190.945 203.77 191.16 203.555 191.16 203.29C191.16 203.025 190.945 202.81 190.68 202.81C190.415 202.81 190.2 203.025 190.2 203.29C190.2 203.555 190.415 203.77 190.68 203.77Z" fill="#434391"/>
                    <path d="M195.88 206.82C196.173 206.82 196.41 206.582 196.41 206.29C196.41 205.997 196.173 205.76 195.88 205.76C195.587 205.76 195.35 205.997 195.35 206.29C195.35 206.582 195.587 206.82 195.88 206.82Z" fill="#434391"/>
                    <path d="M201.07 209.87C201.39 209.87 201.65 209.61 201.65 209.29C201.65 208.969 201.39 208.71 201.07 208.71C200.75 208.71 200.49 208.969 200.49 209.29C200.49 209.61 200.75 209.87 201.07 209.87Z" fill="#434391"/>
                    <path d="M206.27 212.92C206.618 212.92 206.9 212.638 206.9 212.29C206.9 211.942 206.618 211.66 206.27 211.66C205.922 211.66 205.64 211.942 205.64 212.29C205.64 212.638 205.922 212.92 206.27 212.92Z" fill="#434391"/>
                    <path d="M211.47 215.97C211.846 215.97 212.15 215.665 212.15 215.29C212.15 214.914 211.846 214.61 211.47 214.61C211.094 214.61 210.79 214.914 210.79 215.29C210.79 215.665 211.094 215.97 211.47 215.97Z" fill="#434391"/>
                    <path d="M216.66 219.01C217.058 219.01 217.38 218.687 217.38 218.29C217.38 217.892 217.058 217.57 216.66 217.57C216.262 217.57 215.94 217.892 215.94 218.29C215.94 218.687 216.262 219.01 216.66 219.01Z" fill="#434391"/>
                    <path d="M221.86 222.06C222.285 222.06 222.63 221.715 222.63 221.29C222.63 220.865 222.285 220.52 221.86 220.52C221.435 220.52 221.09 220.865 221.09 221.29C221.09 221.715 221.435 222.06 221.86 222.06Z" fill="#434391"/>
                    <path d="M227.05 225.11C227.503 225.11 227.87 224.743 227.87 224.29C227.87 223.837 227.503 223.47 227.05 223.47C226.597 223.47 226.23 223.837 226.23 224.29C226.23 224.743 226.597 225.11 227.05 225.11Z" fill="#434391"/>
                    <path d="M232.25 228.16C232.73 228.16 233.12 227.77 233.12 227.29C233.12 226.809 232.73 226.42 232.25 226.42C231.769 226.42 231.38 226.809 231.38 227.29C231.38 227.77 231.769 228.16 232.25 228.16Z" fill="#434391"/>
                    <path d="M237.45 231.21C237.958 231.21 238.37 230.798 238.37 230.29C238.37 229.782 237.958 229.37 237.45 229.37C236.942 229.37 236.53 229.782 236.53 230.29C236.53 230.798 236.942 231.21 237.45 231.21Z" fill="#434391"/>
                    <path d="M242.64 234.25C243.17 234.25 243.6 233.82 243.6 233.29C243.6 232.76 243.17 232.33 242.64 232.33C242.11 232.33 241.68 232.76 241.68 233.29C241.68 233.82 242.11 234.25 242.64 234.25Z" fill="#434391"/>
                    <path d="M247.84 237.3C248.398 237.3 248.85 236.848 248.85 236.29C248.85 235.732 248.398 235.28 247.84 235.28C247.282 235.28 246.83 235.732 246.83 236.29C246.83 236.848 247.282 237.3 247.84 237.3Z" fill="#434391"/>
                    <path d="M253.04 240.35C253.625 240.35 254.1 239.875 254.1 239.29C254.1 238.704 253.625 238.23 253.04 238.23C252.455 238.23 251.98 238.704 251.98 239.29C251.98 239.875 252.455 240.35 253.04 240.35Z" fill="#434391"/>
                    <path d="M258.23 243.4C258.843 243.4 259.34 242.903 259.34 242.29C259.34 241.677 258.843 241.18 258.23 241.18C257.617 241.18 257.12 241.677 257.12 242.29C257.12 242.903 257.617 243.4 258.23 243.4Z" fill="#434391"/>
                    <path d="M263.43 246.11C263.883 246.11 264.25 245.743 264.25 245.29C264.25 244.837 263.883 244.47 263.43 244.47C262.977 244.47 262.61 244.837 262.61 245.29C262.61 245.743 262.977 246.11 263.43 246.11Z" fill="#434391"/>
                    <path d="M140.92 181.56C140.959 181.56 140.99 181.528 140.99 181.49C140.99 181.451 140.959 181.42 140.92 181.42C140.881 181.42 140.85 181.451 140.85 181.49C140.85 181.528 140.881 181.56 140.92 181.56Z" fill="#434391"/>
                    <path d="M146.11 184.67C146.209 184.67 146.29 184.589 146.29 184.49C146.29 184.39 146.209 184.31 146.11 184.31C146.011 184.31 145.93 184.39 145.93 184.49C145.93 184.589 146.011 184.67 146.11 184.67Z" fill="#434391"/>
                    <path d="M151.31 187.72C151.437 187.72 151.54 187.617 151.54 187.49C151.54 187.363 151.437 187.26 151.31 187.26C151.183 187.26 151.08 187.363 151.08 187.49C151.08 187.617 151.183 187.72 151.31 187.72Z" fill="#434391"/>
                    <path d="M156.5 190.77C156.655 190.77 156.78 190.644 156.78 190.49C156.78 190.335 156.655 190.21 156.5 190.21C156.345 190.21 156.22 190.335 156.22 190.49C156.22 190.644 156.345 190.77 156.5 190.77Z" fill="#434391"/>
                    <path d="M161.7 193.82C161.882 193.82 162.03 193.672 162.03 193.49C162.03 193.307 161.882 193.16 161.7 193.16C161.518 193.16 161.37 193.307 161.37 193.49C161.37 193.672 161.518 193.82 161.7 193.82Z" fill="#434391"/>
                    <path d="M166.9 196.86C167.104 196.86 167.27 196.694 167.27 196.49C167.27 196.286 167.104 196.12 166.9 196.12C166.696 196.12 166.53 196.286 166.53 196.49C166.53 196.694 166.696 196.86 166.9 196.86Z" fill="#434391"/>
                    <path d="M172.09 199.91C172.322 199.91 172.51 199.722 172.51 199.49C172.51 199.258 172.322 199.07 172.09 199.07C171.858 199.07 171.67 199.258 171.67 199.49C171.67 199.722 171.858 199.91 172.09 199.91Z" fill="#434391"/>
                    <path d="M177.29 202.96C177.55 202.96 177.76 202.749 177.76 202.49C177.76 202.23 177.55 202.02 177.29 202.02C177.03 202.02 176.82 202.23 176.82 202.49C176.82 202.749 177.03 202.96 177.29 202.96Z" fill="#434391"/>
                    <path d="M182.49 206.01C182.777 206.01 183.01 205.777 183.01 205.49C183.01 205.203 182.777 204.97 182.49 204.97C182.203 204.97 181.97 205.203 181.97 205.49C181.97 205.777 182.203 206.01 182.49 206.01Z" fill="#434391"/>
                    <path d="M187.68 209.06C187.995 209.06 188.25 208.804 188.25 208.49C188.25 208.175 187.995 207.92 187.68 207.92C187.365 207.92 187.11 208.175 187.11 208.49C187.11 208.804 187.365 209.06 187.68 209.06Z" fill="#434391"/>
                    <path d="M192.88 212.11C193.222 212.11 193.5 211.832 193.5 211.49C193.5 211.147 193.222 210.87 192.88 210.87C192.538 210.87 192.26 211.147 192.26 211.49C192.26 211.832 192.538 212.11 192.88 212.11Z" fill="#434391"/>
                    <path d="M198.07 215.16C198.44 215.16 198.74 214.86 198.74 214.49C198.74 214.12 198.44 213.82 198.07 213.82C197.7 213.82 197.4 214.12 197.4 214.49C197.4 214.86 197.7 215.16 198.07 215.16Z" fill="#434391"/>
                    <path d="M203.27 218.21C203.668 218.21 203.99 217.887 203.99 217.49C203.99 217.092 203.668 216.77 203.27 216.77C202.872 216.77 202.55 217.092 202.55 217.49C202.55 217.887 202.872 218.21 203.27 218.21Z" fill="#434391"/>
                    <path d="M208.47 221.25C208.89 221.25 209.23 220.909 209.23 220.49C209.23 220.07 208.89 219.73 208.47 219.73C208.05 219.73 207.71 220.07 207.71 220.49C207.71 220.909 208.05 221.25 208.47 221.25Z" fill="#434391"/>
                    <path d="M213.66 224.3C214.107 224.3 214.47 223.937 214.47 223.49C214.47 223.042 214.107 222.68 213.66 222.68C213.213 222.68 212.85 223.042 212.85 223.49C212.85 223.937 213.213 224.3 213.66 224.3Z" fill="#434391"/>
                    <path d="M218.86 227.35C219.335 227.35 219.72 226.965 219.72 226.49C219.72 226.015 219.335 225.63 218.86 225.63C218.385 225.63 218 226.015 218 226.49C218 226.965 218.385 227.35 218.86 227.35Z" fill="#434391"/>
                    <path d="M224.05 230.4C224.553 230.4 224.96 229.992 224.96 229.49C224.96 228.987 224.553 228.58 224.05 228.58C223.547 228.58 223.14 228.987 223.14 229.49C223.14 229.992 223.547 230.4 224.05 230.4Z" fill="#434391"/>
                    <path d="M229.25 233.44C229.775 233.44 230.2 233.014 230.2 232.49C230.2 231.965 229.775 231.54 229.25 231.54C228.725 231.54 228.3 231.965 228.3 232.49C228.3 233.014 228.725 233.44 229.25 233.44Z" fill="#434391"/>
                    <path d="M234.45 236.49C235.002 236.49 235.45 236.042 235.45 235.49C235.45 234.937 235.002 234.49 234.45 234.49C233.898 234.49 233.45 234.937 233.45 235.49C233.45 236.042 233.898 236.49 234.45 236.49Z" fill="#434391"/>
                    <path d="M239.64 239.54C240.22 239.54 240.69 239.07 240.69 238.49C240.69 237.91 240.22 237.44 239.64 237.44C239.06 237.44 238.59 237.91 238.59 238.49C238.59 239.07 239.06 239.54 239.64 239.54Z" fill="#434391"/>
                    <path d="M244.84 242.59C245.447 242.59 245.94 242.097 245.94 241.49C245.94 240.882 245.447 240.39 244.84 240.39C244.232 240.39 243.74 240.882 243.74 241.49C243.74 242.097 244.232 242.59 244.84 242.59Z" fill="#434391"/>
                    <path d="M250.04 245.63C250.67 245.63 251.18 245.119 251.18 244.49C251.18 243.86 250.67 243.35 250.04 243.35C249.41 243.35 248.9 243.86 248.9 244.49C248.9 245.119 249.41 245.63 250.04 245.63Z" fill="#434391"/>
                    <path d="M255.23 248.68C255.887 248.68 256.42 248.147 256.42 247.49C256.42 246.833 255.887 246.3 255.23 246.3C254.573 246.3 254.04 246.833 254.04 247.49C254.04 248.147 254.573 248.68 255.23 248.68Z" fill="#434391"/>
                    <path d="M260.43 251.73C261.115 251.73 261.67 251.175 261.67 250.49C261.67 249.805 261.115 249.25 260.43 249.25C259.745 249.25 259.19 249.805 259.19 250.49C259.19 251.175 259.745 251.73 260.43 251.73Z" fill="#434391"/>
                    <path d="M265.62 253.96C265.88 253.96 266.09 253.749 266.09 253.49C266.09 253.23 265.88 253.02 265.62 253.02C265.36 253.02 265.15 253.23 265.15 253.49C265.15 253.749 265.36 253.96 265.62 253.96Z" fill="#434391"/>
                    <path d="M132.72 183.75C132.759 183.75 132.79 183.719 132.79 183.68C132.79 183.641 132.759 183.61 132.72 183.61C132.681 183.61 132.65 183.641 132.65 183.68C132.65 183.719 132.681 183.75 132.72 183.75Z" fill="#434391"/>
                    <path d="M137.92 186.9C138.041 186.9 138.14 186.801 138.14 186.68C138.14 186.558 138.041 186.46 137.92 186.46C137.798 186.46 137.7 186.558 137.7 186.68C137.7 186.801 137.798 186.9 137.92 186.9Z" fill="#434391"/>
                    <path d="M143.11 189.95C143.259 189.95 143.38 189.829 143.38 189.68C143.38 189.531 143.259 189.41 143.11 189.41C142.961 189.41 142.84 189.531 142.84 189.68C142.84 189.829 142.961 189.95 143.11 189.95Z" fill="#434391"/>
                    <path d="M148.31 193C148.487 193 148.63 192.857 148.63 192.68C148.63 192.503 148.487 192.36 148.31 192.36C148.133 192.36 147.99 192.503 147.99 192.68C147.99 192.857 148.133 193 148.31 193Z" fill="#434391"/>
                    <path d="M153.5 196.05C153.704 196.05 153.87 195.884 153.87 195.68C153.87 195.475 153.704 195.31 153.5 195.31C153.296 195.31 153.13 195.475 153.13 195.68C153.13 195.884 153.296 196.05 153.5 196.05Z" fill="#434391"/>
                    <path d="M158.7 199.1C158.932 199.1 159.12 198.912 159.12 198.68C159.12 198.448 158.932 198.26 158.7 198.26C158.468 198.26 158.28 198.448 158.28 198.68C158.28 198.912 158.468 199.1 158.7 199.1Z" fill="#434391"/>
                    <path d="M163.9 202.14C164.154 202.14 164.36 201.934 164.36 201.68C164.36 201.426 164.154 201.22 163.9 201.22C163.646 201.22 163.44 201.426 163.44 201.68C163.44 201.934 163.646 202.14 163.9 202.14Z" fill="#434391"/>
                    <path d="M169.09 205.19C169.372 205.19 169.6 204.961 169.6 204.68C169.6 204.398 169.372 204.17 169.09 204.17C168.808 204.17 168.58 204.398 168.58 204.68C168.58 204.961 168.808 205.19 169.09 205.19Z" fill="#434391"/>
                    <path d="M174.29 208.24C174.599 208.24 174.85 207.989 174.85 207.68C174.85 207.371 174.599 207.12 174.29 207.12C173.981 207.12 173.73 207.371 173.73 207.68C173.73 207.989 173.981 208.24 174.29 208.24Z" fill="#434391"/>
                    <path d="M179.49 211.29C179.827 211.29 180.1 211.017 180.1 210.68C180.1 210.343 179.827 210.07 179.49 210.07C179.153 210.07 178.88 210.343 178.88 210.68C178.88 211.017 179.153 211.29 179.49 211.29Z" fill="#434391"/>
                    <path d="M184.68 214.34C185.045 214.34 185.34 214.044 185.34 213.68C185.34 213.315 185.045 213.02 184.68 213.02C184.315 213.02 184.02 213.315 184.02 213.68C184.02 214.044 184.315 214.34 184.68 214.34Z" fill="#434391"/>
                    <path d="M189.88 217.38C190.267 217.38 190.58 217.066 190.58 216.68C190.58 216.293 190.267 215.98 189.88 215.98C189.493 215.98 189.18 216.293 189.18 216.68C189.18 217.066 189.493 217.38 189.88 217.38Z" fill="#434391"/>
                    <path d="M195.07 220.43C195.484 220.43 195.82 220.094 195.82 219.68C195.82 219.265 195.484 218.93 195.07 218.93C194.656 218.93 194.32 219.265 194.32 219.68C194.32 220.094 194.656 220.43 195.07 220.43Z" fill="#434391"/>
                    <path d="M200.27 223.48C200.712 223.48 201.07 223.121 201.07 222.68C201.07 222.238 200.712 221.88 200.27 221.88C199.828 221.88 199.47 222.238 199.47 222.68C199.47 223.121 199.828 223.48 200.27 223.48Z" fill="#434391"/>
                    <path d="M205.47 226.53C205.939 226.53 206.32 226.149 206.32 225.68C206.32 225.21 205.939 224.83 205.47 224.83C205.001 224.83 204.62 225.21 204.62 225.68C204.62 226.149 205.001 226.53 205.47 226.53Z" fill="#434391"/>
                    <path d="M210.66 229.57C211.152 229.57 211.55 229.171 211.55 228.68C211.55 228.188 211.152 227.79 210.66 227.79C210.168 227.79 209.77 228.188 209.77 228.68C209.77 229.171 210.168 229.57 210.66 229.57Z" fill="#434391"/>
                    <path d="M215.86 232.62C216.379 232.62 216.8 232.199 216.8 231.68C216.8 231.161 216.379 230.74 215.86 230.74C215.341 230.74 214.92 231.161 214.92 231.68C214.92 232.199 215.341 232.62 215.86 232.62Z" fill="#434391"/>
                    <path d="M221.05 235.67C221.597 235.67 222.04 235.226 222.04 234.68C222.04 234.133 221.597 233.69 221.05 233.69C220.503 233.69 220.06 234.133 220.06 234.68C220.06 235.226 220.503 235.67 221.05 235.67Z" fill="#434391"/>
                    <path d="M226.25 238.72C226.824 238.72 227.29 238.254 227.29 237.68C227.29 237.105 226.824 236.64 226.25 236.64C225.676 236.64 225.21 237.105 225.21 237.68C225.21 238.254 225.676 238.72 226.25 238.72Z" fill="#434391"/>
                    <path d="M231.45 241.76C232.046 241.76 232.53 241.276 232.53 240.68C232.53 240.083 232.046 239.6 231.45 239.6C230.854 239.6 230.37 240.083 230.37 240.68C230.37 241.276 230.854 241.76 231.45 241.76Z" fill="#434391"/>
                    <path d="M236.64 244.81C237.264 244.81 237.77 244.304 237.77 243.68C237.77 243.056 237.264 242.55 236.64 242.55C236.016 242.55 235.51 243.056 235.51 243.68C235.51 244.304 236.016 244.81 236.64 244.81Z" fill="#434391"/>
                    <path d="M241.84 247.86C242.492 247.86 243.02 247.331 243.02 246.68C243.02 246.028 242.492 245.5 241.84 245.5C241.188 245.5 240.66 246.028 240.66 246.68C240.66 247.331 241.188 247.86 241.84 247.86Z" fill="#434391"/>
                    <path d="M247.04 250.91C247.719 250.91 248.27 250.359 248.27 249.68C248.27 249 247.719 248.45 247.04 248.45C246.361 248.45 245.81 249 245.81 249.68C245.81 250.359 246.361 250.91 247.04 250.91Z" fill="#434391"/>
                    <path d="M252.23 253.95C252.931 253.95 253.5 253.381 253.5 252.68C253.5 251.978 252.931 251.41 252.23 251.41C251.529 251.41 250.96 251.978 250.96 252.68C250.96 253.381 251.529 253.95 252.23 253.95Z" fill="#434391"/>
                    <path d="M257.43 257C258.159 257 258.75 256.409 258.75 255.68C258.75 254.951 258.159 254.36 257.43 254.36C256.701 254.36 256.11 254.951 256.11 255.68C256.11 256.409 256.701 257 257.43 257Z" fill="#434391"/>
                    <path d="M262.62 259.94C263.316 259.94 263.88 259.376 263.88 258.68C263.88 257.984 263.316 257.42 262.62 257.42C261.924 257.42 261.36 257.984 261.36 258.68C261.36 259.376 261.924 259.94 262.62 259.94Z" fill="#434391"/>
                    <path d="M124.52 185.98C124.575 185.98 124.62 185.935 124.62 185.88C124.62 185.825 124.575 185.78 124.52 185.78C124.465 185.78 124.42 185.825 124.42 185.88C124.42 185.935 124.465 185.98 124.52 185.98Z" fill="#434391"/>
                    <path d="M129.72 189.13C129.858 189.13 129.97 189.018 129.97 188.88C129.97 188.742 129.858 188.63 129.72 188.63C129.582 188.63 129.47 188.742 129.47 188.88C129.47 189.018 129.582 189.13 129.72 189.13Z" fill="#434391"/>
                    <path d="M134.92 192.18C135.086 192.18 135.22 192.046 135.22 191.88C135.22 191.714 135.086 191.58 134.92 191.58C134.754 191.58 134.62 191.714 134.62 191.88C134.62 192.046 134.754 192.18 134.92 192.18Z" fill="#434391"/>
                    <path d="M140.11 195.23C140.303 195.23 140.46 195.073 140.46 194.88C140.46 194.686 140.303 194.53 140.11 194.53C139.917 194.53 139.76 194.686 139.76 194.88C139.76 195.073 139.917 195.23 140.11 195.23Z" fill="#434391"/>
                    <path d="M145.31 198.28C145.531 198.28 145.71 198.101 145.71 197.88C145.71 197.659 145.531 197.48 145.31 197.48C145.089 197.48 144.91 197.659 144.91 197.88C144.91 198.101 145.089 198.28 145.31 198.28Z" fill="#434391"/>
                    <path d="M150.5 201.33C150.749 201.33 150.95 201.128 150.95 200.88C150.95 200.631 150.749 200.43 150.5 200.43C150.251 200.43 150.05 200.631 150.05 200.88C150.05 201.128 150.251 201.33 150.5 201.33Z" fill="#434391"/>
                    <path d="M155.7 204.37C155.971 204.37 156.19 204.15 156.19 203.88C156.19 203.609 155.971 203.39 155.7 203.39C155.429 203.39 155.21 203.609 155.21 203.88C155.21 204.15 155.429 204.37 155.7 204.37Z" fill="#434391"/>
                    <path d="M160.9 207.42C161.198 207.42 161.44 207.178 161.44 206.88C161.44 206.582 161.198 206.34 160.9 206.34C160.602 206.34 160.36 206.582 160.36 206.88C160.36 207.178 160.602 207.42 160.9 207.42Z" fill="#434391"/>
                    <path d="M166.09 210.47C166.416 210.47 166.68 210.206 166.68 209.88C166.68 209.554 166.416 209.29 166.09 209.29C165.764 209.29 165.5 209.554 165.5 209.88C165.5 210.206 165.764 210.47 166.09 210.47Z" fill="#434391"/>
                    <path d="M171.29 213.52C171.643 213.52 171.93 213.233 171.93 212.88C171.93 212.526 171.643 212.24 171.29 212.24C170.937 212.24 170.65 212.526 170.65 212.88C170.65 213.233 170.937 213.52 171.29 213.52Z" fill="#434391"/>
                    <path d="M176.49 216.57C176.871 216.57 177.18 216.261 177.18 215.88C177.18 215.499 176.871 215.19 176.49 215.19C176.109 215.19 175.8 215.499 175.8 215.88C175.8 216.261 176.109 216.57 176.49 216.57Z" fill="#434391"/>
                    <path d="M181.68 219.61C182.083 219.61 182.41 219.283 182.41 218.88C182.41 218.477 182.083 218.15 181.68 218.15C181.277 218.15 180.95 218.477 180.95 218.88C180.95 219.283 181.277 219.61 181.68 219.61Z" fill="#434391"/>
                    <path d="M186.88 222.66C187.311 222.66 187.66 222.311 187.66 221.88C187.66 221.449 187.311 221.1 186.88 221.1C186.449 221.1 186.1 221.449 186.1 221.88C186.1 222.311 186.449 222.66 186.88 222.66Z" fill="#434391"/>
                    <path d="M192.07 225.71C192.528 225.71 192.9 225.338 192.9 224.88C192.9 224.421 192.528 224.05 192.07 224.05C191.612 224.05 191.24 224.421 191.24 224.88C191.24 225.338 191.612 225.71 192.07 225.71Z" fill="#434391"/>
                    <path d="M197.27 228.76C197.756 228.76 198.15 228.366 198.15 227.88C198.15 227.394 197.756 227 197.27 227C196.784 227 196.39 227.394 196.39 227.88C196.39 228.366 196.784 228.76 197.27 228.76Z" fill="#434391"/>
                    <path d="M202.47 231.8C202.978 231.8 203.39 231.388 203.39 230.88C203.39 230.372 202.978 229.96 202.47 229.96C201.962 229.96 201.55 230.372 201.55 230.88C201.55 231.388 201.962 231.8 202.47 231.8Z" fill="#434391"/>
                    <path d="M207.66 234.85C208.196 234.85 208.63 234.415 208.63 233.88C208.63 233.344 208.196 232.91 207.66 232.91C207.124 232.91 206.69 233.344 206.69 233.88C206.69 234.415 207.124 234.85 207.66 234.85Z" fill="#434391"/>
                    <path d="M212.86 237.9C213.423 237.9 213.88 237.443 213.88 236.88C213.88 236.317 213.423 235.86 212.86 235.86C212.297 235.86 211.84 236.317 211.84 236.88C211.84 237.443 212.297 237.9 212.86 237.9Z" fill="#434391"/>
                    <path d="M218.05 240.95C218.641 240.95 219.12 240.471 219.12 239.88C219.12 239.289 218.641 238.81 218.05 238.81C217.459 238.81 216.98 239.289 216.98 239.88C216.98 240.471 217.459 240.95 218.05 240.95Z" fill="#434391"/>
                    <path d="M223.25 243.99C223.863 243.99 224.36 243.493 224.36 242.88C224.36 242.267 223.863 241.77 223.25 241.77C222.637 241.77 222.14 242.267 222.14 242.88C222.14 243.493 222.637 243.99 223.25 243.99Z" fill="#434391"/>
                    <path d="M228.45 247.04C229.091 247.04 229.61 246.52 229.61 245.88C229.61 245.239 229.091 244.72 228.45 244.72C227.809 244.72 227.29 245.239 227.29 245.88C227.29 246.52 227.809 247.04 228.45 247.04Z" fill="#434391"/>
                    <path d="M233.64 250.09C234.308 250.09 234.85 249.548 234.85 248.88C234.85 248.211 234.308 247.67 233.64 247.67C232.972 247.67 232.43 248.211 232.43 248.88C232.43 249.548 232.972 250.09 233.64 250.09Z" fill="#434391"/>
                    <path d="M238.84 253.14C239.536 253.14 240.1 252.576 240.1 251.88C240.1 251.184 239.536 250.62 238.84 250.62C238.144 250.62 237.58 251.184 237.58 251.88C237.58 252.576 238.144 253.14 238.84 253.14Z" fill="#434391"/>
                    <path d="M244.04 256.19C244.763 256.19 245.35 255.603 245.35 254.88C245.35 254.156 244.763 253.57 244.04 253.57C243.316 253.57 242.73 254.156 242.73 254.88C242.73 255.603 243.316 256.19 244.04 256.19Z" fill="#434391"/>
                    <path d="M249.23 259.24C249.981 259.24 250.59 258.631 250.59 257.88C250.59 257.129 249.981 256.52 249.23 256.52C248.479 256.52 247.87 257.129 247.87 257.88C247.87 258.631 248.479 259.24 249.23 259.24Z" fill="#434391"/>
                    <path d="M254.43 262.29C255.209 262.29 255.84 261.658 255.84 260.88C255.84 260.101 255.209 259.47 254.43 259.47C253.651 259.47 253.02 260.101 253.02 260.88C253.02 261.658 253.651 262.29 254.43 262.29Z" fill="#434391"/>
                    <path d="M259.62 265.34C260.426 265.34 261.08 264.686 261.08 263.88C261.08 263.073 260.426 262.42 259.62 262.42C258.814 262.42 258.16 263.073 258.16 263.88C258.16 264.686 258.814 265.34 259.62 265.34Z" fill="#434391"/>
                    <path d="M264.82 267.89C265.378 267.89 265.83 267.437 265.83 266.88C265.83 266.322 265.378 265.87 264.82 265.87C264.262 265.87 263.81 266.322 263.81 266.88C263.81 267.437 264.262 267.89 264.82 267.89Z" fill="#434391"/>
                    <path d="M116.33 188.2C116.396 188.2 116.45 188.146 116.45 188.08C116.45 188.013 116.396 187.96 116.33 187.96C116.264 187.96 116.21 188.013 116.21 188.08C116.21 188.146 116.264 188.2 116.33 188.2Z" fill="#434391"/>
                    <path d="M121.52 191.37C121.68 191.37 121.81 191.24 121.81 191.08C121.81 190.92 121.68 190.79 121.52 190.79C121.36 190.79 121.23 190.92 121.23 191.08C121.23 191.24 121.36 191.37 121.52 191.37Z" fill="#434391"/>
                    <path d="M126.72 194.42C126.908 194.42 127.06 194.268 127.06 194.08C127.06 193.892 126.908 193.74 126.72 193.74C126.532 193.74 126.38 193.892 126.38 194.08C126.38 194.268 126.532 194.42 126.72 194.42Z" fill="#434391"/>
                    <path d="M131.92 197.47C132.135 197.47 132.31 197.295 132.31 197.08C132.31 196.864 132.135 196.69 131.92 196.69C131.705 196.69 131.53 196.864 131.53 197.08C131.53 197.295 131.705 197.47 131.92 197.47Z" fill="#434391"/>
                    <path d="M137.11 200.51C137.347 200.51 137.54 200.317 137.54 200.08C137.54 199.842 137.347 199.65 137.11 199.65C136.873 199.65 136.68 199.842 136.68 200.08C136.68 200.317 136.873 200.51 137.11 200.51Z" fill="#434391"/>
                    <path d="M142.31 203.56C142.575 203.56 142.79 203.345 142.79 203.08C142.79 202.815 142.575 202.6 142.31 202.6C142.045 202.6 141.83 202.815 141.83 203.08C141.83 203.345 142.045 203.56 142.31 203.56Z" fill="#434391"/>
                    <path d="M147.5 206.61C147.793 206.61 148.03 206.373 148.03 206.08C148.03 205.787 147.793 205.55 147.5 205.55C147.207 205.55 146.97 205.787 146.97 206.08C146.97 206.373 147.207 206.61 147.5 206.61Z" fill="#434391"/>
                    <path d="M152.7 209.66C153.02 209.66 153.28 209.4 153.28 209.08C153.28 208.759 153.02 208.5 152.7 208.5C152.38 208.5 152.12 208.759 152.12 209.08C152.12 209.4 152.38 209.66 152.7 209.66Z" fill="#434391"/>
                    <path d="M157.9 212.7C158.242 212.7 158.52 212.422 158.52 212.08C158.52 211.737 158.242 211.46 157.9 211.46C157.558 211.46 157.28 211.737 157.28 212.08C157.28 212.422 157.558 212.7 157.9 212.7Z" fill="#434391"/>
                    <path d="M163.09 215.75C163.46 215.75 163.76 215.45 163.76 215.08C163.76 214.71 163.46 214.41 163.09 214.41C162.72 214.41 162.42 214.71 162.42 215.08C162.42 215.45 162.72 215.75 163.09 215.75Z" fill="#434391"/>
                    <path d="M168.29 218.8C168.688 218.8 169.01 218.478 169.01 218.08C169.01 217.682 168.688 217.36 168.29 217.36C167.892 217.36 167.57 217.682 167.57 218.08C167.57 218.478 167.892 218.8 168.29 218.8Z" fill="#434391"/>
                    <path d="M173.49 221.85C173.915 221.85 174.26 221.505 174.26 221.08C174.26 220.655 173.915 220.31 173.49 220.31C173.065 220.31 172.72 220.655 172.72 221.08C172.72 221.505 173.065 221.85 173.49 221.85Z" fill="#434391"/>
                    <path d="M178.68 224.9C179.133 224.9 179.5 224.533 179.5 224.08C179.5 223.627 179.133 223.26 178.68 223.26C178.227 223.26 177.86 223.627 177.86 224.08C177.86 224.533 178.227 224.9 178.68 224.9Z" fill="#434391"/>
                    <path d="M183.88 227.95C184.36 227.95 184.75 227.56 184.75 227.08C184.75 226.599 184.36 226.21 183.88 226.21C183.4 226.21 183.01 226.599 183.01 227.08C183.01 227.56 183.4 227.95 183.88 227.95Z" fill="#434391"/>
                    <path d="M189.07 231C189.578 231 189.99 230.588 189.99 230.08C189.99 229.572 189.578 229.16 189.07 229.16C188.562 229.16 188.15 229.572 188.15 230.08C188.15 230.588 188.562 231 189.07 231Z" fill="#434391"/>
                    <path d="M194.27 234.04C194.8 234.04 195.23 233.61 195.23 233.08C195.23 232.549 194.8 232.12 194.27 232.12C193.74 232.12 193.31 232.549 193.31 233.08C193.31 233.61 193.74 234.04 194.27 234.04Z" fill="#434391"/>
                    <path d="M199.47 237.09C200.028 237.09 200.48 236.638 200.48 236.08C200.48 235.522 200.028 235.07 199.47 235.07C198.912 235.07 198.46 235.522 198.46 236.08C198.46 236.638 198.912 237.09 199.47 237.09Z" fill="#434391"/>
                    <path d="M204.66 240.14C205.245 240.14 205.72 239.665 205.72 239.08C205.72 238.494 205.245 238.02 204.66 238.02C204.075 238.02 203.6 238.494 203.6 239.08C203.6 239.665 204.075 240.14 204.66 240.14Z" fill="#434391"/>
                    <path d="M209.86 243.19C210.473 243.19 210.97 242.693 210.97 242.08C210.97 241.467 210.473 240.97 209.86 240.97C209.247 240.97 208.75 241.467 208.75 242.08C208.75 242.693 209.247 243.19 209.86 243.19Z" fill="#434391"/>
                    <path d="M215.05 246.24C215.691 246.24 216.21 245.72 216.21 245.08C216.21 244.439 215.691 243.92 215.05 243.92C214.409 243.92 213.89 244.439 213.89 245.08C213.89 245.72 214.409 246.24 215.05 246.24Z" fill="#434391"/>
                    <path d="M220.25 249.28C220.913 249.28 221.45 248.742 221.45 248.08C221.45 247.417 220.913 246.88 220.25 246.88C219.587 246.88 219.05 247.417 219.05 248.08C219.05 248.742 219.587 249.28 220.25 249.28Z" fill="#434391"/>
                    <path d="M225.45 252.33C226.14 252.33 226.7 251.77 226.7 251.08C226.7 250.389 226.14 249.83 225.45 249.83C224.76 249.83 224.2 250.389 224.2 251.08C224.2 251.77 224.76 252.33 225.45 252.33Z" fill="#434391"/>
                    <path d="M230.64 255.38C231.358 255.38 231.94 254.798 231.94 254.08C231.94 253.362 231.358 252.78 230.64 252.78C229.922 252.78 229.34 253.362 229.34 254.08C229.34 254.798 229.922 255.38 230.64 255.38Z" fill="#434391"/>
                    <path d="M235.84 258.43C236.586 258.43 237.19 257.825 237.19 257.08C237.19 256.334 236.586 255.73 235.84 255.73C235.094 255.73 234.49 256.334 234.49 257.08C234.49 257.825 235.094 258.43 235.84 258.43Z" fill="#434391"/>
                    <path d="M241.04 261.47C241.808 261.47 242.43 260.847 242.43 260.08C242.43 259.312 241.808 258.69 241.04 258.69C240.272 258.69 239.65 259.312 239.65 260.08C239.65 260.847 240.272 261.47 241.04 261.47Z" fill="#434391"/>
                    <path d="M246.23 264.52C247.025 264.52 247.67 263.875 247.67 263.08C247.67 262.284 247.025 261.64 246.23 261.64C245.435 261.64 244.79 262.284 244.79 263.08C244.79 263.875 245.435 264.52 246.23 264.52Z" fill="#434391"/>
                    <path d="M251.43 267.57C252.253 267.57 252.92 266.903 252.92 266.08C252.92 265.257 252.253 264.59 251.43 264.59C250.607 264.59 249.94 265.257 249.94 266.08C249.94 266.903 250.607 267.57 251.43 267.57Z" fill="#434391"/>
                    <path d="M256.62 270.62C257.47 270.62 258.16 269.93 258.16 269.08C258.16 268.229 257.47 267.54 256.62 267.54C255.769 267.54 255.08 268.229 255.08 269.08C255.08 269.93 255.769 270.62 256.62 270.62Z" fill="#434391"/>
                    <path d="M261.82 273.64C262.682 273.64 263.38 272.941 263.38 272.08C263.38 271.218 262.682 270.52 261.82 270.52C260.958 270.52 260.26 271.218 260.26 272.08C260.26 272.941 260.958 273.64 261.82 273.64Z" fill="#434391"/>
                    <path d="M267.02 275.64C267.329 275.64 267.58 275.389 267.58 275.08C267.58 274.77 267.329 274.52 267.02 274.52C266.711 274.52 266.46 274.77 266.46 275.08C266.46 275.389 266.711 275.64 267.02 275.64Z" fill="#434391"/>
                    <path d="M108.13 190.41C108.207 190.41 108.27 190.347 108.27 190.27C108.27 190.192 108.207 190.13 108.13 190.13C108.053 190.13 107.99 190.192 107.99 190.27C107.99 190.347 108.053 190.41 108.13 190.41Z" fill="#434391"/>
                    <path d="M113.33 193.59C113.507 193.59 113.65 193.446 113.65 193.27C113.65 193.093 113.507 192.95 113.33 192.95C113.153 192.95 113.01 193.093 113.01 193.27C113.01 193.446 113.153 193.59 113.33 193.59Z" fill="#434391"/>
                    <path d="M118.52 196.64C118.724 196.64 118.89 196.474 118.89 196.27C118.89 196.065 118.724 195.9 118.52 195.9C118.316 195.9 118.15 196.065 118.15 196.27C118.15 196.474 118.316 196.64 118.52 196.64Z" fill="#434391"/>
                    <path d="M123.72 199.69C123.952 199.69 124.14 199.502 124.14 199.27C124.14 199.038 123.952 198.85 123.72 198.85C123.488 198.85 123.3 199.038 123.3 199.27C123.3 199.502 123.488 199.69 123.72 199.69Z" fill="#434391"/>
                    <path d="M128.92 202.74C129.18 202.74 129.39 202.529 129.39 202.27C129.39 202.01 129.18 201.8 128.92 201.8C128.66 201.8 128.45 202.01 128.45 202.27C128.45 202.529 128.66 202.74 128.92 202.74Z" fill="#434391"/>
                    <path d="M134.11 205.79C134.397 205.79 134.63 205.557 134.63 205.27C134.63 204.983 134.397 204.75 134.11 204.75C133.823 204.75 133.59 204.983 133.59 205.27C133.59 205.557 133.823 205.79 134.11 205.79Z" fill="#434391"/>
                    <path d="M139.31 208.84C139.625 208.84 139.88 208.585 139.88 208.27C139.88 207.955 139.625 207.7 139.31 207.7C138.995 207.7 138.74 207.955 138.74 208.27C138.74 208.585 138.995 208.84 139.31 208.84Z" fill="#434391"/>
                    <path d="M144.5 211.89C144.842 211.89 145.12 211.612 145.12 211.27C145.12 210.927 144.842 210.65 144.5 210.65C144.158 210.65 143.88 210.927 143.88 211.27C143.88 211.612 144.158 211.89 144.5 211.89Z" fill="#434391"/>
                    <path d="M149.7 214.94C150.07 214.94 150.37 214.64 150.37 214.27C150.37 213.9 150.07 213.6 149.7 213.6C149.33 213.6 149.03 213.9 149.03 214.27C149.03 214.64 149.33 214.94 149.7 214.94Z" fill="#434391"/>
                    <path d="M154.9 217.98C155.292 217.98 155.61 217.662 155.61 217.27C155.61 216.878 155.292 216.56 154.9 216.56C154.508 216.56 154.19 216.878 154.19 217.27C154.19 217.662 154.508 217.98 154.9 217.98Z" fill="#434391"/>
                    <path d="M160.09 221.03C160.51 221.03 160.85 220.69 160.85 220.27C160.85 219.85 160.51 219.51 160.09 219.51C159.67 219.51 159.33 219.85 159.33 220.27C159.33 220.69 159.67 221.03 160.09 221.03Z" fill="#434391"/>
                    <path d="M165.29 224.08C165.737 224.08 166.1 223.717 166.1 223.27C166.1 222.822 165.737 222.46 165.29 222.46C164.843 222.46 164.48 222.822 164.48 223.27C164.48 223.717 164.843 224.08 165.29 224.08Z" fill="#434391"/>
                    <path d="M170.49 227.13C170.965 227.13 171.35 226.745 171.35 226.27C171.35 225.795 170.965 225.41 170.49 225.41C170.015 225.41 169.63 225.795 169.63 226.27C169.63 226.745 170.015 227.13 170.49 227.13Z" fill="#434391"/>
                    <path d="M175.68 230.17C176.177 230.17 176.58 229.767 176.58 229.27C176.58 228.773 176.177 228.37 175.68 228.37C175.183 228.37 174.78 228.773 174.78 229.27C174.78 229.767 175.183 230.17 175.68 230.17Z" fill="#434391"/>
                    <path d="M180.88 233.22C181.405 233.22 181.83 232.794 181.83 232.27C181.83 231.745 181.405 231.32 180.88 231.32C180.355 231.32 179.93 231.745 179.93 232.27C179.93 232.794 180.355 233.22 180.88 233.22Z" fill="#434391"/>
                    <path d="M186.07 236.27C186.622 236.27 187.07 235.822 187.07 235.27C187.07 234.717 186.622 234.27 186.07 234.27C185.518 234.27 185.07 234.717 185.07 235.27C185.07 235.822 185.518 236.27 186.07 236.27Z" fill="#434391"/>
                    <path d="M191.27 239.32C191.85 239.32 192.32 238.85 192.32 238.27C192.32 237.69 191.85 237.22 191.27 237.22C190.69 237.22 190.22 237.69 190.22 238.27C190.22 238.85 190.69 239.32 191.27 239.32Z" fill="#434391"/>
                    <path d="M196.47 242.36C197.072 242.36 197.56 241.872 197.56 241.27C197.56 240.668 197.072 240.18 196.47 240.18C195.868 240.18 195.38 240.668 195.38 241.27C195.38 241.872 195.868 242.36 196.47 242.36Z" fill="#434391"/>
                    <path d="M201.66 245.41C202.29 245.41 202.8 244.899 202.8 244.27C202.8 243.64 202.29 243.13 201.66 243.13C201.03 243.13 200.52 243.64 200.52 244.27C200.52 244.899 201.03 245.41 201.66 245.41Z" fill="#434391"/>
                    <path d="M206.86 248.46C207.517 248.46 208.05 247.927 208.05 247.27C208.05 246.613 207.517 246.08 206.86 246.08C206.203 246.08 205.67 246.613 205.67 247.27C205.67 247.927 206.203 248.46 206.86 248.46Z" fill="#434391"/>
                    <path d="M212.05 251.51C212.735 251.51 213.29 250.955 213.29 250.27C213.29 249.585 212.735 249.03 212.05 249.03C211.365 249.03 210.81 249.585 210.81 250.27C210.81 250.955 211.365 251.51 212.05 251.51Z" fill="#434391"/>
                    <path d="M217.25 254.56C217.962 254.56 218.54 253.982 218.54 253.27C218.54 252.557 217.962 251.98 217.25 251.98C216.538 251.98 215.96 252.557 215.96 253.27C215.96 253.982 216.538 254.56 217.25 254.56Z" fill="#434391"/>
                    <path d="M222.45 257.6C223.185 257.6 223.78 257.004 223.78 256.27C223.78 255.535 223.185 254.94 222.45 254.94C221.715 254.94 221.12 255.535 221.12 256.27C221.12 257.004 221.715 257.6 222.45 257.6Z" fill="#434391"/>
                    <path d="M227.64 260.65C228.402 260.65 229.02 260.032 229.02 259.27C229.02 258.508 228.402 257.89 227.64 257.89C226.878 257.89 226.26 258.508 226.26 259.27C226.26 260.032 226.878 260.65 227.64 260.65Z" fill="#434391"/>
                    <path d="M232.84 263.7C233.63 263.7 234.27 263.06 234.27 262.27C234.27 261.48 233.63 260.84 232.84 260.84C232.05 260.84 231.41 261.48 231.41 262.27C231.41 263.06 232.05 263.7 232.84 263.7Z" fill="#434391"/>
                    <path d="M238.04 266.75C238.857 266.75 239.52 266.087 239.52 265.27C239.52 264.452 238.857 263.79 238.04 263.79C237.223 263.79 236.56 264.452 236.56 265.27C236.56 266.087 237.223 266.75 238.04 266.75Z" fill="#434391"/>
                    <path d="M243.23 269.79C244.069 269.79 244.75 269.109 244.75 268.27C244.75 267.43 244.069 266.75 243.23 266.75C242.391 266.75 241.71 267.43 241.71 268.27C241.71 269.109 242.391 269.79 243.23 269.79Z" fill="#434391"/>
                    <path d="M248.43 272.84C249.297 272.84 250 272.137 250 271.27C250 270.403 249.297 269.7 248.43 269.7C247.563 269.7 246.86 270.403 246.86 271.27C246.86 272.137 247.563 272.84 248.43 272.84Z" fill="#434391"/>
                    <path d="M253.62 275.89C254.515 275.89 255.24 275.164 255.24 274.27C255.24 273.375 254.515 272.65 253.62 272.65C252.725 272.65 252 273.375 252 274.27C252 275.164 252.725 275.89 253.62 275.89Z" fill="#434391"/>
                    <path d="M258.82 278.94C259.742 278.94 260.49 278.192 260.49 277.27C260.49 276.348 259.742 275.6 258.82 275.6C257.898 275.6 257.15 276.348 257.15 277.27C257.15 278.192 257.898 278.94 258.82 278.94Z" fill="#434391"/>
                    <path d="M264.02 281.8C264.865 281.8 265.55 281.115 265.55 280.27C265.55 279.425 264.865 278.74 264.02 278.74C263.175 278.74 262.49 279.425 262.49 280.27C262.49 281.115 263.175 281.8 264.02 281.8Z" fill="#434391"/>
                    <path d="M99.94 192.56C99.9897 192.56 100.03 192.519 100.03 192.47C100.03 192.42 99.9897 192.38 99.94 192.38C99.8903 192.38 99.85 192.42 99.85 192.47C99.85 192.519 99.8903 192.56 99.94 192.56Z" fill="#434391"/>
                    <path d="M105.13 195.83C105.329 195.83 105.49 195.669 105.49 195.47C105.49 195.271 105.329 195.11 105.13 195.11C104.931 195.11 104.77 195.271 104.77 195.47C104.77 195.669 104.931 195.83 105.13 195.83Z" fill="#434391"/>
                    <path d="M110.33 198.88C110.556 198.88 110.74 198.696 110.74 198.47C110.74 198.243 110.556 198.06 110.33 198.06C110.104 198.06 109.92 198.243 109.92 198.47C109.92 198.696 110.104 198.88 110.33 198.88Z" fill="#434391"/>
                    <path d="M115.52 201.93C115.774 201.93 115.98 201.724 115.98 201.47C115.98 201.216 115.774 201.01 115.52 201.01C115.266 201.01 115.06 201.216 115.06 201.47C115.06 201.724 115.266 201.93 115.52 201.93Z" fill="#434391"/>
                    <path d="M120.72 204.98C121.002 204.98 121.23 204.751 121.23 204.47C121.23 204.188 121.002 203.96 120.72 203.96C120.438 203.96 120.21 204.188 120.21 204.47C120.21 204.751 120.438 204.98 120.72 204.98Z" fill="#434391"/>
                    <path d="M125.92 208.02C126.224 208.02 126.47 207.773 126.47 207.47C126.47 207.166 126.224 206.92 125.92 206.92C125.616 206.92 125.37 207.166 125.37 207.47C125.37 207.773 125.616 208.02 125.92 208.02Z" fill="#434391"/>
                    <path d="M131.11 211.07C131.441 211.07 131.71 210.801 131.71 210.47C131.71 210.139 131.441 209.87 131.11 209.87C130.779 209.87 130.51 210.139 130.51 210.47C130.51 210.801 130.779 211.07 131.11 211.07Z" fill="#434391"/>
                    <path d="M136.31 214.12C136.669 214.12 136.96 213.829 136.96 213.47C136.96 213.111 136.669 212.82 136.31 212.82C135.951 212.82 135.66 213.111 135.66 213.47C135.66 213.829 135.951 214.12 136.31 214.12Z" fill="#434391"/>
                    <path d="M141.5 217.17C141.887 217.17 142.2 216.856 142.2 216.47C142.2 216.083 141.887 215.77 141.5 215.77C141.113 215.77 140.8 216.083 140.8 216.47C140.8 216.856 141.113 217.17 141.5 217.17Z" fill="#434391"/>
                    <path d="M146.7 220.21C147.109 220.21 147.44 219.878 147.44 219.47C147.44 219.061 147.109 218.73 146.7 218.73C146.291 218.73 145.96 219.061 145.96 219.47C145.96 219.878 146.291 220.21 146.7 220.21Z" fill="#434391"/>
                    <path d="M151.9 223.26C152.336 223.26 152.69 222.906 152.69 222.47C152.69 222.033 152.336 221.68 151.9 221.68C151.464 221.68 151.11 222.033 151.11 222.47C151.11 222.906 151.464 223.26 151.9 223.26Z" fill="#434391"/>
                    <path d="M157.09 226.31C157.554 226.31 157.93 225.934 157.93 225.47C157.93 225.006 157.554 224.63 157.09 224.63C156.626 224.63 156.25 225.006 156.25 225.47C156.25 225.934 156.626 226.31 157.09 226.31Z" fill="#434391"/>
                    <path d="M162.29 229.36C162.782 229.36 163.18 228.961 163.18 228.47C163.18 227.978 162.782 227.58 162.29 227.58C161.798 227.58 161.4 227.978 161.4 228.47C161.4 228.961 161.798 229.36 162.29 229.36Z" fill="#434391"/>
                    <path d="M167.49 232.4C168.004 232.4 168.42 231.983 168.42 231.47C168.42 230.956 168.004 230.54 167.49 230.54C166.976 230.54 166.56 230.956 166.56 231.47C166.56 231.983 166.976 232.4 167.49 232.4Z" fill="#434391"/>
                    <path d="M172.68 235.45C173.221 235.45 173.66 235.011 173.66 234.47C173.66 233.929 173.221 233.49 172.68 233.49C172.139 233.49 171.7 233.929 171.7 234.47C171.7 235.011 172.139 235.45 172.68 235.45Z" fill="#434391"/>
                    <path d="M177.88 238.5C178.449 238.5 178.91 238.039 178.91 237.47C178.91 236.901 178.449 236.44 177.88 236.44C177.311 236.44 176.85 236.901 176.85 237.47C176.85 238.039 177.311 238.5 177.88 238.5Z" fill="#434391"/>
                    <path d="M183.07 241.55C183.666 241.55 184.15 241.066 184.15 240.47C184.15 239.873 183.666 239.39 183.07 239.39C182.474 239.39 181.99 239.873 181.99 240.47C181.99 241.066 182.474 241.55 183.07 241.55Z" fill="#434391"/>
                    <path d="M188.27 244.59C188.889 244.59 189.39 244.088 189.39 243.47C189.39 242.851 188.889 242.35 188.27 242.35C187.651 242.35 187.15 242.851 187.15 243.47C187.15 244.088 187.651 244.59 188.27 244.59Z" fill="#434391"/>
                    <path d="M193.47 247.64C194.116 247.64 194.64 247.116 194.64 246.47C194.64 245.824 194.116 245.3 193.47 245.3C192.824 245.3 192.3 245.824 192.3 246.47C192.3 247.116 192.824 247.64 193.47 247.64Z" fill="#434391"/>
                    <path d="M198.66 250.69C199.334 250.69 199.88 250.144 199.88 249.47C199.88 248.796 199.334 248.25 198.66 248.25C197.986 248.25 197.44 248.796 197.44 249.47C197.44 250.144 197.986 250.69 198.66 250.69Z" fill="#434391"/>
                    <path d="M203.86 253.74C204.561 253.74 205.13 253.171 205.13 252.47C205.13 251.768 204.561 251.2 203.86 251.2C203.159 251.2 202.59 251.768 202.59 252.47C202.59 253.171 203.159 253.74 203.86 253.74Z" fill="#434391"/>
                    <path d="M209.05 256.79C209.779 256.79 210.37 256.199 210.37 255.47C210.37 254.741 209.779 254.15 209.05 254.15C208.321 254.15 207.73 254.741 207.73 255.47C207.73 256.199 208.321 256.79 209.05 256.79Z" fill="#434391"/>
                    <path d="M214.25 259.83C215.001 259.83 215.61 259.221 215.61 258.47C215.61 257.719 215.001 257.11 214.25 257.11C213.499 257.11 212.89 257.719 212.89 258.47C212.89 259.221 213.499 259.83 214.25 259.83Z" fill="#434391"/>
                    <path d="M219.45 262.88C220.229 262.88 220.86 262.249 220.86 261.47C220.86 260.691 220.229 260.06 219.45 260.06C218.671 260.06 218.04 260.691 218.04 261.47C218.04 262.249 218.671 262.88 219.45 262.88Z" fill="#434391"/>
                    <path d="M224.64 265.93C225.446 265.93 226.1 265.276 226.1 264.47C226.1 263.663 225.446 263.01 224.64 263.01C223.834 263.01 223.18 263.663 223.18 264.47C223.18 265.276 223.834 265.93 224.64 265.93Z" fill="#434391"/>
                    <path d="M229.84 268.98C230.674 268.98 231.35 268.304 231.35 267.47C231.35 266.636 230.674 265.96 229.84 265.96C229.006 265.96 228.33 266.636 228.33 267.47C228.33 268.304 229.006 268.98 229.84 268.98Z" fill="#434391"/>
                    <path d="M235.04 272.03C235.902 272.03 236.6 271.331 236.6 270.47C236.6 269.608 235.902 268.91 235.04 268.91C234.178 268.91 233.48 269.608 233.48 270.47C233.48 271.331 234.178 272.03 235.04 272.03Z" fill="#434391"/>
                    <path d="M240.23 275.08C241.119 275.08 241.84 274.359 241.84 273.47C241.84 272.581 241.119 271.86 240.23 271.86C239.341 271.86 238.62 272.581 238.62 273.47C238.62 274.359 239.341 275.08 240.23 275.08Z" fill="#434391"/>
                    <path d="M245.43 278.13C246.347 278.13 247.09 277.387 247.09 276.47C247.09 275.553 246.347 274.81 245.43 274.81C244.513 274.81 243.77 275.553 243.77 276.47C243.77 277.387 244.513 278.13 245.43 278.13Z" fill="#434391"/>
                    <path d="M250.62 281.18C251.564 281.18 252.33 280.414 252.33 279.47C252.33 278.525 251.564 277.76 250.62 277.76C249.676 277.76 248.91 278.525 248.91 279.47C248.91 280.414 249.676 281.18 250.62 281.18Z" fill="#434391"/>
                    <path d="M255.82 284.22C256.786 284.22 257.57 283.436 257.57 282.47C257.57 281.503 256.786 280.72 255.82 280.72C254.853 280.72 254.07 281.503 254.07 282.47C254.07 283.436 254.853 284.22 255.82 284.22Z" fill="#434391"/>
                    <path d="M261.02 287.27C262.014 287.27 262.82 286.464 262.82 285.47C262.82 284.476 262.014 283.67 261.02 283.67C260.026 283.67 259.22 284.476 259.22 285.47C259.22 286.464 260.026 287.27 261.02 287.27Z" fill="#434391"/>
                    <path d="M266.21 289.43C266.74 289.43 267.17 289 267.17 288.47C267.17 287.94 266.74 287.51 266.21 287.51C265.68 287.51 265.25 287.94 265.25 288.47C265.25 289 265.68 289.43 266.21 289.43Z" fill="#434391"/>
                    <path d="M96.94 197.99C97.1222 197.99 97.27 197.842 97.27 197.66C97.27 197.478 97.1222 197.33 96.94 197.33C96.7577 197.33 96.61 197.478 96.61 197.66C96.61 197.842 96.7577 197.99 96.94 197.99Z" fill="#434391"/>
                    <path d="M102.13 201.1C102.373 201.1 102.57 200.903 102.57 200.66C102.57 200.417 102.373 200.22 102.13 200.22C101.887 200.22 101.69 200.417 101.69 200.66C101.69 200.903 101.887 201.1 102.13 201.1Z" fill="#434391"/>
                    <path d="M107.33 204.15C107.601 204.15 107.82 203.93 107.82 203.66C107.82 203.389 107.601 203.17 107.33 203.17C107.059 203.17 106.84 203.389 106.84 203.66C106.84 203.93 107.059 204.15 107.33 204.15Z" fill="#434391"/>
                    <path d="M112.52 207.2C112.818 207.2 113.06 206.958 113.06 206.66C113.06 206.362 112.818 206.12 112.52 206.12C112.222 206.12 111.98 206.362 111.98 206.66C111.98 206.958 112.222 207.2 112.52 207.2Z" fill="#434391"/>
                    <path d="M117.72 210.25C118.046 210.25 118.31 209.986 118.31 209.66C118.31 209.334 118.046 209.07 117.72 209.07C117.394 209.07 117.13 209.334 117.13 209.66C117.13 209.986 117.394 210.25 117.72 210.25Z" fill="#434391"/>
                    <path d="M122.92 213.3C123.273 213.3 123.56 213.013 123.56 212.66C123.56 212.306 123.273 212.02 122.92 212.02C122.567 212.02 122.28 212.306 122.28 212.66C122.28 213.013 122.567 213.3 122.92 213.3Z" fill="#434391"/>
                    <path d="M128.11 216.34C128.486 216.34 128.79 216.035 128.79 215.66C128.79 215.284 128.486 214.98 128.11 214.98C127.734 214.98 127.43 215.284 127.43 215.66C127.43 216.035 127.734 216.34 128.11 216.34Z" fill="#434391"/>
                    <path d="M133.31 219.39C133.713 219.39 134.04 219.063 134.04 218.66C134.04 218.257 133.713 217.93 133.31 217.93C132.907 217.93 132.58 218.257 132.58 218.66C132.58 219.063 132.907 219.39 133.31 219.39Z" fill="#434391"/>
                    <path d="M138.5 222.44C138.931 222.44 139.28 222.09 139.28 221.66C139.28 221.229 138.931 220.88 138.5 220.88C138.069 220.88 137.72 221.229 137.72 221.66C137.72 222.09 138.069 222.44 138.5 222.44Z" fill="#434391"/>
                    <path d="M143.7 225.49C144.158 225.49 144.53 225.118 144.53 224.66C144.53 224.201 144.158 223.83 143.7 223.83C143.242 223.83 142.87 224.201 142.87 224.66C142.87 225.118 143.242 225.49 143.7 225.49Z" fill="#434391"/>
                    <path d="M148.9 228.53C149.38 228.53 149.77 228.14 149.77 227.66C149.77 227.179 149.38 226.79 148.9 226.79C148.42 226.79 148.03 227.179 148.03 227.66C148.03 228.14 148.42 228.53 148.9 228.53Z" fill="#434391"/>
                    <path d="M154.09 231.58C154.598 231.58 155.01 231.168 155.01 230.66C155.01 230.152 154.598 229.74 154.09 229.74C153.582 229.74 153.17 230.152 153.17 230.66C153.17 231.168 153.582 231.58 154.09 231.58Z" fill="#434391"/>
                    <path d="M159.29 234.63C159.826 234.63 160.26 234.195 160.26 233.66C160.26 233.124 159.826 232.69 159.29 232.69C158.754 232.69 158.32 233.124 158.32 233.66C158.32 234.195 158.754 234.63 159.29 234.63Z" fill="#434391"/>
                    <path d="M164.49 237.68C165.053 237.68 165.51 237.223 165.51 236.66C165.51 236.096 165.053 235.64 164.49 235.64C163.927 235.64 163.47 236.096 163.47 236.66C163.47 237.223 163.927 237.68 164.49 237.68Z" fill="#434391"/>
                    <path d="M169.68 240.72C170.265 240.72 170.74 240.245 170.74 239.66C170.74 239.074 170.265 238.6 169.68 238.6C169.095 238.6 168.62 239.074 168.62 239.66C168.62 240.245 169.095 240.72 169.68 240.72Z" fill="#434391"/>
                    <path d="M174.88 243.77C175.493 243.77 175.99 243.273 175.99 242.66C175.99 242.047 175.493 241.55 174.88 241.55C174.267 241.55 173.77 242.047 173.77 242.66C173.77 243.273 174.267 243.77 174.88 243.77Z" fill="#434391"/>
                    <path d="M180.07 246.82C180.711 246.82 181.23 246.3 181.23 245.66C181.23 245.019 180.711 244.5 180.07 244.5C179.429 244.5 178.91 245.019 178.91 245.66C178.91 246.3 179.429 246.82 180.07 246.82Z" fill="#434391"/>
                    <path d="M185.27 249.87C185.938 249.87 186.48 249.328 186.48 248.66C186.48 247.991 185.938 247.45 185.27 247.45C184.602 247.45 184.06 247.991 184.06 248.66C184.06 249.328 184.602 249.87 185.27 249.87Z" fill="#434391"/>
                    <path d="M190.47 252.92C191.166 252.92 191.73 252.356 191.73 251.66C191.73 250.964 191.166 250.4 190.47 250.4C189.774 250.4 189.21 250.964 189.21 251.66C189.21 252.356 189.774 252.92 190.47 252.92Z" fill="#434391"/>
                    <path d="M195.66 255.97C196.383 255.97 196.97 255.383 196.97 254.66C196.97 253.936 196.383 253.35 195.66 253.35C194.937 253.35 194.35 253.936 194.35 254.66C194.35 255.383 194.937 255.97 195.66 255.97Z" fill="#434391"/>
                    <path d="M200.86 259.02C201.611 259.02 202.22 258.411 202.22 257.66C202.22 256.909 201.611 256.3 200.86 256.3C200.109 256.3 199.5 256.909 199.5 257.66C199.5 258.411 200.109 259.02 200.86 259.02Z" fill="#434391"/>
                    <path d="M206.05 262.07C206.829 262.07 207.46 261.438 207.46 260.66C207.46 259.881 206.829 259.25 206.05 259.25C205.271 259.25 204.64 259.881 204.64 260.66C204.64 261.438 205.271 262.07 206.05 262.07Z" fill="#434391"/>
                    <path d="M211.25 265.11C212.051 265.11 212.7 264.461 212.7 263.66C212.7 262.859 212.051 262.21 211.25 262.21C210.449 262.21 209.8 262.859 209.8 263.66C209.8 264.461 210.449 265.11 211.25 265.11Z" fill="#434391"/>
                    <path d="M216.45 268.16C217.278 268.16 217.95 267.488 217.95 266.66C217.95 265.831 217.278 265.16 216.45 265.16C215.622 265.16 214.95 265.831 214.95 266.66C214.95 267.488 215.622 268.16 216.45 268.16Z" fill="#434391"/>
                    <path d="M221.64 271.21C222.496 271.21 223.19 270.516 223.19 269.66C223.19 268.804 222.496 268.11 221.64 268.11C220.784 268.11 220.09 268.804 220.09 269.66C220.09 270.516 220.784 271.21 221.64 271.21Z" fill="#434391"/>
                    <path d="M226.84 274.26C227.724 274.26 228.44 273.543 228.44 272.66C228.44 271.776 227.724 271.06 226.84 271.06C225.956 271.06 225.24 271.776 225.24 272.66C225.24 273.543 225.956 274.26 226.84 274.26Z" fill="#434391"/>
                    <path d="M232.04 277.3C232.946 277.3 233.68 276.566 233.68 275.66C233.68 274.754 232.946 274.02 232.04 274.02C231.134 274.02 230.4 274.754 230.4 275.66C230.4 276.566 231.134 277.3 232.04 277.3Z" fill="#434391"/>
                    <path d="M237.23 280.35C238.163 280.35 238.92 279.593 238.92 278.66C238.92 277.726 238.163 276.97 237.23 276.97C236.297 276.97 235.54 277.726 235.54 278.66C235.54 279.593 236.297 280.35 237.23 280.35Z" fill="#434391"/>
                    <path d="M242.43 283.4C243.391 283.4 244.17 282.621 244.17 281.66C244.17 280.699 243.391 279.92 242.43 279.92C241.469 279.92 240.69 280.699 240.69 281.66C240.69 282.621 241.469 283.4 242.43 283.4Z" fill="#434391"/>
                    <path d="M247.62 286.45C248.609 286.45 249.41 285.648 249.41 284.66C249.41 283.671 248.609 282.87 247.62 282.87C246.631 282.87 245.83 283.671 245.83 284.66C245.83 285.648 246.631 286.45 247.62 286.45Z" fill="#434391"/>
                    <path d="M252.82 289.38C253.77 289.38 254.54 288.61 254.54 287.66C254.54 286.71 253.77 285.94 252.82 285.94C251.87 285.94 251.1 286.71 251.1 287.66C251.1 288.61 251.87 289.38 252.82 289.38Z" fill="#434391"/>
                    <path d="M258.02 291.58C258.528 291.58 258.94 291.168 258.94 290.66C258.94 290.152 258.528 289.74 258.02 289.74C257.512 289.74 257.1 290.152 257.1 290.66C257.1 291.168 257.512 291.58 258.02 291.58Z" fill="#434391"/>
                    <path d="M88.74 199.97C88.8007 199.97 88.85 199.921 88.85 199.86C88.85 199.799 88.8007 199.75 88.74 199.75C88.6792 199.75 88.63 199.799 88.63 199.86C88.63 199.921 88.6792 199.97 88.74 199.97Z" fill="#434391"/>
                    <path d="M93.94 203.33C94.1996 203.33 94.41 203.119 94.41 202.86C94.41 202.6 94.1996 202.39 93.94 202.39C93.6804 202.39 93.47 202.6 93.47 202.86C93.47 203.119 93.6804 203.33 93.94 203.33Z" fill="#434391"/>
                    <path d="M99.13 206.38C99.4172 206.38 99.65 206.147 99.65 205.86C99.65 205.573 99.4172 205.34 99.13 205.34C98.8428 205.34 98.61 205.573 98.61 205.86C98.61 206.147 98.8428 206.38 99.13 206.38Z" fill="#434391"/>
                    <path d="M104.33 209.43C104.645 209.43 104.9 209.175 104.9 208.86C104.9 208.545 104.645 208.29 104.33 208.29C104.015 208.29 103.76 208.545 103.76 208.86C103.76 209.175 104.015 209.43 104.33 209.43Z" fill="#434391"/>
                    <path d="M109.52 212.48C109.862 212.48 110.14 212.202 110.14 211.86C110.14 211.517 109.862 211.24 109.52 211.24C109.178 211.24 108.9 211.517 108.9 211.86C108.9 212.202 109.178 212.48 109.52 212.48Z" fill="#434391"/>
                    <path d="M114.72 215.53C115.09 215.53 115.39 215.23 115.39 214.86C115.39 214.49 115.09 214.19 114.72 214.19C114.35 214.19 114.05 214.49 114.05 214.86C114.05 215.23 114.35 215.53 114.72 215.53Z" fill="#434391"/>
                    <path d="M119.92 218.58C120.318 218.58 120.64 218.257 120.64 217.86C120.64 217.462 120.318 217.14 119.92 217.14C119.522 217.14 119.2 217.462 119.2 217.86C119.2 218.257 119.522 218.58 119.92 218.58Z" fill="#434391"/>
                    <path d="M125.11 221.63C125.535 221.63 125.88 221.285 125.88 220.86C125.88 220.435 125.535 220.09 125.11 220.09C124.685 220.09 124.34 220.435 124.34 220.86C124.34 221.285 124.685 221.63 125.11 221.63Z" fill="#434391"/>
                    <path d="M130.31 224.68C130.763 224.68 131.13 224.313 131.13 223.86C131.13 223.407 130.763 223.04 130.31 223.04C129.857 223.04 129.49 223.407 129.49 223.86C129.49 224.313 129.857 224.68 130.31 224.68Z" fill="#434391"/>
                    <path d="M135.5 227.73C135.98 227.73 136.37 227.34 136.37 226.86C136.37 226.379 135.98 225.99 135.5 225.99C135.02 225.99 134.63 226.379 134.63 226.86C134.63 227.34 135.02 227.73 135.5 227.73Z" fill="#434391"/>
                    <path d="M140.7 230.77C141.203 230.77 141.61 230.362 141.61 229.86C141.61 229.357 141.203 228.95 140.7 228.95C140.197 228.95 139.79 229.357 139.79 229.86C139.79 230.362 140.197 230.77 140.7 230.77Z" fill="#434391"/>
                    <path d="M145.9 233.82C146.43 233.82 146.86 233.39 146.86 232.86C146.86 232.329 146.43 231.9 145.9 231.9C145.37 231.9 144.94 232.329 144.94 232.86C144.94 233.39 145.37 233.82 145.9 233.82Z" fill="#434391"/>
                    <path d="M151.09 236.87C151.648 236.87 152.1 236.418 152.1 235.86C152.1 235.302 151.648 234.85 151.09 234.85C150.532 234.85 150.08 235.302 150.08 235.86C150.08 236.418 150.532 236.87 151.09 236.87Z" fill="#434391"/>
                    <path d="M156.29 239.92C156.875 239.92 157.35 239.445 157.35 238.86C157.35 238.274 156.875 237.8 156.29 237.8C155.705 237.8 155.23 238.274 155.23 238.86C155.23 239.445 155.705 239.92 156.29 239.92Z" fill="#434391"/>
                    <path d="M161.49 242.96C162.098 242.96 162.59 242.467 162.59 241.86C162.59 241.252 162.098 240.76 161.49 240.76C160.882 240.76 160.39 241.252 160.39 241.86C160.39 242.467 160.882 242.96 161.49 242.96Z" fill="#434391"/>
                    <path d="M166.68 246.01C167.315 246.01 167.83 245.495 167.83 244.86C167.83 244.225 167.315 243.71 166.68 243.71C166.045 243.71 165.53 244.225 165.53 244.86C165.53 245.495 166.045 246.01 166.68 246.01Z" fill="#434391"/>
                    <path d="M171.88 249.06C172.543 249.06 173.08 248.522 173.08 247.86C173.08 247.197 172.543 246.66 171.88 246.66C171.217 246.66 170.68 247.197 170.68 247.86C170.68 248.522 171.217 249.06 171.88 249.06Z" fill="#434391"/>
                    <path d="M177.07 252.11C177.76 252.11 178.32 251.55 178.32 250.86C178.32 250.17 177.76 249.61 177.07 249.61C176.38 249.61 175.82 250.17 175.82 250.86C175.82 251.55 176.38 252.11 177.07 252.11Z" fill="#434391"/>
                    <path d="M182.27 255.16C182.988 255.16 183.57 254.578 183.57 253.86C183.57 253.142 182.988 252.56 182.27 252.56C181.552 252.56 180.97 253.142 180.97 253.86C180.97 254.578 181.552 255.16 182.27 255.16Z" fill="#434391"/>
                    <path d="M187.47 258.2C188.21 258.2 188.81 257.6 188.81 256.86C188.81 256.12 188.21 255.52 187.47 255.52C186.73 255.52 186.13 256.12 186.13 256.86C186.13 257.6 186.73 258.2 187.47 258.2Z" fill="#434391"/>
                    <path d="M192.66 261.25C193.428 261.25 194.05 260.627 194.05 259.86C194.05 259.092 193.428 258.47 192.66 258.47C191.892 258.47 191.27 259.092 191.27 259.86C191.27 260.627 191.892 261.25 192.66 261.25Z" fill="#434391"/>
                    <path d="M197.86 264.3C198.655 264.3 199.3 263.655 199.3 262.86C199.3 262.064 198.655 261.42 197.86 261.42C197.065 261.42 196.42 262.064 196.42 262.86C196.42 263.655 197.065 264.3 197.86 264.3Z" fill="#434391"/>
                    <path d="M203.05 267.35C203.873 267.35 204.54 266.683 204.54 265.86C204.54 265.037 203.873 264.37 203.05 264.37C202.227 264.37 201.56 265.037 201.56 265.86C201.56 266.683 202.227 267.35 203.05 267.35Z" fill="#434391"/>
                    <path d="M208.25 270.39C209.095 270.39 209.78 269.705 209.78 268.86C209.78 268.015 209.095 267.33 208.25 267.33C207.405 267.33 206.72 268.015 206.72 268.86C206.72 269.705 207.405 270.39 208.25 270.39Z" fill="#434391"/>
                    <path d="M213.45 273.44C214.323 273.44 215.03 272.732 215.03 271.86C215.03 270.987 214.323 270.28 213.45 270.28C212.577 270.28 211.87 270.987 211.87 271.86C211.87 272.732 212.577 273.44 213.45 273.44Z" fill="#434391"/>
                    <path d="M218.64 276.49C219.54 276.49 220.27 275.76 220.27 274.86C220.27 273.96 219.54 273.23 218.64 273.23C217.74 273.23 217.01 273.96 217.01 274.86C217.01 275.76 217.74 276.49 218.64 276.49Z" fill="#434391"/>
                    <path d="M223.84 279.54C224.768 279.54 225.52 278.788 225.52 277.86C225.52 276.932 224.768 276.18 223.84 276.18C222.912 276.18 222.16 276.932 222.16 277.86C222.16 278.788 222.912 279.54 223.84 279.54Z" fill="#434391"/>
                    <path d="M229.04 282.58C229.99 282.58 230.76 281.81 230.76 280.86C230.76 279.91 229.99 279.14 229.04 279.14C228.09 279.14 227.32 279.91 227.32 280.86C227.32 281.81 228.09 282.58 229.04 282.58Z" fill="#434391"/>
                    <path d="M234.23 285.63C235.208 285.63 236 284.837 236 283.86C236 282.882 235.208 282.09 234.23 282.09C233.252 282.09 232.46 282.882 232.46 283.86C232.46 284.837 233.252 285.63 234.23 285.63Z" fill="#434391"/>
                    <path d="M239.43 288.64C240.413 288.64 241.21 287.843 241.21 286.86C241.21 285.877 240.413 285.08 239.43 285.08C238.447 285.08 237.65 285.877 237.65 286.86C237.65 287.843 238.447 288.64 239.43 288.64Z" fill="#434391"/>
                    <path d="M244.62 291.05C245.277 291.05 245.81 290.517 245.81 289.86C245.81 289.202 245.277 288.67 244.62 288.67C243.963 288.67 243.43 289.202 243.43 289.86C243.43 290.517 243.963 291.05 244.62 291.05Z" fill="#434391"/>
                    <path d="M85.74 205.31C85.8781 205.31 85.99 205.198 85.99 205.06C85.99 204.922 85.8781 204.81 85.74 204.81C85.6019 204.81 85.49 204.922 85.49 205.06C85.49 205.198 85.6019 205.31 85.74 205.31Z" fill="#434391"/>
                    <path d="M90.94 208.63C91.2548 208.63 91.51 208.375 91.51 208.06C91.51 207.745 91.2548 207.49 90.94 207.49C90.6252 207.49 90.37 207.745 90.37 208.06C90.37 208.375 90.6252 208.63 90.94 208.63Z" fill="#434391"/>
                    <path d="M96.13 211.67C96.4669 211.67 96.74 211.397 96.74 211.06C96.74 210.723 96.4669 210.45 96.13 210.45C95.7931 210.45 95.52 210.723 95.52 211.06C95.52 211.397 95.7931 211.67 96.13 211.67Z" fill="#434391"/>
                    <path d="M101.33 214.72C101.695 214.72 101.99 214.424 101.99 214.06C101.99 213.695 101.695 213.4 101.33 213.4C100.965 213.4 100.67 213.695 100.67 214.06C100.67 214.424 100.965 214.72 101.33 214.72Z" fill="#434391"/>
                    <path d="M106.52 217.77C106.912 217.77 107.23 217.452 107.23 217.06C107.23 216.668 106.912 216.35 106.52 216.35C106.128 216.35 105.81 216.668 105.81 217.06C105.81 217.452 106.128 217.77 106.52 217.77Z" fill="#434391"/>
                    <path d="M111.72 220.82C112.14 220.82 112.48 220.48 112.48 220.06C112.48 219.64 112.14 219.3 111.72 219.3C111.3 219.3 110.96 219.64 110.96 220.06C110.96 220.48 111.3 220.82 111.72 220.82Z" fill="#434391"/>
                    <path d="M116.92 223.86C117.362 223.86 117.72 223.502 117.72 223.06C117.72 222.618 117.362 222.26 116.92 222.26C116.478 222.26 116.12 222.618 116.12 223.06C116.12 223.502 116.478 223.86 116.92 223.86Z" fill="#434391"/>
                    <path d="M122.11 226.91C122.579 226.91 122.96 226.529 122.96 226.06C122.96 225.59 122.579 225.21 122.11 225.21C121.641 225.21 121.26 225.59 121.26 226.06C121.26 226.529 121.641 226.91 122.11 226.91Z" fill="#434391"/>
                    <path d="M127.31 229.96C127.807 229.96 128.21 229.557 128.21 229.06C128.21 228.563 127.807 228.16 127.31 228.16C126.813 228.16 126.41 228.563 126.41 229.06C126.41 229.557 126.813 229.96 127.31 229.96Z" fill="#434391"/>
                    <path d="M132.5 233.01C133.025 233.01 133.45 232.585 133.45 232.06C133.45 231.535 133.025 231.11 132.5 231.11C131.975 231.11 131.55 231.535 131.55 232.06C131.55 232.585 131.975 233.01 132.5 233.01Z" fill="#434391"/>
                    <path d="M137.7 236.05C138.247 236.05 138.69 235.607 138.69 235.06C138.69 234.513 138.247 234.07 137.7 234.07C137.153 234.07 136.71 234.513 136.71 235.06C136.71 235.607 137.153 236.05 137.7 236.05Z" fill="#434391"/>
                    <path d="M142.9 239.1C143.474 239.1 143.94 238.634 143.94 238.06C143.94 237.485 143.474 237.02 142.9 237.02C142.326 237.02 141.86 237.485 141.86 238.06C141.86 238.634 142.326 239.1 142.9 239.1Z" fill="#434391"/>
                    <path d="M148.09 242.15C148.692 242.15 149.18 241.662 149.18 241.06C149.18 240.458 148.692 239.97 148.09 239.97C147.488 239.97 147 240.458 147 241.06C147 241.662 147.488 242.15 148.09 242.15Z" fill="#434391"/>
                    <path d="M153.29 245.2C153.92 245.2 154.43 244.689 154.43 244.06C154.43 243.43 153.92 242.92 153.29 242.92C152.66 242.92 152.15 243.43 152.15 244.06C152.15 244.689 152.66 245.2 153.29 245.2Z" fill="#434391"/>
                    <path d="M158.49 248.24C159.142 248.24 159.67 247.711 159.67 247.06C159.67 246.408 159.142 245.88 158.49 245.88C157.838 245.88 157.31 246.408 157.31 247.06C157.31 247.711 157.838 248.24 158.49 248.24Z" fill="#434391"/>
                    <path d="M163.68 251.29C164.359 251.29 164.91 250.739 164.91 250.06C164.91 249.381 164.359 248.83 163.68 248.83C163.001 248.83 162.45 249.381 162.45 250.06C162.45 250.739 163.001 251.29 163.68 251.29Z" fill="#434391"/>
                    <path d="M168.88 254.34C169.587 254.34 170.16 253.767 170.16 253.06C170.16 252.353 169.587 251.78 168.88 251.78C168.173 251.78 167.6 252.353 167.6 253.06C167.6 253.767 168.173 254.34 168.88 254.34Z" fill="#434391"/>
                    <path d="M174.07 257.39C174.805 257.39 175.4 256.794 175.4 256.06C175.4 255.325 174.805 254.73 174.07 254.73C173.335 254.73 172.74 255.325 172.74 256.06C172.74 256.794 173.335 257.39 174.07 257.39Z" fill="#434391"/>
                    <path d="M179.27 260.43C180.027 260.43 180.64 259.816 180.64 259.06C180.64 258.303 180.027 257.69 179.27 257.69C178.513 257.69 177.9 258.303 177.9 259.06C177.9 259.816 178.513 260.43 179.27 260.43Z" fill="#434391"/>
                    <path d="M184.47 263.48C185.254 263.48 185.89 262.844 185.89 262.06C185.89 261.275 185.254 260.64 184.47 260.64C183.686 260.64 183.05 261.275 183.05 262.06C183.05 262.844 183.686 263.48 184.47 263.48Z" fill="#434391"/>
                    <path d="M189.66 266.53C190.472 266.53 191.13 265.872 191.13 265.06C191.13 264.248 190.472 263.59 189.66 263.59C188.848 263.59 188.19 264.248 188.19 265.06C188.19 265.872 188.848 266.53 189.66 266.53Z" fill="#434391"/>
                    <path d="M194.86 269.58C195.699 269.58 196.38 268.899 196.38 268.06C196.38 267.22 195.699 266.54 194.86 266.54C194.021 266.54 193.34 267.22 193.34 268.06C193.34 268.899 194.021 269.58 194.86 269.58Z" fill="#434391"/>
                    <path d="M200.05 272.62C200.912 272.62 201.61 271.921 201.61 271.06C201.61 270.198 200.912 269.5 200.05 269.5C199.188 269.5 198.49 270.198 198.49 271.06C198.49 271.921 199.188 272.62 200.05 272.62Z" fill="#434391"/>
                    <path d="M205.25 275.67C206.139 275.67 206.86 274.949 206.86 274.06C206.86 273.171 206.139 272.45 205.25 272.45C204.361 272.45 203.64 273.171 203.64 274.06C203.64 274.949 204.361 275.67 205.25 275.67Z" fill="#434391"/>
                    <path d="M210.45 278.72C211.367 278.72 212.11 277.976 212.11 277.06C212.11 276.143 211.367 275.4 210.45 275.4C209.533 275.4 208.79 276.143 208.79 277.06C208.79 277.976 209.533 278.72 210.45 278.72Z" fill="#434391"/>
                    <path d="M215.64 281.77C216.584 281.77 217.35 281.004 217.35 280.06C217.35 279.115 216.584 278.35 215.64 278.35C214.696 278.35 213.93 279.115 213.93 280.06C213.93 281.004 214.696 281.77 215.64 281.77Z" fill="#434391"/>
                    <path d="M220.84 284.82C221.812 284.82 222.6 284.032 222.6 283.06C222.6 282.088 221.812 281.3 220.84 281.3C219.868 281.3 219.08 282.088 219.08 283.06C219.08 284.032 219.868 284.82 220.84 284.82Z" fill="#434391"/>
                    <path d="M226.04 287.87C227.04 287.87 227.85 287.059 227.85 286.06C227.85 285.06 227.04 284.25 226.04 284.25C225.04 284.25 224.23 285.06 224.23 286.06C224.23 287.059 225.04 287.87 226.04 287.87Z" fill="#434391"/>
                    <path d="M231.23 290.5C232.025 290.5 232.67 289.855 232.67 289.06C232.67 288.264 232.025 287.62 231.23 287.62C230.435 287.62 229.79 288.264 229.79 289.06C229.79 289.855 230.435 290.5 231.23 290.5Z" fill="#434391"/>
                    <path d="M82.74 210.59C82.9278 210.59 83.08 210.437 83.08 210.25C83.08 210.062 82.9278 209.91 82.74 209.91C82.5522 209.91 82.4 210.062 82.4 210.25C82.4 210.437 82.5522 210.59 82.74 210.59Z" fill="#434391"/>
                    <path d="M87.94 213.9C88.299 213.9 88.59 213.609 88.59 213.25C88.59 212.891 88.299 212.6 87.94 212.6C87.581 212.6 87.29 212.891 87.29 213.25C87.29 213.609 87.581 213.9 87.94 213.9Z" fill="#434391"/>
                    <path d="M93.13 216.94C93.5111 216.94 93.82 216.631 93.82 216.25C93.82 215.869 93.5111 215.56 93.13 215.56C92.7489 215.56 92.44 215.869 92.44 216.25C92.44 216.631 92.7489 216.94 93.13 216.94Z" fill="#434391"/>
                    <path d="M98.33 219.99C98.7387 219.99 99.07 219.658 99.07 219.25C99.07 218.841 98.7387 218.51 98.33 218.51C97.9213 218.51 97.59 218.841 97.59 219.25C97.59 219.658 97.9213 219.99 98.33 219.99Z" fill="#434391"/>
                    <path d="M103.52 223.04C103.956 223.04 104.31 222.686 104.31 222.25C104.31 221.813 103.956 221.46 103.52 221.46C103.084 221.46 102.73 221.813 102.73 222.25C102.73 222.686 103.084 223.04 103.52 223.04Z" fill="#434391"/>
                    <path d="M108.72 226.09C109.184 226.09 109.56 225.714 109.56 225.25C109.56 224.786 109.184 224.41 108.72 224.41C108.256 224.41 107.88 224.786 107.88 225.25C107.88 225.714 108.256 226.09 108.72 226.09Z" fill="#434391"/>
                    <path d="M113.92 229.13C114.406 229.13 114.8 228.736 114.8 228.25C114.8 227.764 114.406 227.37 113.92 227.37C113.434 227.37 113.04 227.764 113.04 228.25C113.04 228.736 113.434 229.13 113.92 229.13Z" fill="#434391"/>
                    <path d="M119.11 232.18C119.624 232.18 120.04 231.763 120.04 231.25C120.04 230.736 119.624 230.32 119.11 230.32C118.596 230.32 118.18 230.736 118.18 231.25C118.18 231.763 118.596 232.18 119.11 232.18Z" fill="#434391"/>
                    <path d="M124.31 235.23C124.851 235.23 125.29 234.791 125.29 234.25C125.29 233.709 124.851 233.27 124.31 233.27C123.769 233.27 123.33 233.709 123.33 234.25C123.33 234.791 123.769 235.23 124.31 235.23Z" fill="#434391"/>
                    <path d="M129.5 238.28C130.069 238.28 130.53 237.819 130.53 237.25C130.53 236.681 130.069 236.22 129.5 236.22C128.931 236.22 128.47 236.681 128.47 237.25C128.47 237.819 128.931 238.28 129.5 238.28Z" fill="#434391"/>
                    <path d="M134.7 241.32C135.291 241.32 135.77 240.841 135.77 240.25C135.77 239.659 135.291 239.18 134.7 239.18C134.109 239.18 133.63 239.659 133.63 240.25C133.63 240.841 134.109 241.32 134.7 241.32Z" fill="#434391"/>
                    <path d="M139.9 244.37C140.519 244.37 141.02 243.868 141.02 243.25C141.02 242.631 140.519 242.13 139.9 242.13C139.281 242.13 138.78 242.631 138.78 243.25C138.78 243.868 139.281 244.37 139.9 244.37Z" fill="#434391"/>
                    <path d="M145.09 247.42C145.736 247.42 146.26 246.896 146.26 246.25C146.26 245.604 145.736 245.08 145.09 245.08C144.444 245.08 143.92 245.604 143.92 246.25C143.92 246.896 144.444 247.42 145.09 247.42Z" fill="#434391"/>
                    <path d="M150.29 250.47C150.964 250.47 151.51 249.924 151.51 249.25C151.51 248.576 150.964 248.03 150.29 248.03C149.616 248.03 149.07 248.576 149.07 249.25C149.07 249.924 149.616 250.47 150.29 250.47Z" fill="#434391"/>
                    <path d="M155.49 253.51C156.186 253.51 156.75 252.946 156.75 252.25C156.75 251.554 156.186 250.99 155.49 250.99C154.794 250.99 154.23 251.554 154.23 252.25C154.23 252.946 154.794 253.51 155.49 253.51Z" fill="#434391"/>
                    <path d="M160.68 256.56C161.403 256.56 161.99 255.973 161.99 255.25C161.99 254.526 161.403 253.94 160.68 253.94C159.956 253.94 159.37 254.526 159.37 255.25C159.37 255.973 159.956 256.56 160.68 256.56Z" fill="#434391"/>
                    <path d="M165.88 259.61C166.631 259.61 167.24 259.001 167.24 258.25C167.24 257.499 166.631 256.89 165.88 256.89C165.129 256.89 164.52 257.499 164.52 258.25C164.52 259.001 165.129 259.61 165.88 259.61Z" fill="#434391"/>
                    <path d="M171.07 262.66C171.849 262.66 172.48 262.029 172.48 261.25C172.48 260.471 171.849 259.84 171.07 259.84C170.291 259.84 169.66 260.471 169.66 261.25C169.66 262.029 170.291 262.66 171.07 262.66Z" fill="#434391"/>
                    <path d="M176.27 265.71C177.076 265.71 177.73 265.056 177.73 264.25C177.73 263.443 177.076 262.79 176.27 262.79C175.464 262.79 174.81 263.443 174.81 264.25C174.81 265.056 175.464 265.71 176.27 265.71Z" fill="#434391"/>
                    <path d="M181.47 268.76C182.304 268.76 182.98 268.084 182.98 267.25C182.98 266.416 182.304 265.74 181.47 265.74C180.636 265.74 179.96 266.416 179.96 267.25C179.96 268.084 180.636 268.76 181.47 268.76Z" fill="#434391"/>
                    <path d="M186.66 271.81C187.522 271.81 188.22 271.111 188.22 270.25C188.22 269.388 187.522 268.69 186.66 268.69C185.798 268.69 185.1 269.388 185.1 270.25C185.1 271.111 185.798 271.81 186.66 271.81Z" fill="#434391"/>
                    <path d="M191.86 274.86C192.749 274.86 193.47 274.139 193.47 273.25C193.47 272.36 192.749 271.64 191.86 271.64C190.971 271.64 190.25 272.36 190.25 273.25C190.25 274.139 190.971 274.86 191.86 274.86Z" fill="#434391"/>
                    <path d="M197.05 277.9C197.961 277.9 198.7 277.161 198.7 276.25C198.7 275.339 197.961 274.6 197.05 274.6C196.139 274.6 195.4 275.339 195.4 276.25C195.4 277.161 196.139 277.9 197.05 277.9Z" fill="#434391"/>
                    <path d="M202.25 280.95C203.189 280.95 203.95 280.189 203.95 279.25C203.95 278.311 203.189 277.55 202.25 277.55C201.311 277.55 200.55 278.311 200.55 279.25C200.55 280.189 201.311 280.95 202.25 280.95Z" fill="#434391"/>
                    <path d="M207.45 284C208.416 284 209.2 283.216 209.2 282.25C209.2 281.283 208.416 280.5 207.45 280.5C206.483 280.5 205.7 281.283 205.7 282.25C205.7 283.216 206.483 284 207.45 284Z" fill="#434391"/>
                    <path d="M212.64 287.05C213.634 287.05 214.44 286.244 214.44 285.25C214.44 284.256 213.634 283.45 212.64 283.45C211.646 283.45 210.84 284.256 210.84 285.25C210.84 286.244 211.646 287.05 212.64 287.05Z" fill="#434391"/>
                    <path d="M217.84 289.88C218.74 289.88 219.47 289.15 219.47 288.25C219.47 287.349 218.74 286.62 217.84 286.62C216.94 286.62 216.21 287.349 216.21 288.25C216.21 289.15 216.94 289.88 217.84 289.88Z" fill="#434391"/>
                    <path d="M79.74 215.81C79.9388 215.81 80.1 215.649 80.1 215.45C80.1 215.251 79.9388 215.09 79.74 215.09C79.5412 215.09 79.38 215.251 79.38 215.45C79.38 215.649 79.5412 215.81 79.74 215.81Z" fill="#434391"/>
                    <path d="M84.94 219.18C85.3432 219.18 85.67 218.853 85.67 218.45C85.67 218.047 85.3432 217.72 84.94 217.72C84.5368 217.72 84.21 218.047 84.21 218.45C84.21 218.853 84.5368 219.18 84.94 219.18Z" fill="#434391"/>
                    <path d="M90.13 222.22C90.5553 222.22 90.9 221.875 90.9 221.45C90.9 221.024 90.5553 220.68 90.13 220.68C89.7047 220.68 89.36 221.024 89.36 221.45C89.36 221.875 89.7047 222.22 90.13 222.22Z" fill="#434391"/>
                    <path d="M95.33 225.27C95.7829 225.27 96.15 224.903 96.15 224.45C96.15 223.997 95.7829 223.63 95.33 223.63C94.8771 223.63 94.51 223.997 94.51 224.45C94.51 224.903 94.8771 225.27 95.33 225.27Z" fill="#434391"/>
                    <path d="M100.52 228.32C101 228.32 101.39 227.93 101.39 227.45C101.39 226.969 101 226.58 100.52 226.58C100.04 226.58 99.65 226.969 99.65 227.45C99.65 227.93 100.04 228.32 100.52 228.32Z" fill="#434391"/>
                    <path d="M105.72 231.37C106.228 231.37 106.64 230.958 106.64 230.45C106.64 229.942 106.228 229.53 105.72 229.53C105.212 229.53 104.8 229.942 104.8 230.45C104.8 230.958 105.212 231.37 105.72 231.37Z" fill="#434391"/>
                    <path d="M110.92 234.41C111.45 234.41 111.88 233.98 111.88 233.45C111.88 232.92 111.45 232.49 110.92 232.49C110.39 232.49 109.96 232.92 109.96 233.45C109.96 233.98 110.39 234.41 110.92 234.41Z" fill="#434391"/>
                    <path d="M116.11 237.46C116.668 237.46 117.12 237.007 117.12 236.45C117.12 235.892 116.668 235.44 116.11 235.44C115.552 235.44 115.1 235.892 115.1 236.45C115.1 237.007 115.552 237.46 116.11 237.46Z" fill="#434391"/>
                    <path d="M121.31 240.51C121.895 240.51 122.37 240.035 122.37 239.45C122.37 238.864 121.895 238.39 121.31 238.39C120.725 238.39 120.25 238.864 120.25 239.45C120.25 240.035 120.725 240.51 121.31 240.51Z" fill="#434391"/>
                    <path d="M126.5 243.56C127.113 243.56 127.61 243.063 127.61 242.45C127.61 241.837 127.113 241.34 126.5 241.34C125.887 241.34 125.39 241.837 125.39 242.45C125.39 243.063 125.887 243.56 126.5 243.56Z" fill="#434391"/>
                    <path d="M131.7 246.61C132.341 246.61 132.86 246.09 132.86 245.45C132.86 244.809 132.341 244.29 131.7 244.29C131.059 244.29 130.54 244.809 130.54 245.45C130.54 246.09 131.059 246.61 131.7 246.61Z" fill="#434391"/>
                    <path d="M136.9 249.66C137.568 249.66 138.11 249.118 138.11 248.45C138.11 247.781 137.568 247.24 136.9 247.24C136.232 247.24 135.69 247.781 135.69 248.45C135.69 249.118 136.232 249.66 136.9 249.66Z" fill="#434391"/>
                    <path d="M142.09 252.71C142.786 252.71 143.35 252.146 143.35 251.45C143.35 250.754 142.786 250.19 142.09 250.19C141.394 250.19 140.83 250.754 140.83 251.45C140.83 252.146 141.394 252.71 142.09 252.71Z" fill="#434391"/>
                    <path d="M147.29 255.76C148.013 255.76 148.6 255.173 148.6 254.45C148.6 253.726 148.013 253.14 147.29 253.14C146.567 253.14 145.98 253.726 145.98 254.45C145.98 255.173 146.567 255.76 147.29 255.76Z" fill="#434391"/>
                    <path d="M152.49 258.81C153.241 258.81 153.85 258.201 153.85 257.45C153.85 256.699 153.241 256.09 152.49 256.09C151.739 256.09 151.13 256.699 151.13 257.45C151.13 258.201 151.739 258.81 152.49 258.81Z" fill="#434391"/>
                    <path d="M157.68 261.85C158.453 261.85 159.08 261.223 159.08 260.45C159.08 259.677 158.453 259.05 157.68 259.05C156.907 259.05 156.28 259.677 156.28 260.45C156.28 261.223 156.907 261.85 157.68 261.85Z" fill="#434391"/>
                    <path d="M162.88 264.9C163.681 264.9 164.33 264.251 164.33 263.45C164.33 262.649 163.681 262 162.88 262C162.079 262 161.43 262.649 161.43 263.45C161.43 264.251 162.079 264.9 162.88 264.9Z" fill="#434391"/>
                    <path d="M168.07 267.95C168.898 267.95 169.57 267.278 169.57 266.45C169.57 265.621 168.898 264.95 168.07 264.95C167.242 264.95 166.57 265.621 166.57 266.45C166.57 267.278 167.242 267.95 168.07 267.95Z" fill="#434391"/>
                    <path d="M173.27 270.99C174.121 270.99 174.81 270.3 174.81 269.45C174.81 268.599 174.121 267.91 173.27 267.91C172.419 267.91 171.73 268.599 171.73 269.45C171.73 270.3 172.419 270.99 173.27 270.99Z" fill="#434391"/>
                    <path d="M178.47 274.04C179.348 274.04 180.06 273.328 180.06 272.45C180.06 271.572 179.348 270.86 178.47 270.86C177.592 270.86 176.88 271.572 176.88 272.45C176.88 273.328 177.592 274.04 178.47 274.04Z" fill="#434391"/>
                    <path d="M183.66 277.09C184.566 277.09 185.3 276.356 185.3 275.45C185.3 274.544 184.566 273.81 183.66 273.81C182.754 273.81 182.02 274.544 182.02 275.45C182.02 276.356 182.754 277.09 183.66 277.09Z" fill="#434391"/>
                    <path d="M188.86 280.14C189.793 280.14 190.55 279.383 190.55 278.45C190.55 277.516 189.793 276.76 188.86 276.76C187.927 276.76 187.17 277.516 187.17 278.45C187.17 279.383 187.927 280.14 188.86 280.14Z" fill="#434391"/>
                    <path d="M194.05 283.18C195.005 283.18 195.78 282.405 195.78 281.45C195.78 280.494 195.005 279.72 194.05 279.72C193.095 279.72 192.32 280.494 192.32 281.45C192.32 282.405 193.095 283.18 194.05 283.18Z" fill="#434391"/>
                    <path d="M199.25 286.23C200.233 286.23 201.03 285.433 201.03 284.45C201.03 283.467 200.233 282.67 199.25 282.67C198.267 282.67 197.47 283.467 197.47 284.45C197.47 285.433 198.267 286.23 199.25 286.23Z" fill="#434391"/>
                    <path d="M204.45 289.21C205.422 289.21 206.21 288.422 206.21 287.45C206.21 286.478 205.422 285.69 204.45 285.69C203.478 285.69 202.69 286.478 202.69 287.45C202.69 288.422 203.478 289.21 204.45 289.21Z" fill="#434391"/>
                    <path d="M209.64 291.43C210.181 291.43 210.62 290.991 210.62 290.45C210.62 289.908 210.181 289.47 209.64 289.47C209.099 289.47 208.66 289.908 208.66 290.45C208.66 290.991 209.099 291.43 209.64 291.43Z" fill="#434391"/>
                    <path d="M76.74 220.91C76.8836 220.91 77 220.793 77 220.65C77 220.506 76.8836 220.39 76.74 220.39C76.5964 220.39 76.48 220.506 76.48 220.65C76.48 220.793 76.5964 220.91 76.74 220.91Z" fill="#434391"/>
                    <path d="M81.94 224.47C82.3929 224.47 82.76 224.103 82.76 223.65C82.76 223.197 82.3929 222.83 81.94 222.83C81.4871 222.83 81.12 223.197 81.12 223.65C81.12 224.103 81.4871 224.47 81.94 224.47Z" fill="#434391"/>
                    <path d="M87.13 227.51C87.605 227.51 87.99 227.125 87.99 226.65C87.99 226.175 87.605 225.79 87.13 225.79C86.655 225.79 86.27 226.175 86.27 226.65C86.27 227.125 86.655 227.51 87.13 227.51Z" fill="#434391"/>
                    <path d="M92.33 230.56C92.8326 230.56 93.24 230.152 93.24 229.65C93.24 229.147 92.8326 228.74 92.33 228.74C91.8274 228.74 91.42 229.147 91.42 229.65C91.42 230.152 91.8274 230.56 92.33 230.56Z" fill="#434391"/>
                    <path d="M97.52 233.61C98.0502 233.61 98.48 233.18 98.48 232.65C98.48 232.119 98.0502 231.69 97.52 231.69C96.9898 231.69 96.56 232.119 96.56 232.65C96.56 233.18 96.9898 233.61 97.52 233.61Z" fill="#434391"/>
                    <path d="M102.72 236.66C103.278 236.66 103.73 236.207 103.73 235.65C103.73 235.092 103.278 234.64 102.72 234.64C102.162 234.64 101.71 235.092 101.71 235.65C101.71 236.207 102.162 236.66 102.72 236.66Z" fill="#434391"/>
                    <path d="M107.92 239.7C108.5 239.7 108.97 239.23 108.97 238.65C108.97 238.07 108.5 237.6 107.92 237.6C107.34 237.6 106.87 238.07 106.87 238.65C106.87 239.23 107.34 239.7 107.92 239.7Z" fill="#434391"/>
                    <path d="M113.11 242.75C113.718 242.75 114.21 242.257 114.21 241.65C114.21 241.042 113.718 240.55 113.11 240.55C112.502 240.55 112.01 241.042 112.01 241.65C112.01 242.257 112.502 242.75 113.11 242.75Z" fill="#434391"/>
                    <path d="M118.31 245.8C118.945 245.8 119.46 245.285 119.46 244.65C119.46 244.015 118.945 243.5 118.31 243.5C117.675 243.5 117.16 244.015 117.16 244.65C117.16 245.285 117.675 245.8 118.31 245.8Z" fill="#434391"/>
                    <path d="M123.5 248.85C124.163 248.85 124.7 248.312 124.7 247.65C124.7 246.987 124.163 246.45 123.5 246.45C122.837 246.45 122.3 246.987 122.3 247.65C122.3 248.312 122.837 248.85 123.5 248.85Z" fill="#434391"/>
                    <path d="M128.7 251.89C129.385 251.89 129.94 251.334 129.94 250.65C129.94 249.965 129.385 249.41 128.7 249.41C128.015 249.41 127.46 249.965 127.46 250.65C127.46 251.334 128.015 251.89 128.7 251.89Z" fill="#434391"/>
                    <path d="M133.9 254.94C134.612 254.94 135.19 254.362 135.19 253.65C135.19 252.937 134.612 252.36 133.9 252.36C133.188 252.36 132.61 252.937 132.61 253.65C132.61 254.362 133.188 254.94 133.9 254.94Z" fill="#434391"/>
                    <path d="M139.09 257.99C139.83 257.99 140.43 257.39 140.43 256.65C140.43 255.91 139.83 255.31 139.09 255.31C138.35 255.31 137.75 255.91 137.75 256.65C137.75 257.39 138.35 257.99 139.09 257.99Z" fill="#434391"/>
                    <path d="M144.29 261.04C145.058 261.04 145.68 260.417 145.68 259.65C145.68 258.882 145.058 258.26 144.29 258.26C143.522 258.26 142.9 258.882 142.9 259.65C142.9 260.417 143.522 261.04 144.29 261.04Z" fill="#434391"/>
                    <path d="M149.49 264.08C150.28 264.08 150.92 263.439 150.92 262.65C150.92 261.86 150.28 261.22 149.49 261.22C148.7 261.22 148.06 261.86 148.06 262.65C148.06 263.439 148.7 264.08 149.49 264.08Z" fill="#434391"/>
                    <path d="M154.68 267.13C155.497 267.13 156.16 266.467 156.16 265.65C156.16 264.832 155.497 264.17 154.68 264.17C153.863 264.17 153.2 264.832 153.2 265.65C153.2 266.467 153.863 267.13 154.68 267.13Z" fill="#434391"/>
                    <path d="M159.88 270.18C160.725 270.18 161.41 269.495 161.41 268.65C161.41 267.805 160.725 267.12 159.88 267.12C159.035 267.12 158.35 267.805 158.35 268.65C158.35 269.495 159.035 270.18 159.88 270.18Z" fill="#434391"/>
                    <path d="M165.07 273.23C165.943 273.23 166.65 272.522 166.65 271.65C166.65 270.777 165.943 270.07 165.07 270.07C164.197 270.07 163.49 270.777 163.49 271.65C163.49 272.522 164.197 273.23 165.07 273.23Z" fill="#434391"/>
                    <path d="M170.27 276.27C171.165 276.27 171.89 275.544 171.89 274.65C171.89 273.755 171.165 273.03 170.27 273.03C169.375 273.03 168.65 273.755 168.65 274.65C168.65 275.544 169.375 276.27 170.27 276.27Z" fill="#434391"/>
                    <path d="M175.47 279.32C176.392 279.32 177.14 278.572 177.14 277.65C177.14 276.727 176.392 275.98 175.47 275.98C174.548 275.98 173.8 276.727 173.8 277.65C173.8 278.572 174.548 279.32 175.47 279.32Z" fill="#434391"/>
                    <path d="M180.66 282.37C181.61 282.37 182.38 281.6 182.38 280.65C182.38 279.7 181.61 278.93 180.66 278.93C179.71 278.93 178.94 279.7 178.94 280.65C178.94 281.6 179.71 282.37 180.66 282.37Z" fill="#434391"/>
                    <path d="M185.86 285.42C186.838 285.42 187.63 284.627 187.63 283.65C187.63 282.672 186.838 281.88 185.86 281.88C184.882 281.88 184.09 282.672 184.09 283.65C184.09 284.627 184.882 285.42 185.86 285.42Z" fill="#434391"/>
                    <path d="M191.05 288.44C192.039 288.44 192.84 287.638 192.84 286.65C192.84 285.661 192.039 284.86 191.05 284.86C190.061 284.86 189.26 285.661 189.26 286.65C189.26 287.638 190.061 288.44 191.05 288.44Z" fill="#434391"/>
                    <path d="M196.25 290.91C196.946 290.91 197.51 290.346 197.51 289.65C197.51 288.954 196.946 288.39 196.25 288.39C195.554 288.39 194.99 288.954 194.99 289.65C194.99 290.346 195.554 290.91 196.25 290.91Z" fill="#434391"/>
                    <path d="M73.74 225.91C73.7787 225.91 73.81 225.878 73.81 225.84C73.81 225.801 73.7787 225.77 73.74 225.77C73.7013 225.77 73.67 225.801 73.67 225.84C73.67 225.878 73.7013 225.91 73.74 225.91Z" fill="#434391"/>
                    <path d="M78.94 229.69C79.4094 229.69 79.79 229.309 79.79 228.84C79.79 228.37 79.4094 227.99 78.94 227.99C78.4706 227.99 78.09 228.37 78.09 228.84C78.09 229.309 78.4706 229.69 78.94 229.69Z" fill="#434391"/>
                    <path d="M84.13 232.78C84.6491 232.78 85.07 232.359 85.07 231.84C85.07 231.321 84.6491 230.9 84.13 230.9C83.6108 230.9 83.19 231.321 83.19 231.84C83.19 232.359 83.6108 232.78 84.13 232.78Z" fill="#434391"/>
                    <path d="M89.33 235.83C89.8768 235.83 90.32 235.387 90.32 234.84C90.32 234.293 89.8768 233.85 89.33 233.85C88.7832 233.85 88.34 234.293 88.34 234.84C88.34 235.387 88.7832 235.83 89.33 235.83Z" fill="#434391"/>
                    <path d="M94.52 238.88C95.0944 238.88 95.56 238.414 95.56 237.84C95.56 237.265 95.0944 236.8 94.52 236.8C93.9456 236.8 93.48 237.265 93.48 237.84C93.48 238.414 93.9456 238.88 94.52 238.88Z" fill="#434391"/>
                    <path d="M99.72 241.93C100.322 241.93 100.81 241.442 100.81 240.84C100.81 240.238 100.322 239.75 99.72 239.75C99.118 239.75 98.63 240.238 98.63 240.84C98.63 241.442 99.118 241.93 99.72 241.93Z" fill="#434391"/>
                    <path d="M104.92 244.97C105.544 244.97 106.05 244.464 106.05 243.84C106.05 243.216 105.544 242.71 104.92 242.71C104.296 242.71 103.79 243.216 103.79 243.84C103.79 244.464 104.296 244.97 104.92 244.97Z" fill="#434391"/>
                    <path d="M110.11 248.02C110.762 248.02 111.29 247.491 111.29 246.84C111.29 246.188 110.762 245.66 110.11 245.66C109.458 245.66 108.93 246.188 108.93 246.84C108.93 247.491 109.458 248.02 110.11 248.02Z" fill="#434391"/>
                    <path d="M115.31 251.07C115.989 251.07 116.54 250.519 116.54 249.84C116.54 249.161 115.989 248.61 115.31 248.61C114.631 248.61 114.08 249.161 114.08 249.84C114.08 250.519 114.631 251.07 115.31 251.07Z" fill="#434391"/>
                    <path d="M120.5 254.12C121.207 254.12 121.78 253.547 121.78 252.84C121.78 252.133 121.207 251.56 120.5 251.56C119.793 251.56 119.22 252.133 119.22 252.84C119.22 253.547 119.793 254.12 120.5 254.12Z" fill="#434391"/>
                    <path d="M125.7 257.17C126.435 257.17 127.03 256.574 127.03 255.84C127.03 255.105 126.435 254.51 125.7 254.51C124.965 254.51 124.37 255.105 124.37 255.84C124.37 256.574 124.965 257.17 125.7 257.17Z" fill="#434391"/>
                    <path d="M130.9 260.21C131.657 260.21 132.27 259.596 132.27 258.84C132.27 258.083 131.657 257.47 130.9 257.47C130.143 257.47 129.53 258.083 129.53 258.84C129.53 259.596 130.143 260.21 130.9 260.21Z" fill="#434391"/>
                    <path d="M136.09 263.26C136.874 263.26 137.51 262.624 137.51 261.84C137.51 261.055 136.874 260.42 136.09 260.42C135.306 260.42 134.67 261.055 134.67 261.84C134.67 262.624 135.306 263.26 136.09 263.26Z" fill="#434391"/>
                    <path d="M141.29 266.31C142.102 266.31 142.76 265.651 142.76 264.84C142.76 264.028 142.102 263.37 141.29 263.37C140.478 263.37 139.82 264.028 139.82 264.84C139.82 265.651 140.478 266.31 141.29 266.31Z" fill="#434391"/>
                    <path d="M146.49 269.35C147.324 269.35 148 268.674 148 267.84C148 267.006 147.324 266.33 146.49 266.33C145.656 266.33 144.98 267.006 144.98 267.84C144.98 268.674 145.656 269.35 146.49 269.35Z" fill="#434391"/>
                    <path d="M151.68 272.4C152.542 272.4 153.24 271.701 153.24 270.84C153.24 269.978 152.542 269.28 151.68 269.28C150.818 269.28 150.12 269.978 150.12 270.84C150.12 271.701 150.818 272.4 151.68 272.4Z" fill="#434391"/>
                    <path d="M156.88 275.45C157.769 275.45 158.49 274.729 158.49 273.84C158.49 272.951 157.769 272.23 156.88 272.23C155.991 272.23 155.27 272.951 155.27 273.84C155.27 274.729 155.991 275.45 156.88 275.45Z" fill="#434391"/>
                    <path d="M162.07 278.5C162.987 278.5 163.73 277.756 163.73 276.84C163.73 275.923 162.987 275.18 162.07 275.18C161.153 275.18 160.41 275.923 160.41 276.84C160.41 277.756 161.153 278.5 162.07 278.5Z" fill="#434391"/>
                    <path d="M167.27 281.55C168.214 281.55 168.98 280.784 168.98 279.84C168.98 278.895 168.214 278.13 167.27 278.13C166.326 278.13 165.56 278.895 165.56 279.84C165.56 280.784 166.326 281.55 167.27 281.55Z" fill="#434391"/>
                    <path d="M172.47 284.6C173.442 284.6 174.23 283.812 174.23 282.84C174.23 281.868 173.442 281.08 172.47 281.08C171.498 281.08 170.71 281.868 170.71 282.84C170.71 283.812 171.498 284.6 172.47 284.6Z" fill="#434391"/>
                    <path d="M177.66 287.65C178.66 287.65 179.47 286.839 179.47 285.84C179.47 284.84 178.66 284.03 177.66 284.03C176.66 284.03 175.85 284.84 175.85 285.84C175.85 286.839 176.66 287.65 177.66 287.65Z" fill="#434391"/>
                    <path d="M182.86 290.34C183.688 290.34 184.36 289.668 184.36 288.84C184.36 288.011 183.688 287.34 182.86 287.34C182.032 287.34 181.36 288.011 181.36 288.84C181.36 289.668 182.032 290.34 182.86 290.34Z" fill="#434391"/>
                    <path d="M75.94 234.64C76.2714 234.64 76.54 234.371 76.54 234.04C76.54 233.708 76.2714 233.44 75.94 233.44C75.6086 233.44 75.34 233.708 75.34 234.04C75.34 234.371 75.6086 234.64 75.94 234.64Z" fill="#434391"/>
                    <path d="M81.13 238.06C81.6933 238.06 82.15 237.603 82.15 237.04C82.15 236.476 81.6933 236.02 81.13 236.02C80.5667 236.02 80.11 236.476 80.11 237.04C80.11 237.603 80.5667 238.06 81.13 238.06Z" fill="#434391"/>
                    <path d="M86.33 241.11C86.9209 241.11 87.4 240.631 87.4 240.04C87.4 239.449 86.9209 238.97 86.33 238.97C85.739 238.97 85.26 239.449 85.26 240.04C85.26 240.631 85.739 241.11 86.33 241.11Z" fill="#434391"/>
                    <path d="M91.52 244.16C92.1386 244.16 92.64 243.658 92.64 243.04C92.64 242.421 92.1386 241.92 91.52 241.92C90.9014 241.92 90.4 242.421 90.4 243.04C90.4 243.658 90.9014 244.16 91.52 244.16Z" fill="#434391"/>
                    <path d="M96.72 247.2C97.3606 247.2 97.88 246.68 97.88 246.04C97.88 245.399 97.3606 244.88 96.72 244.88C96.0793 244.88 95.56 245.399 95.56 246.04C95.56 246.68 96.0793 247.2 96.72 247.2Z" fill="#434391"/>
                    <path d="M101.92 250.25C102.588 250.25 103.13 249.708 103.13 249.04C103.13 248.372 102.588 247.83 101.92 247.83C101.252 247.83 100.71 248.372 100.71 249.04C100.71 249.708 101.252 250.25 101.92 250.25Z" fill="#434391"/>
                    <path d="M107.11 253.3C107.806 253.3 108.37 252.736 108.37 252.04C108.37 251.344 107.806 250.78 107.11 250.78C106.414 250.78 105.85 251.344 105.85 252.04C105.85 252.736 106.414 253.3 107.11 253.3Z" fill="#434391"/>
                    <path d="M112.31 256.35C113.033 256.35 113.62 255.763 113.62 255.04C113.62 254.316 113.033 253.73 112.31 253.73C111.587 253.73 111 254.316 111 255.04C111 255.763 111.587 256.35 112.31 256.35Z" fill="#434391"/>
                    <path d="M117.5 259.4C118.251 259.4 118.86 258.791 118.86 258.04C118.86 257.289 118.251 256.68 117.5 256.68C116.749 256.68 116.14 257.289 116.14 258.04C116.14 258.791 116.749 259.4 117.5 259.4Z" fill="#434391"/>
                    <path d="M122.7 262.45C123.479 262.45 124.11 261.818 124.11 261.04C124.11 260.261 123.479 259.63 122.7 259.63C121.921 259.63 121.29 260.261 121.29 261.04C121.29 261.818 121.921 262.45 122.7 262.45Z" fill="#434391"/>
                    <path d="M127.9 265.5C128.706 265.5 129.36 264.846 129.36 264.04C129.36 263.233 128.706 262.58 127.9 262.58C127.094 262.58 126.44 263.233 126.44 264.04C126.44 264.846 127.094 265.5 127.9 265.5Z" fill="#434391"/>
                    <path d="M133.09 268.55C133.924 268.55 134.6 267.874 134.6 267.04C134.6 266.206 133.924 265.53 133.09 265.53C132.256 265.53 131.58 266.206 131.58 267.04C131.58 267.874 132.256 268.55 133.09 268.55Z" fill="#434391"/>
                    <path d="M138.29 271.6C139.152 271.6 139.85 270.901 139.85 270.04C139.85 269.178 139.152 268.48 138.29 268.48C137.428 268.48 136.73 269.178 136.73 270.04C136.73 270.901 137.428 271.6 138.29 271.6Z" fill="#434391"/>
                    <path d="M143.49 274.64C144.374 274.64 145.09 273.923 145.09 273.04C145.09 272.156 144.374 271.44 143.49 271.44C142.606 271.44 141.89 272.156 141.89 273.04C141.89 273.923 142.606 274.64 143.49 274.64Z" fill="#434391"/>
                    <path d="M148.68 277.69C149.591 277.69 150.33 276.951 150.33 276.04C150.33 275.128 149.591 274.39 148.68 274.39C147.769 274.39 147.03 275.128 147.03 276.04C147.03 276.951 147.769 277.69 148.68 277.69Z" fill="#434391"/>
                    <path d="M153.88 280.74C154.819 280.74 155.58 279.979 155.58 279.04C155.58 278.101 154.819 277.34 153.88 277.34C152.941 277.34 152.18 278.101 152.18 279.04C152.18 279.979 152.941 280.74 153.88 280.74Z" fill="#434391"/>
                    <path d="M159.07 283.79C160.036 283.79 160.82 283.006 160.82 282.04C160.82 281.073 160.036 280.29 159.07 280.29C158.103 280.29 157.32 281.073 157.32 282.04C157.32 283.006 158.103 283.79 159.07 283.79Z" fill="#434391"/>
                    <path d="M164.27 286.83C165.259 286.83 166.06 286.028 166.06 285.04C166.06 284.051 165.259 283.25 164.27 283.25C163.281 283.25 162.48 284.051 162.48 285.04C162.48 286.028 163.281 286.83 164.27 286.83Z" fill="#434391"/>
                    <path d="M169.47 289.7C170.387 289.7 171.13 288.956 171.13 288.04C171.13 287.123 170.387 286.38 169.47 286.38C168.553 286.38 167.81 287.123 167.81 288.04C167.81 288.956 168.553 289.7 169.47 289.7Z" fill="#434391"/>
                    <path d="M174.66 291.82C175.091 291.82 175.44 291.471 175.44 291.04C175.44 290.609 175.091 290.26 174.66 290.26C174.229 290.26 173.88 290.609 173.88 291.04C173.88 291.471 174.229 291.82 174.66 291.82Z" fill="#434391"/>
                    <path d="M72.94 239.45C73.0615 239.45 73.16 239.351 73.16 239.23C73.16 239.108 73.0615 239.01 72.94 239.01C72.8185 239.01 72.72 239.108 72.72 239.23C72.72 239.351 72.8185 239.45 72.94 239.45Z" fill="#434391"/>
                    <path d="M78.13 243.34C78.743 243.34 79.24 242.843 79.24 242.23C79.24 241.617 78.743 241.12 78.13 241.12C77.517 241.12 77.02 241.617 77.02 242.23C77.02 242.843 77.517 243.34 78.13 243.34Z" fill="#434391"/>
                    <path d="M83.33 246.39C83.9706 246.39 84.49 245.87 84.49 245.23C84.49 244.589 83.9706 244.07 83.33 244.07C82.6893 244.07 82.17 244.589 82.17 245.23C82.17 245.87 82.6893 246.39 83.33 246.39Z" fill="#434391"/>
                    <path d="M88.52 249.44C89.1883 249.44 89.73 248.898 89.73 248.23C89.73 247.562 89.1883 247.02 88.52 247.02C87.8517 247.02 87.31 247.562 87.31 248.23C87.31 248.898 87.8517 249.44 88.52 249.44Z" fill="#434391"/>
                    <path d="M93.72 252.49C94.4159 252.49 94.98 251.926 94.98 251.23C94.98 250.534 94.4159 249.97 93.72 249.97C93.0241 249.97 92.46 250.534 92.46 251.23C92.46 251.926 93.0241 252.49 93.72 252.49Z" fill="#434391"/>
                    <path d="M98.92 255.53C99.638 255.53 100.22 254.948 100.22 254.23C100.22 253.512 99.638 252.93 98.92 252.93C98.202 252.93 97.62 253.512 97.62 254.23C97.62 254.948 98.202 255.53 98.92 255.53Z" fill="#434391"/>
                    <path d="M104.11 258.58C104.856 258.58 105.46 257.975 105.46 257.23C105.46 256.484 104.856 255.88 104.11 255.88C103.364 255.88 102.76 256.484 102.76 257.23C102.76 257.975 103.364 258.58 104.11 258.58Z" fill="#434391"/>
                    <path d="M109.31 261.63C110.083 261.63 110.71 261.003 110.71 260.23C110.71 259.457 110.083 258.83 109.31 258.83C108.537 258.83 107.91 259.457 107.91 260.23C107.91 261.003 108.537 261.63 109.31 261.63Z" fill="#434391"/>
                    <path d="M114.5 264.67C115.295 264.67 115.94 264.025 115.94 263.23C115.94 262.435 115.295 261.79 114.5 261.79C113.705 261.79 113.06 262.435 113.06 263.23C113.06 264.025 113.705 264.67 114.5 264.67Z" fill="#434391"/>
                    <path d="M119.7 267.72C120.523 267.72 121.19 267.053 121.19 266.23C121.19 265.407 120.523 264.74 119.7 264.74C118.877 264.74 118.21 265.407 118.21 266.23C118.21 267.053 118.877 267.72 119.7 267.72Z" fill="#434391"/>
                    <path d="M124.9 270.77C125.751 270.77 126.44 270.08 126.44 269.23C126.44 268.379 125.751 267.69 124.9 267.69C124.049 267.69 123.36 268.379 123.36 269.23C123.36 270.08 124.049 270.77 124.9 270.77Z" fill="#434391"/>
                    <path d="M130.09 273.82C130.968 273.82 131.68 273.108 131.68 272.23C131.68 271.352 130.968 270.64 130.09 270.64C129.212 270.64 128.5 271.352 128.5 272.23C128.5 273.108 129.212 273.82 130.09 273.82Z" fill="#434391"/>
                    <path d="M135.29 276.86C136.19 276.86 136.92 276.13 136.92 275.23C136.92 274.33 136.19 273.6 135.29 273.6C134.39 273.6 133.66 274.33 133.66 275.23C133.66 276.13 134.39 276.86 135.29 276.86Z" fill="#434391"/>
                    <path d="M140.49 279.91C141.418 279.91 142.17 279.158 142.17 278.23C142.17 277.302 141.418 276.55 140.49 276.55C139.562 276.55 138.81 277.302 138.81 278.23C138.81 279.158 139.562 279.91 140.49 279.91Z" fill="#434391"/>
                    <path d="M145.68 282.96C146.635 282.96 147.41 282.185 147.41 281.23C147.41 280.274 146.635 279.5 145.68 279.5C144.725 279.5 143.95 280.274 143.95 281.23C143.95 282.185 144.725 282.96 145.68 282.96Z" fill="#434391"/>
                    <path d="M150.88 286.01C151.863 286.01 152.66 285.213 152.66 284.23C152.66 283.247 151.863 282.45 150.88 282.45C149.897 282.45 149.1 283.247 149.1 284.23C149.1 285.213 149.897 286.01 150.88 286.01Z" fill="#434391"/>
                    <path d="M156.07 288.99C157.042 288.99 157.83 288.202 157.83 287.23C157.83 286.258 157.042 285.47 156.07 285.47C155.098 285.47 154.31 286.258 154.31 287.23C154.31 288.202 155.098 288.99 156.07 288.99Z" fill="#434391"/>
                    <path d="M161.27 291.29C161.855 291.29 162.33 290.815 162.33 290.23C162.33 289.644 161.855 289.17 161.27 289.17C160.685 289.17 160.21 289.644 160.21 290.23C160.21 290.815 160.685 291.29 161.27 291.29Z" fill="#434391"/>
                    <path d="M75.13 248.35C75.6381 248.35 76.05 247.938 76.05 247.43C76.05 246.922 75.6381 246.51 75.13 246.51C74.6219 246.51 74.21 246.922 74.21 247.43C74.21 247.938 74.6219 248.35 75.13 248.35Z" fill="#434391"/>
                    <path d="M80.33 251.67C81.0148 251.67 81.57 251.115 81.57 250.43C81.57 249.745 81.0148 249.19 80.33 249.19C79.6452 249.19 79.09 249.745 79.09 250.43C79.09 251.115 79.6452 251.67 80.33 251.67Z" fill="#434391"/>
                    <path d="M85.52 254.72C86.2324 254.72 86.81 254.142 86.81 253.43C86.81 252.717 86.2324 252.14 85.52 252.14C84.8075 252.14 84.23 252.717 84.23 253.43C84.23 254.142 84.8075 254.72 85.52 254.72Z" fill="#434391"/>
                    <path d="M90.72 257.77C91.4601 257.77 92.06 257.17 92.06 256.43C92.06 255.69 91.4601 255.09 90.72 255.09C89.9799 255.09 89.38 255.69 89.38 256.43C89.38 257.17 89.9799 257.77 90.72 257.77Z" fill="#434391"/>
                    <path d="M95.92 260.81C96.6821 260.81 97.3 260.192 97.3 259.43C97.3 258.668 96.6821 258.05 95.92 258.05C95.1578 258.05 94.54 258.668 94.54 259.43C94.54 260.192 95.1578 260.81 95.92 260.81Z" fill="#434391"/>
                    <path d="M101.11 263.86C101.9 263.86 102.54 263.22 102.54 262.43C102.54 261.64 101.9 261 101.11 261C100.32 261 99.68 261.64 99.68 262.43C99.68 263.22 100.32 263.86 101.11 263.86Z" fill="#434391"/>
                    <path d="M106.31 266.91C107.127 266.91 107.79 266.247 107.79 265.43C107.79 264.612 107.127 263.95 106.31 263.95C105.493 263.95 104.83 264.612 104.83 265.43C104.83 266.247 105.493 266.91 106.31 266.91Z" fill="#434391"/>
                    <path d="M111.5 269.96C112.345 269.96 113.03 269.275 113.03 268.43C113.03 267.585 112.345 266.9 111.5 266.9C110.655 266.9 109.97 267.585 109.97 268.43C109.97 269.275 110.655 269.96 111.5 269.96Z" fill="#434391"/>
                    <path d="M116.7 273C117.567 273 118.27 272.297 118.27 271.43C118.27 270.563 117.567 269.86 116.7 269.86C115.833 269.86 115.13 270.563 115.13 271.43C115.13 272.297 115.833 273 116.7 273Z" fill="#434391"/>
                    <path d="M121.9 276.05C122.795 276.05 123.52 275.325 123.52 274.43C123.52 273.535 122.795 272.81 121.9 272.81C121.005 272.81 120.28 273.535 120.28 274.43C120.28 275.325 121.005 276.05 121.9 276.05Z" fill="#434391"/>
                    <path d="M127.09 279.1C128.012 279.1 128.76 278.352 128.76 277.43C128.76 276.507 128.012 275.76 127.09 275.76C126.168 275.76 125.42 276.507 125.42 277.43C125.42 278.352 126.168 279.1 127.09 279.1Z" fill="#434391"/>
                    <path d="M132.29 282.15C133.24 282.15 134.01 281.38 134.01 280.43C134.01 279.48 133.24 278.71 132.29 278.71C131.34 278.71 130.57 279.48 130.57 280.43C130.57 281.38 131.34 282.15 132.29 282.15Z" fill="#434391"/>
                    <path d="M137.49 285.19C138.462 285.19 139.25 284.402 139.25 283.43C139.25 282.458 138.462 281.67 137.49 281.67C136.518 281.67 135.73 282.458 135.73 283.43C135.73 284.402 136.518 285.19 137.49 285.19Z" fill="#434391"/>
                    <path d="M142.68 288.23C143.674 288.23 144.48 287.424 144.48 286.43C144.48 285.436 143.674 284.63 142.68 284.63C141.686 284.63 140.88 285.436 140.88 286.43C140.88 287.424 141.686 288.23 142.68 288.23Z" fill="#434391"/>
                    <path d="M147.88 290.76C148.615 290.76 149.21 290.164 149.21 289.43C149.21 288.695 148.615 288.1 147.88 288.1C147.145 288.1 146.55 288.695 146.55 289.43C146.55 290.164 147.145 290.76 147.88 290.76Z" fill="#434391"/>
                    <path d="M72.13 253.1C72.3896 253.1 72.6 252.889 72.6 252.63C72.6 252.37 72.3896 252.16 72.13 252.16C71.8704 252.16 71.66 252.37 71.66 252.63C71.66 252.889 71.8704 253.1 72.13 253.1Z" fill="#434391"/>
                    <path d="M77.33 256.95C78.059 256.95 78.65 256.359 78.65 255.63C78.65 254.901 78.059 254.31 77.33 254.31C76.601 254.31 76.01 254.901 76.01 255.63C76.01 256.359 76.601 256.95 77.33 256.95Z" fill="#434391"/>
                    <path d="M82.52 260C83.2766 260 83.89 259.386 83.89 258.63C83.89 257.873 83.2766 257.26 82.52 257.26C81.7634 257.26 81.15 257.873 81.15 258.63C81.15 259.386 81.7634 260 82.52 260Z" fill="#434391"/>
                    <path d="M87.72 263.05C88.5042 263.05 89.14 262.414 89.14 261.63C89.14 260.845 88.5042 260.21 87.72 260.21C86.9357 260.21 86.3 260.845 86.3 261.63C86.3 262.414 86.9357 263.05 87.72 263.05Z" fill="#434391"/>
                    <path d="M92.92 266.09C93.7263 266.09 94.38 265.436 94.38 264.63C94.38 263.823 93.7263 263.17 92.92 263.17C92.1137 263.17 91.46 263.823 91.46 264.63C91.46 265.436 92.1137 266.09 92.92 266.09Z" fill="#434391"/>
                    <path d="M98.11 269.14C98.9439 269.14 99.62 268.464 99.62 267.63C99.62 266.796 98.9439 266.12 98.11 266.12C97.276 266.12 96.6 266.796 96.6 267.63C96.6 268.464 97.276 269.14 98.11 269.14Z" fill="#434391"/>
                    <path d="M103.31 272.19C104.172 272.19 104.87 271.491 104.87 270.63C104.87 269.768 104.172 269.07 103.31 269.07C102.448 269.07 101.75 269.768 101.75 270.63C101.75 271.491 102.448 272.19 103.31 272.19Z" fill="#434391"/>
                    <path d="M108.5 275.24C109.389 275.24 110.11 274.519 110.11 273.63C110.11 272.741 109.389 272.02 108.5 272.02C107.611 272.02 106.89 272.741 106.89 273.63C106.89 274.519 107.611 275.24 108.5 275.24Z" fill="#434391"/>
                    <path d="M113.7 278.29C114.617 278.29 115.36 277.547 115.36 276.63C115.36 275.713 114.617 274.97 113.7 274.97C112.783 274.97 112.04 275.713 112.04 276.63C112.04 277.547 112.783 278.29 113.7 278.29Z" fill="#434391"/>
                    <path d="M118.9 281.34C119.844 281.34 120.61 280.574 120.61 279.63C120.61 278.685 119.844 277.92 118.9 277.92C117.956 277.92 117.19 278.685 117.19 279.63C117.19 280.574 117.956 281.34 118.9 281.34Z" fill="#434391"/>
                    <path d="M124.09 284.39C125.062 284.39 125.85 283.602 125.85 282.63C125.85 281.658 125.062 280.87 124.09 280.87C123.118 280.87 122.33 281.658 122.33 282.63C122.33 283.602 123.118 284.39 124.09 284.39Z" fill="#434391"/>
                    <path d="M129.29 287.43C130.284 287.43 131.09 286.624 131.09 285.63C131.09 284.636 130.284 283.83 129.29 283.83C128.296 283.83 127.49 284.636 127.49 285.63C127.49 286.624 128.296 287.43 129.29 287.43Z" fill="#434391"/>
                    <path d="M134.49 290.18C135.346 290.18 136.04 289.486 136.04 288.63C136.04 287.774 135.346 287.08 134.49 287.08C133.634 287.08 132.94 287.774 132.94 288.63C132.94 289.486 133.634 290.18 134.49 290.18Z" fill="#434391"/>
                    <path d="M69.13 257.89C69.1687 257.89 69.2 257.858 69.2 257.82C69.2 257.781 69.1687 257.75 69.13 257.75C69.0913 257.75 69.06 257.781 69.06 257.82C69.06 257.858 69.0913 257.89 69.13 257.89Z" fill="#434391"/>
                    <path d="M74.33 262.08C75.0259 262.08 75.59 261.516 75.59 260.82C75.59 260.124 75.0259 259.56 74.33 259.56C73.6341 259.56 73.07 260.124 73.07 260.82C73.07 261.516 73.6341 262.08 74.33 262.08Z" fill="#434391"/>
                    <path d="M79.52 265.28C80.3263 265.28 80.98 264.626 80.98 263.82C80.98 263.014 80.3263 262.36 79.52 262.36C78.7137 262.36 78.06 263.014 78.06 263.82C78.06 264.626 78.7137 265.28 79.52 265.28Z" fill="#434391"/>
                    <path d="M84.72 268.33C85.5539 268.33 86.23 267.654 86.23 266.82C86.23 265.986 85.5539 265.31 84.72 265.31C83.886 265.31 83.21 265.986 83.21 266.82C83.21 267.654 83.886 268.33 84.72 268.33Z" fill="#434391"/>
                    <path d="M89.92 271.37C90.776 271.37 91.47 270.676 91.47 269.82C91.47 268.964 90.776 268.27 89.92 268.27C89.064 268.27 88.37 268.964 88.37 269.82C88.37 270.676 89.064 271.37 89.92 271.37Z" fill="#434391"/>
                    <path d="M95.11 274.42C95.9937 274.42 96.71 273.703 96.71 272.82C96.71 271.936 95.9937 271.22 95.11 271.22C94.2263 271.22 93.51 271.936 93.51 272.82C93.51 273.703 94.2263 274.42 95.11 274.42Z" fill="#434391"/>
                    <path d="M100.31 277.47C101.221 277.47 101.96 276.731 101.96 275.82C101.96 274.908 101.221 274.17 100.31 274.17C99.3987 274.17 98.66 274.908 98.66 275.82C98.66 276.731 99.3987 277.47 100.31 277.47Z" fill="#434391"/>
                    <path d="M105.5 280.52C106.439 280.52 107.2 279.759 107.2 278.82C107.2 277.881 106.439 277.12 105.5 277.12C104.561 277.12 103.8 277.881 103.8 278.82C103.8 279.759 104.561 280.52 105.5 280.52Z" fill="#434391"/>
                    <path d="M110.7 283.56C111.661 283.56 112.44 282.781 112.44 281.82C112.44 280.859 111.661 280.08 110.7 280.08C109.739 280.08 108.96 280.859 108.96 281.82C108.96 282.781 109.739 283.56 110.7 283.56Z" fill="#434391"/>
                    <path d="M115.9 286.61C116.889 286.61 117.69 285.808 117.69 284.82C117.69 283.831 116.889 283.03 115.9 283.03C114.911 283.03 114.11 283.831 114.11 284.82C114.11 285.808 114.911 286.61 115.9 286.61Z" fill="#434391"/>
                    <path d="M121.09 289.52C122.029 289.52 122.79 288.759 122.79 287.82C122.79 286.881 122.029 286.12 121.09 286.12C120.151 286.12 119.39 286.881 119.39 287.82C119.39 288.759 120.151 289.52 121.09 289.52Z" fill="#434391"/>
                    <path d="M126.29 291.68C126.765 291.68 127.15 291.295 127.15 290.82C127.15 290.345 126.765 289.96 126.29 289.96C125.815 289.96 125.43 290.345 125.43 290.82C125.43 291.295 125.815 291.68 126.29 291.68Z" fill="#434391"/>
                    <path d="M71.33 266.81C71.7663 266.81 72.12 266.456 72.12 266.02C72.12 265.583 71.7663 265.23 71.33 265.23C70.8937 265.23 70.54 265.583 70.54 266.02C70.54 266.456 70.8937 266.81 71.33 266.81Z" fill="#434391"/>
                    <path d="M76.52 270.56C77.3705 270.56 78.06 269.87 78.06 269.02C78.06 268.169 77.3705 267.48 76.52 267.48C75.6695 267.48 74.98 268.169 74.98 269.02C74.98 269.87 75.6695 270.56 76.52 270.56Z" fill="#434391"/>
                    <path d="M81.72 273.6C82.5926 273.6 83.3 272.892 83.3 272.02C83.3 271.147 82.5926 270.44 81.72 270.44C80.8474 270.44 80.14 271.147 80.14 272.02C80.14 272.892 80.8474 273.6 81.72 273.6Z" fill="#434391"/>
                    <path d="M86.92 276.65C87.8202 276.65 88.55 275.92 88.55 275.02C88.55 274.119 87.8202 273.39 86.92 273.39C86.0198 273.39 85.29 274.119 85.29 275.02C85.29 275.92 86.0198 276.65 86.92 276.65Z" fill="#434391"/>
                    <path d="M92.11 279.7C93.0378 279.7 93.79 278.948 93.79 278.02C93.79 277.092 93.0378 276.34 92.11 276.34C91.1822 276.34 90.43 277.092 90.43 278.02C90.43 278.948 91.1822 279.7 92.11 279.7Z" fill="#434391"/>
                    <path d="M97.31 282.75C98.2654 282.75 99.04 281.975 99.04 281.02C99.04 280.064 98.2654 279.29 97.31 279.29C96.3545 279.29 95.58 280.064 95.58 281.02C95.58 281.975 96.3545 282.75 97.31 282.75Z" fill="#434391"/>
                    <path d="M102.5 285.79C103.478 285.79 104.27 284.997 104.27 284.02C104.27 283.042 103.478 282.25 102.5 282.25C101.522 282.25 100.73 283.042 100.73 284.02C100.73 284.997 101.522 285.79 102.5 285.79Z" fill="#434391"/>
                    <path d="M107.7 288.8C108.683 288.8 109.48 288.003 109.48 287.02C109.48 286.037 108.683 285.24 107.7 285.24C106.717 285.24 105.92 286.037 105.92 287.02C105.92 288.003 106.717 288.8 107.7 288.8Z" fill="#434391"/>
                    <path d="M112.9 291.16C113.53 291.16 114.04 290.649 114.04 290.02C114.04 289.39 113.53 288.88 112.9 288.88C112.27 288.88 111.76 289.39 111.76 290.02C111.76 290.649 112.27 291.16 112.9 291.16Z" fill="#434391"/>
                    <path d="M68.33 271.43C68.4515 271.43 68.55 271.331 68.55 271.21C68.55 271.088 68.4515 270.99 68.33 270.99C68.2085 270.99 68.11 271.088 68.11 271.21C68.11 271.331 68.2085 271.43 68.33 271.43Z" fill="#434391"/>
                    <path d="M73.52 275.8C74.3981 275.8 75.11 275.088 75.11 274.21C75.11 273.332 74.3981 272.62 73.52 272.62C72.6419 272.62 71.93 273.332 71.93 274.21C71.93 275.088 72.6419 275.8 73.52 275.8Z" fill="#434391"/>
                    <path d="M78.72 278.87C79.6368 278.87 80.38 278.127 80.38 277.21C80.38 276.293 79.6368 275.55 78.72 275.55C77.8032 275.55 77.06 276.293 77.06 277.21C77.06 278.127 77.8032 278.87 78.72 278.87Z" fill="#434391"/>
                    <path d="M83.92 281.92C84.8644 281.92 85.63 281.154 85.63 280.21C85.63 279.265 84.8644 278.5 83.92 278.5C82.9756 278.5 82.21 279.265 82.21 280.21C82.21 281.154 82.9756 281.92 83.92 281.92Z" fill="#434391"/>
                    <path d="M89.11 284.97C90.082 284.97 90.87 284.182 90.87 283.21C90.87 282.238 90.082 281.45 89.11 281.45C88.138 281.45 87.35 282.238 87.35 283.21C87.35 284.182 88.138 284.97 89.11 284.97Z" fill="#434391"/>
                    <path d="M94.31 288.01C95.3041 288.01 96.11 287.204 96.11 286.21C96.11 285.216 95.3041 284.41 94.31 284.41C93.3159 284.41 92.51 285.216 92.51 286.21C92.51 287.204 93.3159 288.01 94.31 288.01Z" fill="#434391"/>
                    <path d="M99.5 290.61C100.273 290.61 100.9 289.983 100.9 289.21C100.9 288.437 100.273 287.81 99.5 287.81C98.7268 287.81 98.1 288.437 98.1 289.21C98.1 289.983 98.7268 290.61 99.5 290.61Z" fill="#434391"/>
                    <path d="M70.52 280.59C71.1717 280.59 71.7 280.061 71.7 279.41C71.7 278.758 71.1717 278.23 70.52 278.23C69.8683 278.23 69.34 278.758 69.34 279.41C69.34 280.061 69.8683 280.59 70.52 280.59Z" fill="#434391"/>
                    <path d="M75.72 284.16C76.6865 284.16 77.47 283.376 77.47 282.41C77.47 281.443 76.6865 280.66 75.72 280.66C74.7535 280.66 73.97 281.443 73.97 282.41C73.97 283.376 74.7535 284.16 75.72 284.16Z" fill="#434391"/>
                    <path d="M80.92 287.21C81.9141 287.21 82.72 286.404 82.72 285.41C82.72 284.416 81.9141 283.61 80.92 283.61C79.9259 283.61 79.12 284.416 79.12 285.41C79.12 286.404 79.9259 287.21 80.92 287.21Z" fill="#434391"/>
                    <path d="M86.11 290.01C86.9937 290.01 87.71 289.293 87.71 288.41C87.71 287.526 86.9937 286.81 86.11 286.81C85.2263 286.81 84.51 287.526 84.51 288.41C84.51 289.293 85.2263 290.01 86.11 290.01Z" fill="#434391"/>
                    <path d="M72.72 289.36C73.6865 289.36 74.47 288.576 74.47 287.61C74.47 286.643 73.6865 285.86 72.72 285.86C71.7535 285.86 70.97 286.643 70.97 287.61C70.97 288.576 71.7535 289.36 72.72 289.36Z" fill="#434391"/>
                    <path d="M77.92 291.54C78.4336 291.54 78.85 291.123 78.85 290.61C78.85 290.096 78.4336 289.68 77.92 289.68C77.4064 289.68 76.99 290.096 76.99 290.61C76.99 291.123 77.4064 291.54 77.92 291.54Z" fill="#434391"/>
                </g>
                <path d="M147.31 184.85L146.66 190.29L146.57 191.03C143.81 213.98 148.29 237.21 159.38 257.49L194.22 177.92L147.31 184.86V184.85Z" fill="#F7F0F5"/>
                <path d="M144.88 137.94C148.277 137.94 151.03 135.186 151.03 131.79C151.03 128.393 148.277 125.64 144.88 125.64C141.483 125.64 138.73 128.393 138.73 131.79C138.73 135.186 141.483 137.94 144.88 137.94Z" fill="#7C5329"/>
                <path d="M178.42 137.94C181.817 137.94 184.57 135.186 184.57 131.79C184.57 128.393 181.817 125.64 178.42 125.64C175.023 125.64 172.27 128.393 172.27 131.79C172.27 135.186 175.023 137.94 178.42 137.94Z" fill="#7C5329"/>
                <path d="M138.34 109.53L135.22 108.5C132.25 107.52 129.08 109.36 128.47 112.43L127.16 118.98C126.21 123.75 127.7 128.69 131.15 132.13L138.33 139.31V109.52L138.34 109.53Z" fill="#553B2F"/>
                <path d="M131.03 116.76C130.17 119.49 130.69 122.46 132.42 124.73C133.68 126.39 135.78 127.18 137.82 126.77" stroke="#896750" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M188.9 89.5401C188.82 89.4601 180.36 86.6401 180.36 86.6401L171.78 91.4301L173.08 99.4201L187.05 101.56L188.9 89.5301V89.5401Z" fill="#FFCAA6"/>
                <path d="M214.7 116.7L207.97 100.79C204.65 92.9301 196.95 87.8301 188.42 87.8301C187.73 87.8301 186.91 88.3401 186.6 88.9601L181.46 99.2301L197.17 146.02L195.2 145.35L188.91 166.93L193.94 165.43C199.57 163.75 204.19 159.72 206.62 154.37L214.72 136.54C217.39 130.2 217.39 123.04 214.71 116.71L214.7 116.7Z" fill="#553B2F"/>
                <path d="M146.12 170.54C145.29 175.32 146.8 180.03 149.92 183.09C150.8 183.96 151.82 184.7 152.95 185.26C154.98 186.27 157.13 187.09 159.37 187.67C160.23 187.9 161.12 188.1 162.02 188.25C171.36 189.88 180.55 187.35 187.98 181.91C192.08 178.92 194.85 174.21 195.77 168.96L196.22 166.37L198.66 152.34C197.94 152.19 197.23 151.93 196.56 151.54L194.21 158.44C189.18 173.2 175.57 182.22 161.92 179.85C155.56 178.74 150.13 175.35 146.12 170.54Z" fill="#E5B798"/>
                <path d="M208.95 124.75C208.25 124.2 207.49 123.76 206.72 123.43C205.15 122.75 203.48 122.52 201.86 122.68C201.82 122.93 201.78 123.17 201.74 123.42L200.18 132.47C200.18 132.47 199.18 130.11 198.13 126.38C196.95 122.21 195.7 116.32 195.7 110.07C195.7 106.31 193.54 102.91 190.14 100.14C187.91 98.3101 185.14 96.7501 182.1 95.5301C179.91 94.6501 177.57 93.9601 175.19 93.4701C173.15 93.0401 171.07 92.7701 169.03 92.6801C168.44 92.6401 167.85 92.6301 167.26 92.6301C165.48 92.6301 163.8 92.7201 162.2 92.8801C160.09 93.0901 158.13 93.4401 156.32 93.8801C154.26 94.3901 152.4 95.0301 150.72 95.7601C149.07 96.4701 147.6 97.2701 146.29 98.1201C145.59 96.6701 146.84 99.6501 146.29 98.1201C139.84 101.96 138.04 106.99 137.4 115.6C137.4 115.6 133.61 146.54 133.54 147.9C132.85 160.69 137.77 167.88 143.95 173.44C144.54 173.97 145.14 174.47 145.76 174.95C151 179 157.52 181.41 164.62 181.41H164.63C179.86 181.41 193.69 170.24 196.35 154.84L196.8 152.03C197.59 152.3 198.41 152.44 199.22 152.46C200.63 152.51 202.03 152.2 203.28 151.56C204.63 150.89 205.81 149.83 206.65 148.44C207.2 147.53 207.56 146.55 207.73 145.57C208.19 142.96 209.2 140.47 210.77 138.34C211.17 137.8 211.53 137.2 211.82 136.55C213.68 132.46 212.48 127.53 208.95 124.76V124.75ZM139.84 134.63C139.84 131.54 141.53 128.86 144.04 127.45C145.23 126.76 146.62 126.38 148.09 126.38C151.26 126.38 154.02 128.17 155.4 130.81C155.99 131.95 156.33 133.25 156.33 134.63L155.45 134.78C150.28 135.69 144.99 135.64 139.84 134.63ZM180.85 135.44C177.26 135.44 173.21 134.64 173.21 134.64C173.21 131.45 175.02 128.68 177.67 127.31C178.8 126.72 180.09 126.39 181.45 126.39C184.7 126.39 187.51 128.27 188.85 131.01C189.39 132.11 189.69 133.34 189.69 134.64C189.69 134.64 184.57 135.44 180.85 135.44Z" fill="#FFCAA6"/>
                <path d="M152.11 157.71C154.51 158.77 171.19 158.05 171.19 158.05" stroke="#D87A55" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M163.74 126.82C163.74 126.82 162.71 129.44 162.05 130.51C161.46 131.47 159.98 133.46 157.84 137.66C156.07 141.14 155.97 142.4 155.97 142.4C154.95 145.42 156.62 147.8 159.4 147.8L164.88 147.4" stroke="#CC8666" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M199.41 145.23C200.95 145.78 202.86 144.37 203.68 142.08C204.49 139.79 203.9 137.49 202.37 136.94C203.9 137.49 205.81 136.07 206.63 133.78C207.44 131.49 206.86 129.19 205.32 128.64" stroke="#CC8666" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M169.68 123.46C172 121.44 174.17 120.48 177.12 120.23C177.93 120.16 180.99 120.13 181.92 120.23C185.75 120.61 187.12 121.2 189.68 123.07" stroke="#896750" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M158.26 123.46C156.15 121.36 153.62 120.55 150.33 120.18C149.02 120.03 146.37 120.06 145.05 120.18C143.45 120.33 142.3 120.66 140.73 121.32L139.84 121.82" stroke="#896750" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M138.18 109.6L135.81 105.92C135.55 103.6 136.07 101.25 137.31 99.26L138.92 96.58C143.48 88.95 151.66 84.22 160.55 84.08C160.55 84.08 175.28 83.56 181.46 85.12C184.49 85.88 186.68 86.61 185.39 89.13L182.11 95.53C181.11 97.47 178.56 98.77 176.37 98.77H158.87C150.61 98.77 142.88 102.82 138.18 109.6Z" fill="#553B2F"/>
                <path d="M181.45 99.2299L181.48 99.2499C183.56 100.96 184.51 102 185.51 104.5C186.36 106.62 190.36 118.01 192.18 123.77C194.47 130.99 200.53 139.98 200.53 139.98L203.88 137.43C208.2 134.14 211.94 130.16 214.95 125.63L185.48 91.1899L181.46 99.2299H181.45Z" fill="#553B2F"/>
                <path d="M172.27 193.08C172.91 193.08 176.45 174.67 176.45 174.67C176.45 174.67 181.44 173.31 187.05 170.14C192.66 166.97 197.25 160.45 197.25 160.45L194.21 177.92C194.21 177.92 187.78 184.65 185.53 186.2C182.29 188.44 172.27 193.08 172.27 193.08Z" fill="#F7F0F5"/>
                <path d="M143.05 171.8C144.48 172.79 145.82 173.29 148.85 174.13L154.03 193.48L147.31 184.86L138.74 167.73C138.74 167.73 140.67 170.15 143.06 171.81L143.05 171.8Z" fill="#F7F0F5"/>
                <path d="M178.1 175.73L173.92 194.13" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M146.64 175.6L152.13 192.4" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M196.62 92.3601L197.99 93.4401C202.2 96.7701 204.82 101.72 205.21 107.07C205.41 109.92 206.99 112.49 209.43 113.97C210.6 114.68 211.96 114.91 213.25 114.66" stroke="#896750" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M212.03 129.02C213.07 126.36 212.75 123.36 211.17 120.97C210.02 119.23 207.98 118.3 205.91 118.58" stroke="#896750" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M176.17 93H162.97C157.53 93 152.18 94.3 147.35 96.8" stroke="#896750" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M195.54 170.29L190.03 177.25C184.99 183.63 177.55 187.66 169.46 188.41L168.82 188.47C159.79 189.31 150.87 185.98 144.6 179.43L142.68 182.82C141.45 184.99 140.98 187.51 141.33 189.99C141.78 193.13 143.09 196.07 145.12 198.51C149.94 204.3 157.28 207.38 164.79 206.78C175.94 205.89 186.02 199.79 191.99 190.33L196.41 183.33C198.76 179.34 198.51 174.34 195.77 170.61L195.53 170.28" fill="#F7F0F5"/>
                <path d="M145.61 181.38C146.19 181.94 146.84 182.45 147.53 182.89C154.66 187.42 163.52 189.13 172.1 187.57C172.58 187.48 173.06 187.38 173.54 187.28C181.11 185.57 187.61 181.16 192.03 175.29L193.86 172.85" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M194.21 177.92L195.54 170.29C195.54 170.29 202.53 174.9 204.88 176.4C210.96 180.29 214.07 185.94 212.77 192.46C211.07 201 208 209.2 203.71 216.73C201.06 221.37 198.56 226.1 196.18 230.88C191.82 239.64 188.66 248.94 186.63 258.51L161.96 259.45L183.35 267.87L167.88 290.33H145.79L159.38 257.5L194.22 177.93" fill="#292D35"/>
                <path d="M146.56 191.03L146.65 190.29L144.59 179.43L143.67 177.6C136.45 181.11 131.01 187.48 128.68 195.17C125.93 204.22 125.63 213.83 127.8 223.03L135.93 257.49L154.77 259.33L145.24 263.82L153.54 271.56L159.37 257.49C148.28 237.21 143.81 213.98 146.56 191.03Z" fill="#292D35"/>
                <path d="M159.1 257.55L152.06 275.07" stroke="#6969D6" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M105.8 290.32L110.07 250.57" stroke="#6969D6" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M227.72 290.32L223.8 252.52" stroke="#6969D6" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M146.78 185.66C147.85 188.59 150.19 190.54 150.19 190.54L149.59 199.01L146.75 202.37" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M150.19 190.54C150.19 190.54 153.43 192.38 158.59 193.08C163.75 193.78 168.32 194.57 176.01 192.66C183.7 190.75 190.49 183.33 190.49 183.33" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M149.58 199.01L157.45 206.2L148.13 218.27" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M157.45 206.2L171.28 201.87L175.29 213.76" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M171.28 201.87L172.27 193.85" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M153.27 239.58L155.09 234.38C155.88 232.13 157.63 230.35 159.87 229.53C162.08 228.72 163.82 226.97 164.62 224.76L166.37 219.95" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M167.49 216.14C168.595 216.14 169.49 215.245 169.49 214.14C169.49 213.036 168.595 212.14 167.49 212.14C166.385 212.14 165.49 213.036 165.49 214.14C165.49 215.245 166.385 216.14 167.49 216.14Z" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M181.78 179.02C182.724 179.02 183.49 178.255 183.49 177.31C183.49 176.366 182.724 175.6 181.78 175.6C180.836 175.6 180.07 176.366 180.07 177.31C180.07 178.255 180.836 179.02 181.78 179.02Z" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M183.49 256.42C184.434 256.42 185.2 255.654 185.2 254.71C185.2 253.766 184.434 253 183.49 253C182.546 253 181.78 253.766 181.78 254.71C181.78 255.654 182.546 256.42 183.49 256.42Z" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
                <path d="M138.73 255.94C139.674 255.94 140.44 255.174 140.44 254.23C140.44 253.286 139.674 252.52 138.73 252.52C137.786 252.52 137.02 253.286 137.02 254.23C137.02 255.174 137.786 255.94 138.73 255.94Z" stroke="#F8CD93" stroke-miterlimit="10" stroke-linecap="round"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_2093_5101">
                <rect x="0.142822" y="167.571" width="236.982" height="236.982" rx="118.491" transform="rotate(-45 0.142822 167.571)" fill="white"/>
            </clipPath>
            <clipPath id="clip1_2093_5101">
                <rect width="202.36" height="210.85" fill="white" transform="translate(67 84)"/>
            </clipPath>
        </defs>
    </svg>

</template>

<style scoped>

</style>
