<script setup>
import CapitoliList from "@/components/chapters/ChaptersList.vue";
import LivelloStats from "@/components/levels/LevelStats.vue";
import PageBreadcrumb from "@/components/utilities/PageBreadcrumb.vue";
import {ref} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import LayoutPrimaryPage from "@/components/home/LayoutPrimaryPage.vue";
import {extractLevelRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import {useRouter} from "vue-router";

const themes = {
    warmLight : {
        descriptionText : "text-black"
    },
    greenish : {
        descriptionText : "text-black"
    },
    blue : {
        descriptionText : "text-white"
    }
}

const UserProgression = useUserProgressionStore();

const router = useRouter()

const levelRouteInfo = extractLevelRouteInfo()

const operaId = levelRouteInfo.operaInfo.id

const operaIndex = levelRouteInfo.operaInfo.index;
const levelIndex = levelRouteInfo.levelInfo.index;

const currentLevel = ref(AppContent.operas[operaIndex].levels[levelIndex])

const currentTheme = themes[AppContent.operas[operaIndex].theme] ?? themes["warmLight"]

</script>

<template>
    <LayoutPrimaryPage v-model="AppContent.operas[operaIndex].theme">
        <div class="chapters">
            <PageBreadcrumb :back-to="`/app/${operaId !== 'rossini' ? operaId : ''}`" :vertical="true" :theme="AppContent.operas[operaIndex].theme">
                <span class="uppercase">{{ currentLevel.title }}</span>
            </PageBreadcrumb>
            <div class="chapters-content">
                <div class="max-w-screen-sm mx-auto">
                    <div class="chapters-stats">
                        <LivelloStats
                            :data="currentLevel"
                            :progression="UserProgression.unlockedLevelAchievements(operaIndex, levelIndex)"
                            theme="levelPage"
                            :opera-theme="AppContent.operas[operaIndex].theme"
                        />
                    </div>
                    <div class="level-description" :class="currentTheme.descriptionText">
                        <component :is="AppContent.operas[operaIndex].levelDescriptionComponent">
                            {{currentLevel.description}}
                        </component>
                    </div>
                </div>
                <CapitoliList></CapitoliList>
            </div>
        </div>
    </LayoutPrimaryPage>
</template>

<style scoped>

.chapters {
    @apply container h-full;
    @apply max-w-screen-md xl:max-w-screen-lg;
}

.level-description{
    @apply ps-10 mb-6 mt-2 md:mb-16
}

.chapters-stats {
    @apply px-16 ;
}

.chapters-content{
    @apply max-w-screen-md mx-auto md:pt-6
}


</style>
