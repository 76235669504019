<script setup>
import LayoutPrimaryPage from "@/components/home/LayoutPrimaryPage.vue";
import LivelliList from "@/components/levels/LevelsList.vue";
import {extractOperaRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";

const operaRouteInfo = extractOperaRouteInfo()
const operaIndex = operaRouteInfo.index;
</script>

<template>
    <LayoutPrimaryPage v-model="AppContent.operas[operaIndex].theme">
        <div class="levels">
            <LivelliList/>
        </div>
    </LayoutPrimaryPage>
</template>

<style scoped>
.levels {
    @apply w-full;
}
</style>
