export const instrumentsMap = {
    1: {
        title: "Archi",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-1-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/archi.wav",
    },
    2: {
        title: "Chitarra",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-2-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/chitarra.wav",
    },
    3: {
        title: "Corno",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-3-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/corno.wav",
    },
    4: {
        title: "Fagotto",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-4-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/fagotto.wav",
    },
    5: {
        title: "Oboe",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-5-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/oboe.wav",
    },
    6: {
        title: "Pianoforte",
        img: "/assets/operas/barbieredisiviglia/level-0/instrument-image-6-O0L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/pianoforte.wav",
    },
    7:{
        title: "Archi",
        img: "/assets/operas/cenerentola/level-0/instruments/instrument-image-0-O2L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/Cenerentola_Archi.wav",
    },
    8:{
        title: "Fortepiano",
        img: "/assets/operas/cenerentola/level-0/instruments/instrument-image-1-O2L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/Cenerentola_Fortepiano.wav",
    },
    9:{
        title: "Clarinetto",
        img: "/assets/operas/cenerentola/level-0/instruments/instrument-image-2-O2L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/Cenerentola_Clarinetto.wav",
    },
    10:{
        title: "Oboe",
        img: "/assets/operas/cenerentola/level-0/instruments/instrument-image-3-O2L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/Cenerentola_Oboe.wav",
    },
    11:{
        title : "Ottavino e Fagotto",
        img: "/assets/operas/cenerentola/level-0/instruments/instrument-image-4-O2L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/Cenerentola_Ottavino%2BFagotto.wav",
    },
    12:{
        title: "Corno",
        img: "/assets/operas/cenerentola/level-0/instruments/instrument-image-5-O2L0C2.png",
        source: "https://io-bunny-cdn.b-cdn.net/ROF/instruments/Cenerentola_Corno.wav",
    }
}
