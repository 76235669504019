<script setup>
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import Video from "@/components/content/ContentVideo.vue";

import ContentPersonaggiCardsReveals from "@/components/content/characters/ContentCharactersCardsReveals.vue";
import NextCapitoloCard from "@/components/content/ContentNextChapterCard.vue";
import {defineModel, defineProps} from "vue";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import ContentFunFact from "@/components/content/ContentFunFact.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import ContentGlossaryTooltip from "@/components/glossary/GlossaryWordTooltip.vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import ContentFunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];

const readChapters = defineModel()

function checkAllCharactersUnlockedAchievement(characterId){
    if (!UserProgression.isAchievementUnlocked(2)
        && UserProgression.allCharactersUnlocked(operaIndex, levelIndex, chapterIndex)) {
        UserProgression.unlockAchievementById(2);
        UserNotifications.pushNotification("achievement", 2);
    }
}

function unlockVideoPlayedAchievement(){
    if (!UserProgression.isAchievementUnlocked(1)) {
        UserProgression.unlockAchievementById(1);
        UserNotifications.pushNotification("achievement", 1);
    }
}



</script>

<template>
    <Title>Di che cosa parla <i class="italic">Il barbiere di Siviglia</i>?</Title>
    <Textbox>
        Guarda il video per scoprirlo!
    </Textbox>
    <Video
        width="480"
        height="270"
        controls
        controlslist="nodownload noremoteplayback noplaybackrate"
        poster="/assets/operas/barbieredisiviglia/level-0/thumbnail-motion.png"
        @play="unlockVideoPlayedAchievement"
    >
        <template #sources>
            <source src="https://vz-c01ac8fb-8ab.b-cdn.net/6b3af9cc-b20e-4d56-9710-a2b521e59a18/original" type="video/mp4" />
        </template>
    </Video>

    <ContentFunFact theme="green">
        <template #counter>CURIOSITÀ (2 su 3)</template>
        <template #title><i class="italic">Il barbiere di Siviglia</i> non è il titolo originale dell’opera!</template>
        <template #funcfact-p>
            <Textbox>
                Inizialmente, il titolo dell’opera era <i class="italic">Almaviva, o sia L'inutile precauzione</i>.<br>
                Dopo le prime recite romane però, divenne  <i class="italic">Il barbiere di Siviglia</i>.
            </Textbox>
            <ContentFunfactParagraph>
                <template #image>
                    <img class="pt-4" src="/assets/operas/barbieredisiviglia/level-0/funfact-image-1-O0L0C1.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Nel libretto della prima rappresentazione si trova un particolare
                        <i class="italic">Avvertimento al pubblico</i> con l’intento di manifestare il rispetto nei confronti di <ContentGlossaryTooltip :word-id="9" theme="white">Giovanni Paisiello</ContentGlossaryTooltip>,
                        cui si deve un’altra versione, precedente e molto amata del <i class="italic">Barbiere</i>, ma anche segnalare “nuove situazioni di pezzi musicali".
                    </p>
                </template>
            </ContentFunfactParagraph>
        </template>
    </ContentFunFact>
    <!-- - -->
    <Title>Chi sono i personaggi del <i class="italic">Barbiere di Siviglia</i>?</Title>
    <textbox>
        Clicca sulle card per scoprire
        <img src="/assets/icons/character-card/Icona_Potere.png" class="inline-icon"  alt="character trait icon"> potere principale,
        <img src="/assets/icons/character-card/Icona_Caratteristiche.png" class="inline-icon" alt="character trait icon"> caratteristiche,
        <img src="/assets/icons/character-card/Icona_Punti_Deboli.png" class="inline-icon" alt="character trait icon"> punti deboli,
        <img src="/assets/icons/character-card/Icona_Arma_Segreta.png" class="inline-icon" alt="character trait icon"> arma segreta
        e <img src="/assets/icons/character-card/Icona_Obiettivo.png" class="inline-icon" alt="character trait icon"> obiettivo
        di ogni personaggio!
    </textbox>
    <ContentPersonaggiCardsReveals @character-unlocked="checkAllCharactersUnlockedAchievement"  :characters="currentChapter.characters"/>

    <NextCapitoloCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>
</style>
