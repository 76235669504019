export const glossaryWordsGroupsMap = {
    1: {
        title: "Struttura opera",
        items: [1, 32],
    },
    2: {
        title: "Documenti opera",
        items: [6, 33, 34, 35],
    },
    3: {
        title: "Tipi di opera",
        items: [7, 11, 12, 60, 78, 85, 86],
    },
    4: {
        title: "Tipi di brani",
        items: [10, 21, 22, 36, 79, 84, 87, 88],
    },
    5: {
        title: "Personaggi storici",
        items: [3, 4, 9, 23, 24, 25, 37, 38, 39, 40, 41, 42, 43, 55, 56, 58, 59, 64, 65, 77, 80, 81, 83, 89, 90, 91],
    },
    6: {
        title: "Ruoli opera",
        items: [13, 14],
    },
    7: {
        title: "Tipi di voci",
        items: [15, 16, 17, 18, 19, 20],
    },
    8: {
        title: "Luoghi chiave",
        items: [8, 26, 44, 45, 46, 66, 67, 92, 93],
    },
    9: {
        title: "Titoli opere",
        items: [5, 27, 28, 53, 47, 48, 49, 50, 61, 62, 63, 69, 70, 71, 72, 73, 74, 75, 76, 82, 94, 95, 96, 97, 98],
    },
    10: {
        title: "Extra",
        items: [2, 31, 29, 30, 51, 52, 57, 99],
    },
}

export const glossaryWordsMap = {
    1: {
        operaId: 1,
        word: "Atti",
        description:
            "Diverse parti in cui è strutturata un'opera lirica. Ci sono opere in uno, due e perfino cinque atti." +
            " I due atti di una commedia sono come il primo e il secondo tempo di un film. Aiutano a organizzare meglio la storia.",
    },
    2: {
        operaId: 1,
        word: "Opera",
        description:
            "Forma di spettacolo che unisce elementi diversi." +
            " L'azione teatrale, attraverso la musica, il canto, le scene e i costumi, racconta una storia.",
    },
    3: {

        word: "Gioachino Rossini",
        description:
            "Compositore italiano del XIX secolo, noto soprattutto per le sue opere.",
    },
    4: {
        operaId: 1,
        word: "Cesare Sterbini",
        description:
            "Librettista italiano del XIX secolo," +
            " famoso per aver scritto il libretto del Barbiere di Siviglia di Rossini." +
            " Il suo lavoro ha contribuito a rendere quest’opera un capolavoro musicale.",
    },
    5: {
        operaId: 1,
        word: "Le barbier de Séville ou La précaution inutile",
        description:
            "Commedia teatrale francese del 1775 di Pierre-Augustin Caron de Beaumarchais." +
            " Come quando si trasforma un libro in film," +
            " Cesare Sterbini ha trasformato questa commedia teatrale in un testo per l’opera musicata da Rossini.",
    },
    6: {
        operaId: 1,
        word: "Libretto",
        description: "Contiene le parole che vengono cantate durante l'opera e le didascalie sceniche." +
            " È come se fosse la sceneggiatura di un film.",
    },
    7: {
        operaId: 1,
        word: "Opera buffa (o comica)",
        description:
            "Detta anche \"commedia in musica\" o \"dramma giocoso\"." +
            " Nata a Napoli come genere di opera per la gente comune," +
            " racconta storie semplici con lo scopo di divertire il pubblico.",
    },
    8: {
        operaId: 1,
        word: "Teatro Argentina",
        description:
            "Uno dei principali teatri di Roma, inaugurato nel 1732 e tuttora in attività," +
            " famoso anche per la prima rappresentazione del Barbiere di Siviglia di Rossini il 20 febbraio 1816.",
    },
    9: {
        operaId: 1,
        word: "Giovanni Paisiello",
        description:
            "Compositore italiano del XVIII secolo che ha contribuito" +
            " in maniera determinante allo sviluppo dell'opera buffa.",
    },
    10: {
        operaId: 1,
        word: "Sinfonia",
        description:
            "Brano orchestrale introduttivo di un’opera affermatosi nel Seicento e divenuto," +
            " nel secolo successivo, una forma musicale autonoma e complessa.",
    },
    11: {
        operaId: 1,
        word: "Opera seria",
        description: "Genere di opera lirica che tratta temi seri e drammatici," +
            " spesso caratterizzata da personaggi nobili e situazioni tragiche.",
    },
    12: {
        operaId: 1,
        word: "Opera semiseria",
        description: "Genere operistico intermedio tra serio e buffo," +
            " in cui convivono personaggi, forme e stili tratti da entrambi i generi.",
    },
    13: {
        operaId: 1,
        word: "Compositore",
        description: "Chi scrive la musica dell’opera.",
    },
    14: {
        operaId: 1,
        word: "Copista",
        description: "Musicista che prepara le parti per cantanti, coro e orchestra.",
    },
    15: {
        operaId: 1,
        word: "Tenore",
        description: "La più acuta delle voci maschili.",
    },
    16: {
        operaId: 1,
        word: "Baritono",
        description: "Voce maschile intermedia tra il tenore e il basso.",
    },
    17: {
        operaId: 1,
        word: "Mezzosoprano",
        description: "Voce femminile intermedia tra quella del contralto e del soprano.",
    },
    18: {
        operaId: 1,
        word: "Basso",
        description: "La più grave tra le voci maschili.",
    },
    19: {
        operaId: 1,
        word: "Soprano",
        description: "La più acuta delle voci femminili.",
    },
    20: {
        operaId: 1,
        word: "Vocalità",
        description: "Insieme delle caratteristiche e capacità vocali di un cantante.",
    },
    21: {
        operaId: 1,
        word: "Aria",
        description: "Brano vocale solistico, con un inizio, uno svolgimento e una conclusione, che permette" +
            " agli interpreti di mostrare le proprie abilità vocali ed esprimere diverse emozioni durante la performance.",
    },
    22: {
        operaId: 1,
        word: "Arietta",
        description: "Breve aria di carattere leggero e sentimentale," +
            " cantata generalmente dalle seconde parti nel melodramma settecentesco.",
    },
    23: {
        operaId: 1,
        word: "Francesco Sforza Cesarini",
        description: "Aristocratico romano. Impresario del Teatro Argentina" +
            " fatto erigere nel 1732 da suo nonno Giuseppe Sforza.",
    },
    24: {
        operaId: 1,
        word: "Ercole Consalvi",
        description: "Segretario di Stato che comunicò al duca Francesco Sforza Cesarini che il Teatro Argentina," +
            " di proprietà del duca stesso, sarebbe dovuto rimanere aperto per la stagione teatrale di Carnevale e" +
            " avrebbe ospitato opere buffe.",
    },
    25: {
        word: "Jacopo Ferretti",
        description: "Nato a Roma il 16 luglio 1784. Scrisse poesie, prose e anche libretti d'opera.",
    },
    26: {
        operaId: 1,
        word: "Teatro Valle",
        description: "Famoso teatro di Roma, inaugurato nel gennaio del 1727." +
            " Attivo tutto l'anno, era noto per le rappresentazioni di opere buffe, semiserie e commedie.",
    },
    27: {
        operaId: 1,
        word: "L'Italiana in Algeri",
        description: "Dramma giocoso per musica in due atti su libretto di Angelo Anelli." +
            " Fu rappresentato per la prima volta al Teatro San Benedetto di Venezia il 22 maggio 1813." +
            " Rossini diresse quest’opera a Vicenza (1813) e a Napoli (1815).",
    },
    28: {
        operaId: 1,
        word: "Torvaldo e Dorliska",
        description: "Dramma semiserio in due atti su libretto di Cesare Sterbini." +
            " Messo in scena per la prima volta al Teatro Valle di Roma il 26 dicembre 1815, fu accolto freddamente.",
    },
    29: {
        operaId: 1,
        word: "Compagnia",
        description: "Gruppo di cantanti e musicisti" +
            " che si esibiscono insieme in produzioni teatrali.",
    },
    30: {
        operaId: 1,
        word: "Claque",
        description: "Vocabolo francese che letteralmente significa “colpo dato col palmo della mano”." +
            " Nel gergo teatrale viene così chiamato un gruppo di persone pagate per applaudire (o fischiare)" +
            " una rappresentazione teatrale.",
    },
    31: {
        operaId: 1,
        word: "Carnevale",
        description: "La stagione teatrale di Carnevale iniziava il 26 dicembre e terminava con il Mercoledì delle ceneri." +
            " Era un periodo molto importante per gli artisti," +
            " che potevano guadagnare prima del lungo periodo di pausa della Quaresima, in cui gli spettacoli erano " +
            "quasi sempre vietati."
    },
    32: {
        operaId: 1,
        word: "Sinfonia",
        description: "Introduzione orchestrale di un'opera lirica, un oratorio o una cantata," +
            " usata per preparare il pubblico. Nel XVIII secolo, la sinfonia all’italiana aveva lo scopo" +
            " di catturare l'attenzione del pubblico e abbattere il brusio in sala. Originariamente articolata" +
            " in tre movimenti (Allegro–Adagio–Allegro), in seguito prevale la struttura in un singolo movimento Allegro," +
            " spesso preceduto da un Maestoso introduttivo. Rossini ne offre esempi brillanti."
    },
    33: {
        operaId: 1,
        word: "Autografo",
        description: "Manoscritto d’autore di un’opera letteraria o musicale."
    },

    34: {
        operaId: 1,
        word: "Edizione critica",
        description: "Versione di una partitura che punta a restituire  l'opera al suo stato originale," +
            " basandosi su studi approfonditi del materiale pervenuto ai giorni nostri."
    },
    35: {
        operaId: 1,
        word: "Appendici",
        description: "documenti che consentono di ricostruire la storia e l’evoluzione dell'opera nel corso del tempo." +
            " Possono contenere integrazioni, elaborazioni, modifiche o sostituzioni di parti vocali, strumentali di una composizione."
    },
    36: {
        operaId: 1,
        word: "Stretta",
        description: "Passaggio di una composizione, spesso usato nei finali operistici," +
            " dove il ritmo accelera gradualmente fino a raggiungere un momento culminante."
    },
    37: {
        operaId: 1,
        word: "Costantino Dall’Argine",
        description: "compositore che studiò al Conservatorio di Milano. Raggiunse grande successo come autore" +
            " di balli e ottenne una buona accoglienza con opere buffe come <i class='italic'>I due orsi (1867).</i>" +
            " Nel 1868 musicò il libretto di Sterbini per <i class='italic'>Il Barbiere di Siviglia</i>."
    },
    38: {
        operaId: 1,
        word: "Francesco Morlacchi",
        description: "Compositore che studiò al Liceo filarmonico di Bologna con Stanislao Mattei." +
            " Iniziò a scrivere opere nel 1807 e nel 1810 divenne direttore dell’Opera italiana di Dresda." +
            " Nel 1815 compose <i class='italic'>Il barbiere di Siviglia</i> sullo stesso libretto usato da  Paisiello." +
            " La prima rappresentazione ebbe luogo a Dresda il 27 aprile 1816 con buon successo."
    },
    39: {
        operaId: 1,
        word: "Alberto Zedda",
        description: "Direttore d'orchestra e musicologo. Vinse il Concorso internazionale della RAI per direttori" +
            " d’orchestra nel 1957. Svolse un’intensa attività operistica e fu consulente artistico (1981-1992)" +
            " e direttore artistico (2001-2015) del Rossini Opera Festival di Pesaro." +
            " Fondò e diresse l'Accademia Rossiniana di Pesaro (1989-2016)." +
            " Curò edizioni critiche di opere di Rossini e fu membro del Comitato editoriale della Fondazione Rossini."
    },
    40: {
        operaId: 1,
        word: "Casa Ricordi",
        description: "Casa editrice musicale milanese, fondata nel 1808 da Giovanni Ricordi (1785-1853)." +
            " Nel 1815 divenne copista della Scala con il diritto di pubblicarne il materiale e nel 1825 acquistò" +
            " l’archivio del teatro. Il figlio Giovanni fu amico di Rossini e pubblicò molte delle sue opere," +
            " sia in versione per canto e piano, sia in vari arrangiamenti per dilettanti."
    },
    41: {
        operaId: 1,
        word: "Geltrude Righetti Giorgi",
        description: "Contralto. Studiò a Bologna dove conobbe Rossini." +
            " Debuttò nel 1811 in un’opera di Cimarosa. Sposò l’avvocato Luigi Giorgi nel 1814 e rinunciò al canto." +
            " Nel 1815, su suggerimento dell’agente teatrale Francesco Zappi e con l'approvazione di Rossini," +
            " fu scritturata per la Stagione di Carnevale al Teatro Argentina di Roma, sostituendo Elisabetta Gafforini." +
            " Dopo il successo nell’<i class='italic'>Italiana in Algeri</i>, fu la prima interprete di Rosina nel" +
            " <i class='italic'> Barbiere di Siviglia</i> e, l’anno successivo, di Cenerentola nell’opera omonima."
    },
    42: {
        operaId: 1,
        word: "Pier Luigi Pizzi",
        description: "Scenografo, regista e costumista italiano. Dal 1951 ha lavorato come scenografo" +
            " e costumista per spettacoli di prosa e lirica. Importante il suo sodalizio con Giorgio De Lullo" +
            " e la Compagnia dei Giovani, nonché la collaborazione con Luca Ronconi. Attivo nei principali teatri" +
            " e festival internazionali, ha ricevuto prestigiosi riconoscimenti."
    },
    43: {
        operaId: 1,
        word: "Luca Ronconi",
        description: "Regista e attore italiano. Ha diretto spettacoli prestigiosi e guidato il Teatro Stabile" +
            " di Torino (1988-93), il Teatro Stabile di Roma (1994-98), e il Piccolo Teatro di Milano" +
            " come direttore artistico (1999-2004)."
    },
    44: {
        operaId: 1,
        word: "Teatro Comunale di Bologna",
        description: "Costruito da Antonio Galli Bibbiena, inaugurato il 14 maggio 1763 con" +
            " <i class='italic'>Il trionfo di Clelia</i> di Gluck. Famoso per l'eccellente qualità degli spettacoli e" +
            " per la fama degli artisti internazionali. Rossini visse a Bologna per anni e vide molte delle" +
            " sue opere rappresentate al Comunale."
    },
    45: {
        operaId: 1,
        word: "Teatro Contavalli",
        description: "Costruito dal dottor Antonio Contavalli, inaugurato il 3 ottobre 1814. Presto vi fu rappresentata" +
            " <i class='italic'>L'Italiana in Algeri</i> di Rossini, cantata da Maria Marcolini. Dopo una chiusura temporanea durante la" +
            " Restaurazione, riaprì nell'estate del 1816 con una replica dell'<i class='italic'>Italiana in Algeri</i> e " +
            "<i class='italic'>Il Barbiere di Siviglia di Rossini.</i>"
    },
    46: {
        operaId: 1,
        word: "Teatro della Pergola di Firenze",
        description: "Inaugurato nel 1657 con <i class='italic'>Il podestà di Colognole</i> di Giovanni Andrea Moniglia." +
            " Progettato da Ferdinando Tacca, la sala unica era ispirata ai cortili dei palazzi rinascimentali."
    },
    47: {
        operaId: 1,
        word: "Aureliano in Palmira",
        description: "Dramma giocoso per musica in due atti su libretto di Angelo Anelli. Fu rappresentato per la prima" +
            " volta al Teatro San Benedetto di Venezia il 22 maggio 1813." +
            " Rossini diresse quest’opera a Vicenza (1813) e a Napoli (1815)."
    },
    48: {
        operaId: 1,
        word: "Elisabetta regina d’Inghilterra",
        description: "Dramma per musica in due atti di Gioachino Rossini su libretto di Giovanni Schmidt." +
            " Rappresentata per la prima volta a Napoli, presso il Teatro San Carlo, il 4 ottobre del 1815," +
            " con protagonista Isabella Colbran."
    },
    49: {
        operaId: 1,
        word: "Sigismondo",
        description: "Dramma in due atti di Gioachino Rossini su libretto di Giuseppe Foppa." +
            " Prima rappresentazione a Venezia, presso il Teatro La Fenice, il 26 dicembre 1814."
    },
    50: {
        operaId: 1,
        word: "Cantata in onore del compleanno di Sua Maestà il Re Ferdinando IV",
        description: "Cantata <i class='italic'>Pel faustissimo giorno natalizio di Sua Maestà il re Ferdinando IV, nostro augusto sovrano</i>" +
            ", composta da Rossini nel 1815-1816 ed eseguita il 12 gennaio 1816 al Teatro San Carlo di Napoli in onore di Ferdinando di Borbone (1751-1825)."
    },
    51: {
        operaId: 1,
        word: "Autoimprestiti",
        description: "Pratica che consiste nel riutilizzo da parte dei compositori di temi," +
            " frasi, movimenti e interi pezzi delle loro opere precedenti. Questo fenomeno, comune tra il Settecento" +
            " e il primo Ottocento, è frequente anche nelle opere di Rossini."
    },
    52: {
        operaId: 1,
        word: "Crescendo rossiniano",
        description: "Tecnica utilizzata per creare situazioni paradossali o estremamente animate," +
            " impiegata da Rossini e altri compositori come Mayr e Generali. Consiste nella ripetizione" +
            " di brevi incisi musicali che aumentano progressivamente la sonorità, grazie all'ingresso progressivo" +
            " di tutti gli strumenti, dal pianissimo al fortissimo."
    },
    53: {
        operaId: 1,
        word: "<i>La pietra del paragone</i>",
        description: "Melodramma giocoso in due atti su libretto di Luigi Romanelli. Fu rappresentato per la prima" +
            " volta il 26 settembre 1812 a Milano, Teatro alla Scala. La pietra del paragone fu la prima commissione" +
            " di Rossini per la Scala di Milano, il principale teatro di uno dei maggiori centri culturali italiani."
    },
    54: {
        operaId: 1,
        word: "<i>Fondazione Rossini</i>",
        description: "Dal 1940 gestisce il patrimonio materiale e immateriale del Maestro da lui lasciato" +
            " alla città di Pesaro. Dal 1979 sta pubblicando l'edizione critica di tutti i lavori di Rossini," +
            " non solo le opere: una gigantesca impresa che terminerà tra qualche decennio al ritmo di un volume l'anno."
    },
    55: {
        operaId: 2,
        word: "Anna Guidarini",
        description: "Madre di Gioachino Rossini, figlia di un fornaio. Dopo la caduta del divieto" +
            " per le donne di esibirsi, intraprese una carriera come cantante nei teatri delle Marche e dell'Emilia Romagna." +
            " Visse a Bologna fino alla morte. Di lei rimangono due ritratti: uno in costume di scena e uno in età avanzata."
    },
    56: {
        operaId: 2,
        word: "Giuseppe Prinetti",
        description: "Musicista novarese e maestro al cembalo al Teatro Comunale di Bologna." +
            " Tra i primi insegnanti di Rossini tra il 1799 e il 1801."
    },
    57: {
        operaId: 2,
        word: "Spinetta",
        description: "Strumento musicale a tastiera, simile al clavicembalo, ma di dimensioni più piccole e con" +
            " un suono più delicato. Spesso utilizzato per lo studio e l'accompagnamento nel XVIII e XIX secolo."
    },
    58: {
        operaId: 2,
        word: "Fratelli Malerbi",
        description: "Giuseppe e Luigi Malerbi, musicisti e compositori, furono tra i primi maestri di Rossini a Lugo" +
            " di Romagna, dove il giovane Gioachino studiò basso cifrato e composizione sotto la loro guida."
    },
    59: {
        operaId: 2,
        word: "Padre Stanislao Mattei",
        description: "Compositore e insegnante bolognese, maestro di cappella in San Petronio. Fu tra i" +
            " principali insegnanti di contrappunto di Rossini al Liceo Filarmonico di Bologna." +
            " Compose prevalentemente musica sacra."
    },
    60: {
        operaId: 2,
        word: "Farsa comica",
        description: "Breve opera lirica comica in un atto, con trama semplice e personaggi stereotipati." +
            " Popolare nei teatri italiani alla fine del XVIII e inizio del  XIX secolo."
    },
    61: {
        operaId: 2,
        word: "La cambiale di matrimonio",
        description: "Farsa comica su libretto di Gaetano Rossi. Prima rappresentazione:" +
            " Teatro San Moisè, Venezia, 3 novembre 1810."
    },
    62: {
        operaId: 2,
        word: "L'inganno felice",
        description: "Farsa su libretto di Giuseppe Foppa. Prima rappresentazione:" +
            " Teatro San Moisè, Venezia, 8 gennaio 1812."
    },
    63: {
        operaId: 2,
        word: "Il Turco in Italia",
        description: "Dramma buffo in due atti su libretto di Felice Romani." +
            " Prima rappresentazione: Teatro alla Scala, Milano, 14 agosto 1814."
    },
    64: {
        operaId: 2,
        word: "Domenico Barbaja",
        description: "Impresario milanese, noto per la sua gestione dei teatri di Napoli e per aver" +
            " dato una svolta alla carriera di Rossini. Inventore della “barbajata”," +
            " una bevanda a base di caffè, cioccolata e panna."
    },
    65: {
        operaId: 2,
        word: "Isabella Colbran",
        description: "Soprano spagnolo, celebre per la sua collaborazione con Rossini, di cui divenne moglie nel 1822." +
            " La sua voce agile e la presenza scenica la resero una delle cantanti più ammirate del suo tempo."
    },
    66: {
        operaId: 2,
        word: "Teatro San Moisè",
        description: "Teatro veneziano specializzato in opere buffe e farse tra la fine del Settecento e il 1818." +
            " Qui Rossini debuttò nel 1810 con <i class='italic'>La cambiale di matrimonio.</i>"
    },
    67: {
        operaId: 2,
        word: "Accademia Rossiniana “Alberto Zedda”",
        description: "Corso di alta specializzazione per giovani cantanti internazionali, attivo a Pesaro dal 1989." +
            " Parte integrante del Rossini Opera Festival."
    },
    69: {
        operaId: 2,
        word: "Otello",
        description: "Dramma per musica in tre atti su libretto di Francesco Berio di Salsa." +
            " Prima rappresentazione: Teatro del Fondo, Napoli, 4 dicembre 1816."
    },
    70:{
        operaId:2,
        word: "La Cenerentola",
        description : "Dramma giocoso in due atti su libretto di Giacomo Ferretti." +
            " Prima rappresentazione: Teatro Valle, Roma, 25 gennaio 1817."
    },
    71:{
        operaId:2,
        word: "Zelmira",
        description : "dramma in due atti su libretto di Andrea Leone Tottola, fu rappresentata per la prima volta" +
            " a Napoli il 16 febbraio 1822, al Teatro San Carlo." +
            " Segna la  conclusione del soggiorno napoletano di Rossini."
    },
    72:{
        operaId:2,
        word: "Semiramide",
        description : "Melodramma tragico in due atti su libretto di Gaetano Rossi." +
            " Prima rappresentazione: Teatro La Fenice, Venezia, 3 febbraio 1823."
    },
    73:{
        operaId:2,
        word: "Il viaggio a Reims, ossia L’albergo del giglio d’oro",
        description : "Cantata scenica in un atto, scritta per l’incoronazione di Carlo X." +
            " Prima rappresentazione: Théâtre Italien, Parigi, 19 giugno 1825."
    },
    74:{
        operaId:2,
        word: "Le Siège de Corinthe",
        description : "<i class='italic'>Tragédie lyrique</i> in tre atti, rifacimento del <i class='italic'>Maometto II.</i>" +
            " Prima rappresentazione: Théâtre de l’Académie Royale de Musique, Parigi, 9 ottobre 1826."
    },
    75:{
        operaId:2,
        word: "Le Comte Ory",
        description : "Su libretto di Eugène Scribe e Charles-Gaspard Delestre-Poirson, fu rappresentata per la prima" +
            " volta a Parigi al Théâtre de l’Académie Royale de Musique il 20 agosto 1828." +
            " Parte della musica di quest’opera proviene da <i class='italic'>Il viaggio a Reims.</i>"
    },
    76:{
        operaId:2,
        word: "Guillaume Tell",
        description : "Opéra in quattro atti su libretto di Étienne de Jouy e Hippolyte-Louis-Florent Bis." +
            " Prima rappresentazione: Théâtre de l’Académie Royale de Musique, Parigi, 3 agosto 1829."
    },
    77:{
        operaId:2,
        word: "Olympe Pélissier:",
        description : "Seconda moglie di Rossini dal 1846. Fu modella e attrice, e assistette Rossini fino alla morte." +
            " Acconsentì al trasferimento delle spoglie del marito a Firenze e rispettò il suo testamento."
    },
    78: {
        operaId:3,
        word: "Dramma giocoso",
        description : "Termine generico usato tra Sette e Ottocento e che significa “opera comica”." +
            " Alcune opere comiche però possono contenere anche momenti di riflessione o più seri," +
            " come le canzoni che Cenerentola canta mentre lavora in casa. "
    },
    79 : {
        operaId:3,
        word:"Cavatina",
        description : "Si chiama così, in genere, l’aria con cui un personaggio importante si presenta in scena," +
            " offrendo un primo assaggio del suo carattere e delle sue emozioni."
    },
    80: {
        operaId:3,
        word: "Charles Perrault",
        description : "Celebre scrittore francese nato nel 1628, autore di numerose fiabe che sono diventate" +
            " classici senza tempo. La sua versione della storia di Cenerentola, pubblicata nel 1697," +
            " ha influenzato profondamente tutte le rappresentazioni successive del personaggio," +
            " compresa quella nell'opera di Rossini."
    },
    81: {
        operaId:3,
        word: "Stefano Pavesi",
        description : "Compositore italiano nato nel 1779, noto soprattutto per le sue opere comiche tra cui" +
            " <i>Ser Marcantonio</i>, da cui Donizetti trarrà ispirazione per <i>Don Pasquale.</i>" +
            " Fu uno dei compositori più rappresentati e apprezzati all’inizio dell’Ottocento e la sua vita" +
            " si è incrociata più volte con quella di Rossini."
    },
    82: {
        operaId:3,
        word: "<i>Agatina o la virtù premiata</i>",
        description : "Un'opera semiseria composta da Stefano Pavesi su libretto di Francesco Fiorini," +
            " rappresentata per la prima volta a Milano nel 1814. La trama segue le avventure di Agatina," +
            " una giovane donna la cui bontà e virtù vengono alla fine ricompensate, non senza l’aiuto del mago Alidoro."
    },
    83: {
        word: "Luca Agolini",
        description : ""
    },
    84: {
        word: "Rondò",
        description : ""
    },
    85: {
        word: "Pastiche",
        description : ""
    },
    86: {
        word: "Dramma semiserio",
        description : ""
    },
    87: {
        word: "Cabaletta",
        description : ""
    },
    88: {
        word: "Sortita",
        description : ""
    },
    89: {
        word: "Pietro Cartoni",
        description : ""
    },
    90: {
        word: "Gaetano Rossi",
        description : ""
    },
    91: {
        word: "Charles-Guillaume Étienne",
        description : ""
    },
    92: {
        word: "Teatro Apollo",
        description : ""
    },
    93: {
        word: "Théâtre-Italien",
        description : ""
    },
    94: {
        word: "<i>La Gazzetta</i>",
        description : ""
    },
    95: {
        word: "<i>Matilde di Shabran</i>",
        description : ""
    },
    96: {
        word: "<i>Barbiere di Siviglia</i>",
        description : ""
    },
    97: {
        word: "<i>Francesca di Foix</i>",
        description : ""
    },
    98: {
        word: "<i>Cendrillon</i>",
        description : ""
    },
    99: {
        word: "Bozzetti",
        description : ""
    },
    100: {
        word: "",
        description : ""
    },
    101: {
        word: "",
        description : ""
    },
    102: {
        word: "",
        description : ""
    },
    103: {
        word: "",
        description : ""
    },
}
