import {levelsMap} from "@/content/data/levels.js";
import {chaptersMap} from "@/content/data/chapters.js";
import {achievementProfilePictures, achievementsMap} from "@/content/data/achievements.js";
import {operasMap} from "@/content/data/operas.js";
import {glossaryWordsGroupsMap, glossaryWordsMap} from "@/content/data/glossaryWords.js";
import {charactersMap} from "@/content/data/characters.js";
import {instrumentsMap} from "@/content/data/instruments.js";
import {ariasMap} from "@/content/data/arias.js";
import {autoimprestitiMap, autoimprestitiSourcesMap} from "@/content/data/autoimprestiti.js";

const operasIds = [2, 1, 3]

const getOperaFromId = (operaId) => composeOpera(operasMap[operaId], operaId)

const composeOpera = (operaRow, id) => {
    return {
        ...operaRow,
        id,
        levels: operaRow.levels.map(levelId => getLevelFromId(levelId))
    }
}

const getLevelFromId = (levelId) => composeLevel(levelsMap[levelId], levelId)

const composeLevel = (levelRow, id) => {
    return {
        ...levelRow,
        id,
        chapters : levelRow.chapters.map(chapterId => getChapterFromId(chapterId)),
        achievements : levelRow.achievements.map(achievementId => getAchievementFromId(achievementId)),
    }
}

const getChapterFromId = (chapterId) => composeChapter(chaptersMap[chapterId], chapterId)

const composeChapter = (chapterRow, id) => {
    return {
        ...chapterRow,
        id,
        characters : chapterRow.characters?.map(charId => getCharacterFromId(charId)),
        instruments : chapterRow.instruments?.map(instrumentId => getInstrumentFromId(instrumentId)),
        arias : chapterRow.arias?.map(ariaId => getAriaFromId(ariaId)),
        autoimprestiti : chapterRow.autoimprestiti?.map(id => getAutoimprestitoFromId(id)),
    }
}

const getAchievementFromId = (achievementId) => composeAchievement(achievementsMap[achievementId], achievementId)
const composeAchievement = (chapterRow, id) => {
    return {
        ...chapterRow,
        id
    }
}

const getAutoimprestitoFromId = (autoimprestitoId) =>  composeAutoimprestito(autoimprestitiMap[autoimprestitoId], autoimprestitoId)
const composeAutoimprestito = (autoimprestitoRow, id) => {
    return {
        ...autoimprestitoRow,
        id,
        sources : autoimprestitoRow.sources.map(autoimprestitoSourceId => getAutoimprestitoSourceFromId(autoimprestitoSourceId))
    }
}

const getAutoimprestitoSourceFromId = (id) => composeAutoimprestitoSource(autoimprestitiSourcesMap[id], id)

const composeAutoimprestitoSource = (autoimprestitoSourceRow, id) => {
    return {
        ...autoimprestitoSourceRow,
        id
    }
}

const getGlossaryGroupFromId = (glossaryGroupId) => composeGlossaryGroup(glossaryWordsGroupsMap[glossaryGroupId], glossaryGroupId)

const composeGlossaryGroup = (glossaryGroupRow, id) => {
    return {
        ...glossaryGroupRow,
        id,
        items : glossaryGroupRow.items.map(wordId => getGlossaryWordFromId(wordId))
    }
}

const getGlossaryWordFromId = (wordId) => (
    {
        ...glossaryWordsMap[wordId],
        id : wordId
    })

const getCharacterFromId = (characterId) => ({
    ...charactersMap[characterId],
    id : characterId
})

const getInstrumentFromId = (instrumentId) => ({
    ...instrumentsMap[instrumentId],
    id : instrumentId
})

const getAriaFromId = (ariaId) => ({
    ...ariasMap[ariaId],
    id : ariaId
})

export const AppContent = {
    operas : operasIds.map(operaId => getOperaFromId(operaId)),
    glossary : Object.keys(glossaryWordsGroupsMap).map(key => getGlossaryGroupFromId(parseInt(key))),
    achievementProfilePictures
}
