<script setup>

import Autoimprestito from "@/components/content/ContentAutoimprestito.vue";
import Title from "@/components/content/ContentTitle.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import {defineModel, defineProps} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import AriaPlayer from "@/components/content/music/AriaPlayer.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import ContentAppendixCardTrigger from "@/components/content/ContentAppendixCardTrigger.vue";
import {AppContent} from "@/content/data/content.js";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;

const autoimprestiti = AppContent.operas[operaIndex].levels[levelIndex].chapters[props.chapterIndex].autoimprestiti

function onAriaPlayed(id){
    UserProgression.setAriaAsListened(id)
    checkAchievement()
}

function onAutoimprestitoPlayed(id){
    UserProgression.setAutoimprestitoAsListened(id)
    checkAchievement()
}

function checkAchievement(){
    if (!UserProgression.isAchievementUnlocked(8)
        && UserProgression.allAriasListened(operaIndex, levelIndex, props.chapterIndex)
        && UserProgression.allAutoimprestitiListened(operaIndex, levelIndex, props.chapterIndex)) {
        UserProgression.unlockAchievementById(8);
        UserNotifications.pushNotification("achievement", 8);
    }
}


</script>

<template>

    <Title>Ascolta alcuni dei principali <GlossaryWord :word-id="51">autoimprestiti</GlossaryWord>
        presenti nel <i class="italic">Barbiere di Siviglia</i> !</Title>
    <Autoimprestito :sources="autoimprestiti[0].sources" @autoimprestito-played="onAutoimprestitoPlayed">
        <template #title>
            Sinfonia
        </template>
        <template #description>
            Nata per <i class="italic"><GlossaryWord :word-id="47">Aureliano in Palmira</GlossaryWord></i> e passata così com’era al
            <i class="italic">Barbiere di Siviglia</i> ; Rossini l’aveva già riutilizzata con qualche modifica in
            <i class="italic"><GlossaryWord :word-id="48">Elisabetta regina d’Inghilterra</GlossaryWord></i>.
        </template>
        <template #video-title>
            Sinfonia
        </template>
        <template #video-subtitle>
            dal <i class="italic">Barbiere di Siviglia</i>
        </template>
    </Autoimprestito>

    <Autoimprestito :sources="autoimprestiti[1].sources" @autoimprestito-played="onAutoimprestitoPlayed">
        <template #title>
            Coro <i class="italic">Piano, pianissimo, senza parlar</i>
        </template>
        <template #description>
            Il Coro dell’Introduzione dell’Atto I viene dall’Introduzione dell’Atto II di <i class="italic"><GlossaryWord :word-id="49">Sigismondo</GlossaryWord></i>
            “In segreto a che ci chiama?”
        </template>
        <template #video-title>
            Coro Piano Pianissimo, senza parlar
        </template>
        <template #video-subtitle>
            dal <i class="italic">Barbiere di Siviglia</i>
        </template>
    </Autoimprestito>

    <Autoimprestito :sources="autoimprestiti[2].sources" @autoimprestito-played="onAutoimprestitoPlayed">
        <template #title>
            Cavatina del Conte <i class="italic">Ecco ridente in cielo</i>
        </template>
        <template #description>
            Il tema proviene dal Coro di <i class="italic">Aureliano in Palmira</i> “Sposa del grande Osiride” ed è stato utilizzato anche
            nella <i class="italic"><GlossaryWord :word-id="50">Cantata in onore del compleanno di Sua Maestà il Re Ferdinando IV.</GlossaryWord></i>
        </template>
        <template #video-title>
            Cavatina del Conte Ecco ridente in cielo
        </template>
        <template #video-subtitle>
            dal <i class="italic">Barbiere di Siviglia</i>
        </template>
    </Autoimprestito>

    <Title>
        Ascolta alcuni esempi di <GlossaryWord :word-id="52">crescendo rossiniano</GlossaryWord> nel <i class="italic">Barbiere di Siviglia.</i>
    </Title>
    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Crescendo_Sinfonia.mp3" @play="onAriaPlayed(7)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark"><i class="italic">Sinfonia</i></h3>
        </template>
        <template #description>
            <Textbox>
                La Sinfonia del <i class="italic">Barbiere di Siviglia</i> è famosissima. Composta originariamente per
                l’<i class="italic">Aureliano in Palmira</i> (1813),
                Rossini la riutilizzò per la sua opera più famosa.
            </Textbox>
        </template>
    </AriaPlayer>

    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Crescendo_Calunnia.mp3" @play="onAriaPlayed(8)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark"><i class="italic">La calunnia</i></h3>
        </template>
        <template #description>
            <Textbox>
                Basilio cerca di convincere Bartolo a calunniare Almaviva/Lindoro per allontanarlo da Rosina.
            </Textbox>
            <Textbox>
                Le sue parole minacciose si amalgamano con il crescendo delle note, che sembrano travolgere la vittima designata
                a cui non rimane altro che “crepar”.
            </Textbox>
        </template>
    </AriaPlayer>

    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Crescendo_Finale_I.mp3" @play="onAriaPlayed(9)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark"><i class="italic">Finale I</i></h3>
        </template>
        <template #description>
            <Textbox>
                Sono in scena: Rosina, il Conte, Bartolo, Basilio, Figaro e Berta, meravigliati per quanto è appena accaduto.
            </Textbox>
            <Textbox>
                Almaviva, travestito da soldato ubriaco, dopo una discussione con Bartolo,  ha rivelato la sua vera identità
                all’Ufficiale intervenuto per arrestarlo e l’esercito si è ritirato improvvisamente.
            </Textbox>
            <Textbox>
                Le voci dei personaggi si uniscono in un passaggio concitato (definito musicalmente una “stretta”)
                in cui rivelano che il loro cervello sembra rimbombare come dentro “un’orrida fucina”.
            </Textbox>
        </template>
    </AriaPlayer>

    <Title>
        L’<GlossaryWord :word-id="35">appendice</GlossaryWord> III dell’indice dei pezzi del <i class="italic">Barbiere di Siviglia.</i>
    </Title>
    <Textbox>
        L'appendice III riporta alcuni cambiamenti effettuati in occasione della ripresa del Barbiere al Teatro Contavalli
        di Bologna nell'estate del 1816.
    </Textbox>
    <Textbox>
        Le principali differenze rispetto al debutto romano sono la sostituzione della Cavatina N.11 “Contro un cor
        che accende amore” con l’aria “La mia pace, la mia calma” (N.11a) e il cambio di ruolo nel N.17 Aria Conte
        “Cessa di più resistere” che dal Conte passa a Rosina (N.17a).
    </Textbox>
    <Textbox>
        Questi cambiamenti sembrano collegati: un’aria meno impegnativa ne sostituisce una più complessa,
        mentre Rosina assume la grande aria virtuosistica del Conte per finire, più tradizionalmente, col trionfo della primadonna.
    </Textbox>
    <Textbox>
        A Bologna, il ruolo di Rosina fu ricoperto da Geltrude Righetti Giorgi, la stessa interprete del battesimo romano,
        e fu probabilmente lei stessa a chiedere a Rossini di appropriarsi dell'aria finale del tenore.
    </Textbox>

    <div class="appendix-trigger-container">
        <ContentAppendixCardTrigger/>
    </div>
    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>
.appendix-trigger-container{
    @apply px-5 w-full h-40 mb-6 pt-4 max-w-screen-sm mx-auto;
}
</style>
