<script setup>
import {defineModel, defineProps} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import OperaListItem from "@/components/content/operasList/OperaListItem.vue";
import AppAccordion from "@/components/utilities/AppAccordion.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const readChapters = defineModel()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];
</script>

<template>
    <div style="min-height: 75vh">
        <Title>Ecco tutte le opere composte da Rossini in ordine cronologico.</Title>
        <Textbox>
            Quelle evidenziate hanno un percorso di approfondimento dedicato dentro la piattaforma. Cliccale per iniziarlo!
        </Textbox>
        <AppAccordion aria-title="operas list accordion" theme="operasList" class="mt-6" pre-expanded>
            <template #trigger>Indice completo</template>
            <template #content>
                <ul>
                    <li>
                        <OperaListItem>
                            <template #index>1</template>
                            <template #opera-name>Demetrio e Polibio</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>2</template>
                            <template #opera-name>La cambiale di matrimonio</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>3</template>
                            <template #opera-name>L’equivoco stravagante</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>4</template>
                            <template #opera-name>L’inganno felice</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>5</template>
                            <template #opera-name>Ciro in Babilonia, ossia La caduta di Baldassarre</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>6</template>
                            <template #opera-name>La scala di seta</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>7</template>
                            <template #opera-name>La pietra del paragone</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>8</template>
                            <template #opera-name>L’occasione fa il ladro</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>9</template>
                            <template #opera-name>Il signor Bruschino, ossia il figlio per azzardo</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>10</template>
                            <template #opera-name>Tancredi</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>11</template>
                            <template #opera-name>L’Italiana in Algeri</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>12</template>
                            <template #opera-name>Aureliano in Palmira</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>13</template>
                            <template #opera-name>Il Turco in Italia</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>14</template>
                            <template #opera-name>Sigismondo</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>15</template>
                            <template #opera-name>Elisabetta regina d’Inghilterra</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>16</template>
                            <template #opera-name>Torvaldo e Dorliska</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem linked>
                            <template #index>17</template>
                            <template #opera-name>
                                <a href="/app/ilbarbieredisiviglia"><span class="linked-opera-title">Il barbiere di Siviglia</span></a>
                                (Almaviva, ossia L’inutile precauzione)
                            </template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>18</template>
                            <template #opera-name>La gazzetta</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>19</template>
                            <template #opera-name>Otello, ossia Il moro di Venezia</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>20</template>
                            <template #opera-name>La Cenerentola, ossia La bontà in trionfo</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>21</template>
                            <template #opera-name>La gazza ladra</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>22</template>
                            <template #opera-name>Armida</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>23</template>
                            <template #opera-name>Adelaide di Borgogna</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>24</template>
                            <template #opera-name>Mosè in Egitto</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>25</template>
                            <template #opera-name>Adina</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>26</template>
                            <template #opera-name>Ricciardo e Zoraide </template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>27</template>
                            <template #opera-name>Ermione</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>28</template>
                            <template #opera-name>Eduardo e Cristina</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>29</template>
                            <template #opera-name>La donna del lago</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>30</template>
                            <template #opera-name>Bianca e Falliero, ossia Il consiglio dei tre</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>31</template>
                            <template #opera-name>Maometto II </template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>32</template>
                            <template #opera-name>Matilde di Shabran, ossia Bellezza e cuor di ferro</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>33</template>
                            <template #opera-name>Zelmira</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>34</template>
                            <template #opera-name>Semiramide</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>35</template>
                            <template #opera-name>Il viaggio a Reims, ossia L’albergo del giglio d’oro</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>36</template>
                            <template #opera-name>Le Siège de Corinthe</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>37</template>
                            <template #opera-name>Moïse et Pharaon, ou Le passage de la Mer Rouge</template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>38</template>
                            <template #opera-name>Le Comte Ory </template>
                        </OperaListItem>
                    </li>
                    <li>
                        <OperaListItem>
                            <template #index>39</template>
                            <template #opera-name>Guillaume Tell</template>
                        </OperaListItem>
                    </li>
                </ul>
            </template>
        </AppAccordion>
        <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
    </div>
</template>

<style scoped>
.linked-opera-title{
    @apply text-celeste font-bold underline;
}
</style>
