<script setup>
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";

import {defineModel, defineProps} from "vue";
import ContentImageBox from "@/components/content/ContentImageBox.vue";
import ContentFunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()
</script>

<template>
    <Title>Una commedia in due <GlossaryWord :wordId="1">atti</GlossaryWord> composta da Gioachino Rossini.
    </Title>
    <Textbox>
        Hai presente quei film pieni di personaggi bizzarri che danno vita a situazioni talvolta assurde e spesso comiche?<br>
        Come probabilmente sai, si chiamano “commedie”.

    </Textbox>
    <Textbox>
        Ecco, anche nel mondo dell’<GlossaryWord :wordId="2">opera</GlossaryWord> esistono.<br>
        E <i class="italic">Il barbiere di Siviglia</i> di
        <GlossaryWord :wordId="3">Gioachino Rossini</GlossaryWord> ne è un ottimo esempio.
    </Textbox>

    <ContentImageBox>
        <img src="/assets/operas/barbieredisiviglia/level-0/content-image-0-O0L0C0.png" alt="" />
    </ContentImageBox>

    <Title>Basata sul libretto scritto da Cesare Sterbini.</Title>

    <Textbox>
        Se Rossini ha composto la musica, chi ha pensato a scrivere il “copione” per i cantanti?
        <GlossaryWord :wordId="4">Cesare Sterbini</GlossaryWord>.
    </Textbox>
    <Textbox>
        Non è però partito da zero, inventando tutto di sana pianta, ma da una commedia teatrale che esisteva già:
        <GlossaryWord :word-id="5"><i class="italic">Le barbier de Séville ou La précaution inutile</i></GlossaryWord> di Pierre-Augustin Caron de Beaumarchais.
    </Textbox>
    <Textbox>
        Come quando da un libro viene tratto un film,
        Sterbini ha trasformato quella commedia teatrale in un <GlossaryWord :word-id="6">libretto</GlossaryWord> d'opera,
        di cui Rossini ha poi composto la musica.
    </Textbox>

    <ContentImageBox align="right">
        <img src="/assets/operas/barbieredisiviglia/level-0/content-image-1-O0L0C0.png" alt="" />
    </ContentImageBox>

    <Title>Fa ridere ma fa anche riflettere.</Title>

    <Textbox>
        <i class="italic">Il barbiere di Siviglia</i> è un’opera vivace.<br>
        I suoi personaggi sono stravaganti e le sue scene esilaranti.
    </Textbox>
    <Textbox>
        Viene infatti definita “<GlossaryWord :word-id="7">opera buffa</GlossaryWord>”, ma nasconde anche una pungente satira,
        che prende di mira la società del tempo.
    </Textbox>
    <Textbox>
        Quindi sì, fa ridere, ma fa anche riflettere.
    </Textbox>

    <FunFact theme="green">
        <template #counter>CURIOSITÀ (1 su 3)</template>
        <template #title>Un debutto… con i fischi!</template>
        <template #funcfact-p>
            <ContentFunfactParagraph>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-0/funfact-image-0-O0L0C0.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Il debutto a teatro del <i class="italic">Barbiere di Siviglia</i> avvenne il 20 febbraio 1816 sul palco del
                        <GlossaryWord theme="white" :word-id="8">Teatro Argentina</GlossaryWord> di Roma e non andò nel migliore dei modi.
                    </p>
                    <p>
                        Come scrisse Rossini stesso in una lettera indirizzata alla madre:
                        “Ieri Sera Andò in Scena la mia Opera e fu Solennemente fischiata”.
                    </p>
                </template>
            </ContentFunfactParagraph>
            <Textbox>
                Questo però non lo scoraggiò, perché era consapevole di aver scritto una “Musica bella assai”
                che molto presto sarebbe stata apprezzata.
            </Textbox>
            <Textbox>
                A distanza di pochi giorni infatti, l’opera riscosse il successo che meritava e,
                sempre alla madre, Rossini raccontò in una nuova lettera di “aplausi di un
                genere tutto novo e che mi fece Piangere di soddisfazione”.
            </Textbox>
        </template>
    </FunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>
</style>
