export const achievementsMap = {
    1: {
        title: "Storyteller - Barbiere di Siviglia",
        description: "Guarda il video animato che racconta la storia del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    2: {
        title: "Mazziere - Barbiere di Siviglia",
        description: "Scopri tutte le card interattive dei personaggi del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    3: {
        title: "DJ - Barbiere di Siviglia",
        description: "Ascolta tutti gli estratti dei brani principali del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    4: {
        title: "Perfezionista - Barbiere di Siviglia",
        description: "Scopri tutte le parole del glossario del livello 2!",
    },
    5: {
        title: "Melomane - Barbiere di Siviglia",
        description: "Scarica lo schema musicale del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    9:{
        title: "Meteorologo",
        description: "Ascolta il <i class='italic'>Temporale</i> del <i class='italic'>Barbiere di Siviglia</i> !",
    },
    6: {
        title: "Portalettere",
        description: "Scarica le lettere di Dall’Argine e Rossini!",
    },
    7: {
        title: "Insaziabile",
        description: "Scarica la rilettura critica di Alberto Zedda!",
    },
    8: {
        title: "Compositore",
        description: "Ascolta tutti gli esempi di autoimprestiti e crescendo rossiniano!",
    },
    10: {
        title: "Collezionista",
        description: "Scopri tutte le card interattive con le figure chiave della vita di Rossini!",
    },
    11: {
        title: "Storyteller - Cenerentola",
        description: "Guarda il video animato che racconta la storia della <i>Cenerentola</i> !",
    },
    12: {
        title: "Mazziere - Cenerentola",
        description: "Scopri tutte le card interattive dei personaggi della <i>Cenerentola</i> !",
    },
    13: {
        title: "DJ - Cenerentola",
        description: "Ascolta tutti gli estratti dei brani principali della <i>Cenerentola</i> !",
    },
    14:{
        title : "Melomane - Cenerentola",
        description : "Scarica lo schema musicale della <i>Cenerentola</i> !"
    },
    15:{
        title : "Perfezionista - Cenerentola",
        description : "Scopri tutte le parole del glossario del livello 2!"
    },
    16:{
        title : "",
        description : ""
    }
}

export const achievementProfilePictures = {
    cat: "/assets/user-profile-img-temp.jpg",
    default: {
        default : "/assets/avatars/default-avatar.png"
    },
    1 : {
        locked : "/assets/avatars/avatar-01-locked.png",
        default : "/assets/avatars/avatar-01.png",
    },
    2 : {
        locked : "/assets/avatars/avatar-02-locked.png",
        default : "/assets/avatars/avatar-02.png",
    },
    3 : {
        locked : "/assets/avatars/avatar-03-locked.png",
        default : "/assets/avatars/avatar-03.png",
    },
    4 : {
        locked : "/assets/avatars/avatar-04-locked.png",
        default : "/assets/avatars/avatar-04.png",
    },
    5 : {
        locked : "/assets/avatars/avatar-05-locked.png",
        default : "/assets/avatars/avatar-05.png",
    },
    9 : {
        locked : "/assets/avatars/avatar-06-locked.png",
        default : "/assets/avatars/avatar-06.png",
    },
    6 : {
        locked : "/assets/avatars/avatar-07-locked.png",
        default : "/assets/avatars/avatar-07.png",
    },
    7 : {
        locked : "/assets/avatars/avatar-08-locked.png",
        default : "/assets/avatars/avatar-08.png",
    },
    8 : {
        locked : "/assets/avatars/avatar-09-locked.png",
        default : "/assets/avatars/avatar-09.png",
    },
    10 : {
        locked : "/assets/avatars/avatar-10-locked.png",
        default : "/assets/avatars/avatar-10.png",
    },
    11 : {
        locked : "/assets/avatars/avatar-10-locked.png",
        default : "/assets/avatars/avatar-10.png",
    },
    12 : {
        locked : "/assets/avatars/avatar-10-locked.png",
        default : "/assets/avatars/avatar-10.png",
    },
    13 : {
        locked : "/assets/avatars/avatar-10-locked.png",
        default : "/assets/avatars/avatar-10.png",
    },
    14 : {
        locked : "/assets/avatars/avatar-10-locked.png",
        default : "/assets/avatars/avatar-10.png",
    },
    15 : {
        locked : "/assets/avatars/avatar-10-locked.png",
        default : "/assets/avatars/avatar-10.png",
    },
    16 : {
        locked : "/assets/avatars/avatar-10-locked.png",
        default : "/assets/avatars/avatar-10.png",
    }
}
