<script setup>
import IconAchievements from "@/components/icons/IconAchievements.vue";
import IconTime from "@/components/icons/IconTime.vue";
import IconGlossary from "@/components/icons/IconGlossary.vue";
import IconGlossaryResume from "@/components/icons/IconGlossaryResume.vue";
import IconAchievementsResume from "@/components/icons/IconAchievementsResume.vue";

const props = defineProps({
    data: { type: Object, required: true },
    theme : {type: String, required: false, default: "warmLight"},
});

const themes = {
    warmLight : {
        background : "bg-warm-light",
        fontColor : "text-green-light"
    },
    greenish : {
        background: "bg-greenish",
        fontColor : "text-bordeaux-light"
    }
}

</script>

<template>
    <div class="rounded-2xl border-dashed border-green-light border-2 mx-10 px-10 py-4" :class="themes[theme].background">
        <div class="stats-item">
            <div class="stat-item-icon">
                <IconAchievementsResume class="h-full"
                                  :complete="data.progression.achievements.length === data.opera.achievements.length" />
            </div>
            <p class="mb-0" :class="themes[theme].fontColor">
                <span>{{ data.progression.achievements.length }}</span>
                /
                <span>{{ data.opera.achievements.length }}</span>
                achievements
            </p>
        </div>
        <div class="stats-item">
            <div class="stat-item-icon">
                <IconGlossaryResume class="h-full"
                              :complete="data.progression.glossaryWords.length === data.opera.glossaryWords.length" />
            </div>
            <p class="mb-0" :class="themes[theme].fontColor">
                <span>{{ data.progression.glossaryWords.length }}</span>
                /
                <span>{{ data.opera.glossaryWords.length }}</span>
                termini glossario
            </p>
        </div>
    </div>
</template>

<style scoped>
.stats-item {
    @apply flex items-center mb-4 font-medium;
}
.stat-item-icon {
    @apply w-8 h-8 mr-1;
}
</style>
