<script setup>
import {defineModel, defineProps} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import NextCapitoloCard from "@/components/content/ContentNextChapterCard.vue";
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import DirectionTrigger from "@/components/content/directionNotes/DirectionTrigger.vue";
import RossiniCharacterTrigger from "@/components/content/rossiniCharacters/RossiniCharacterTrigger.vue";
import SlidesCardSlide from "@/components/utilities/SlidesCardSlide.vue";

const props = defineProps({
  chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];

const readChapters = defineModel()

function checkAllCharactersUnlockedAchievement(characterId){
    if (!UserProgression.isAchievementUnlocked(10)
        && UserProgression.allCharactersUnlocked(operaIndex, levelIndex, chapterIndex)) {
        UserProgression.unlockAchievementById(10);
        UserNotifications.pushNotification("achievement", 10);
    }
}


</script>

<template>
  <Title>
      Chi sono stati i personaggi chiave nella vita di Rossini?
  </Title>
  <Textbox>
      Clicca sulle card e scopri di più su ognuno di loro!
  </Textbox>
  <div class="characters-cards-container">
    <RossiniCharacterTrigger v-for="character in currentChapter.characters" :character="character"
                             @revealed="checkAllCharactersUnlockedAchievement">
      <template #slides>
        <SlidesCardSlide :background-src="character.cardPic" bottom-notes :theme="AppContent.operas[operaIndex].theme">
          <template #bottom-notes-title>{{character.role}}</template>
          <template #bottom-notes>
            <p v-html="character.description"/>
          </template>
        </SlidesCardSlide>
      </template>
    </RossiniCharacterTrigger>
  </div>
  <NextCapitoloCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>
.characters-cards-container{
  @apply grid grid-cols-3 mx-4 gap-4 mb-8 max-w-screen-sm mx-auto px-4
}
</style>
