<script setup>
import LayoutPrimaryPage from "@/components/home/LayoutPrimaryPage.vue";
import OperaItem from "@/components/operas/OperaItem.vue";
import OperasNav from "@/components/operas/OperasNav.vue";
import {computed, onMounted, ref} from "vue";
import {AppContent} from "@/content/data/content.js";
import {useAppStateStore} from "@/stores/appState.js";

const currentOperaIndex = ref(0)

</script>

<template>
    <LayoutPrimaryPage :has-halftone-variant="true" v-model="AppContent.operas[currentOperaIndex].theme">
        <div class="operas">
            <div class="opera-placer">
                <template
                    v-for="({ title, selectorImg, selectorDecos, theme }, index) in AppContent.operas"
                    :key="index"
                >
                    <OperaItem
                        v-show="currentOperaIndex === index"
                        :decos="selectorDecos"
                        :img="selectorImg"
                        :title="title"
                        :theme="theme">
                    </OperaItem>
                </template>
            </div>

            <div class="operas-nav-container">
                <OperasNav v-model="currentOperaIndex"/>
            </div>
        </div>
    </LayoutPrimaryPage>
</template>

<style scoped>

.operas {
    @apply absolute h-full w-full top-0 left-0 flex flex-col;
    /*
    grid-template-columns: 1fr;
    grid-template-rows: 0.7fr 0.3fr;
     */
}

.operas-nav-container {
    @apply h-[40vh] absolute bottom-0 inset-x-0;
}

.opera-placer {
    @apply h-full top-0 inset-x-0 absolute;
}
</style>
