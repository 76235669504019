<script setup>
import {defineModel, defineProps} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import Video from "@/components/content/ContentVideo.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import Title from "@/components/content/ContentTitle.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";
import Word from "@/components/glossary/GlossaryWordTooltip.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import ContentPersonaggiCardsReveals from "@/components/content/characters/ContentCharactersCardsReveals.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];

const readChapters = defineModel()

function checkAllCharactersUnlockedAchievement(characterId){
    if (!UserProgression.isAchievementUnlocked(12)
        && UserProgression.allCharactersUnlocked(operaIndex, levelIndex, chapterIndex)) {
        UserProgression.unlockAchievementById(12);
        UserNotifications.pushNotification("achievement", 12);
    }
}

function unlockVideoPlayedAchievement(){
    if (!UserProgression.isAchievementUnlocked(11)) {
        UserProgression.unlockAchievementById(11);
        UserNotifications.pushNotification("achievement", 11);
    }
}
</script>

<template>
    <Title>Di che cosa parla <i>Cenerentola</i>?</Title>
    <Textbox>
        Guarda il video per scoprirlo!
    </Textbox>
    <Video
        width="480"
        height="270"
        controls
        controlslist="nodownload noremoteplayback noplaybackrate"
        poster="/assets/operas/cenerentola/level-0/thumbnail-motion.png"
        @play="unlockVideoPlayedAchievement"
    >
        <template #sources>
            <source src="https://vz-c01ac8fb-8ab.b-cdn.net/9587137d-bfc4-4f96-8316-6b225b878d59/original" type="video/mp4" />
        </template>
    </Video>
    <FunFact>
        <template #title>
            Che fine ha fatto la celebre scarpetta di cristallo?
        </template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/cenerentola/level-0/funfact-image-1-O2L0C1.png" alt="fun-fact related image"/>
                </template>
                <template  #image-description>
                    <p>
                        Nella Cenerentola di <Word :word-id="3" theme="white">Rossini</Word>,  non troviamo la fata madrina, il cui ruolo viene invece assunto
                        da Alidoro, filosofo e precettore del principe, e nemmeno la celebre scarpetta di cristallo.
                    </p>
                    <p>
                        Quest’ultima, come racconta <Word :word-id="25" theme="white">Ferretti</Word> stesso in una lettera, venne sostituita da uno “smaniglio”,
                        un bracciale, per una “necessità nelle scene del <Word :word-id="26" theme="white">Teatro Valle</Word>”.
                    </p>
                </template>
            </FunfactParagraph>
            <Textbox>
                Mostrare il piede di una signora su un palcoscenico della Roma dei papi sarebbe stato mal visto!
                Inoltre portare in scena un bracciale era certamente più comodo.
            </Textbox>
        </template>
    </FunFact>
    <Title>Chi sono i personaggi di <i>Cenerentola</i>?</Title>
    <Textbox>
        Clicca sulle card per scoprire
        <img src="/assets/icons/character-card/Icona_Potere.png" class="inline-icon"  alt="character trait icon"> potere principale,
        <img src="/assets/icons/character-card/Icona_Caratteristiche.png" class="inline-icon" alt="character trait icon"> caratteristiche,
        <img src="/assets/icons/character-card/Icona_Punti_Deboli.png" class="inline-icon" alt="character trait icon"> punti deboli,
        <img src="/assets/icons/character-card/Icona_Arma_Segreta.png" class="inline-icon" alt="character trait icon"> arma segreta
        e <img src="/assets/icons/character-card/Icona_Obiettivo.png" class="inline-icon" alt="character trait icon"> obiettivo
        di ogni personaggio!
    </Textbox>
    <ContentPersonaggiCardsReveals @character-unlocked="checkAllCharactersUnlockedAchievement"  :characters="currentChapter.characters"/>
    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
