<script setup>
import {ref, watch} from "vue";

const props = defineProps({
    variant: { type: String, required: true },
    theme: {type : String, required : true}
});

const variants = {
    warmLight : {
        locked: "/assets/icons/icon-story-disable.svg",
        unlocked: "/assets/icons/icon-story-active-warm-light.svg",
        current: "/assets/icons/icon-story-current-warm-light.svg",
        read: "/assets/icons/icon-story-active-warm-light.svg",
        mastered: "/assets/icons/icon-story-master.svg"
    },
    greenish : {
        locked: "/assets/icons/icon-story-disable.svg",
        unlocked: "/assets/icons/icon-story-active-greenish.svg",
        current: "/assets/icons/icon-story-current-greenish.svg",
        read: "/assets/icons/icon-story-active-greenish.svg",
        mastered: "/assets/icons/icon-story-master.svg"
    },
    blue : {
        locked: "/assets/icons/icon-story-disable.svg",
        unlocked: "/assets/icons/icon-story-active-warm-light.svg",
        current: "/assets/icons/icon-story-current-warm-light.svg",
        read: "/assets/icons/icon-story-active-warm-light.svg",
        mastered: "/assets/icons/icon-story-master.svg"
    }
};

const variant = ref(props.variant);

watch(() => props.variant, (newVal) => {
    variant.value = newVal
})

</script>

<template>
    <img :src="variants[theme][variant]" alt="story icon link" />
</template>
