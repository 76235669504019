export const charactersMap = {
    1 : {
        name : "Conte D'Almaviva",
        mainStrength : "carisma",
        traits : "fascino, gentilezza",
        weaknesses : "mascheramento, travestimento",
        goal : "Conquistare il cuore di Rosina",
        weapon : "ricchezza",
        cardPic : "/assets/characters/almaviva-card-picture.png",
        cardPicMini : "/assets/characters/almaviva-card-picture-mini.png",
        profilePic : "/assets/characters/character-almaviva-profile.png",
        voiceType : "Tenore",
    },
    2 : {
        name : "Bartolo",
        mainStrength : "dispotismo",
        traits : "gelosia",
        weaknesses : "avidità, sospettosità",
        goal : "Sposare Rosina per aumentare il proprio patrimonio",
        weapon : "status sociale",
        cardPic : "/assets/characters/bartolo-card-picture.png",
        cardPicMini : "/assets/characters/bartolo-card-picture-mini.png",
        profilePic : "/assets/characters/character-bartolo-profile.png",
        voiceType : "Basso",
    },
    3 : {
        name : "Rosina",
        mainStrength : "grinta, tenerezza",
        traits : "intelligenza, vivacità",
        weaknesses : "orgoglio, capricci",
        goal : "Sfuggire al controllo di Bartolo per seguire il proprio cuore",
        weapon : "intraprendenza",
        cardPic : "/assets/characters/rosina-card-picture.png",
        cardPicMini : "/assets/characters/rosina-card-picture-mini.png",
        profilePic : "/assets/characters/character-rosina-profile.png",
        voiceType : "Mezzosoprano",
    },
    4 : {
        name : "Figaro",
        mainStrength : "spirito d’iniziativa, intraprendenza",
        traits : "amore per la vita",
        weaknesses : "egocentrismo, inconcludenza",
        goal : "Aiutare il Conte d'Almaviva a conquistare Rosina",
        weapon : "spigliatezza, disinvoltura",
        cardPic : "/assets/characters/figaro-card-picture.png",
        cardPicMini : "/assets/characters/figaro-card-picture-mini.png",
        profilePic : "/assets/characters/character-figaro-profile.png",
        voiceType : "Baritono",
    },
    5 : {
        name : "Basilio",
        mainStrength : "manipolazione",
        traits : "ipocrisia, avidità",
        weaknesses : "mancanza di dignità, servilismo",
        goal : "Mantenere il proprio status",
        weapon : "compiacenza, adulazione",
        cardPic : "/assets/characters/basilio-card-picture.png",
        cardPicMini : "/assets/characters/basilio-card-picture-mini.png",
        profilePic : "/assets/characters/character-basilio-profile.png",
        voiceType : "Basso",
    },
    6 : {
        name : "Berta",
        mainStrength : "senso dell'umorismo",
        traits : "spirito d'iniziativa, umorismo",
        weaknesses : "rimpianto, nostalgia",
        goal : "Rivivere i sentimenti d’amore",
        weapon : "ironia",
        cardPic : "/assets/characters/berta-card-picture.png",
        cardPicMini : "/assets/characters/berta-card-picture-mini.png",
        profilePic : "/assets/characters/character-berta-profile.png",
        voiceType : "Soprano",
    },
    7 : {
        title : "Anna Guidarini",
        cardPic : "/assets/operas/rossini/characters/anna_guidarini.png",
        role : "Madre di Rossini",
        description : "Modista di professione, ha avuto una breve carriera" +
            " come cantante nei teatri marchigiani e dell'Emilia Romagna."
    },
    8 : {
        title : "Giuseppe Rossini",
        cardPic : "/assets/operas/rossini/characters/giuseppe_rossini.png",
        role : "Padre di Rossini",
        description : "Originario di Lugo di Romagna, suonatore di tromba e corno nella banda cittadina e nei teatri."
    },
    9 : {
        title : "Padre Stanislao Mattei",
        cardPic : "/assets/operas/rossini/characters/padre_stanislao_mattei.png",
        role : "Insegnante di musica",
        description : "Ha insegnato per quattro anni violoncello, pianoforte e contrappunto a Rossini" +
            " al Liceo Musicale di Bologna."
    },
    10 : {
        title : "Domenico Barbaja",
        cardPic : "/assets/operas/rossini/characters/domenico_barbaja.png",
        role : "Impresario teatrale",
        description : "Ha avuto un ruolo chiave nella carriera di Rossini, portandolo prima a Napoli," +
            " dove è rimasto dal 1815 al 1822, e poi a Vienna. "
    },
    11 : {
        title : "Isabella Colbran",
        cardPic : "/assets/operas/rossini/characters/isabella_colbran.png",
        role : "Prima moglie di Rossini",
        description : "Descrizione: Cantante e compositrice spagnola, fu la prima moglie di Rossini," +
            " con cui si sposò nel 1822 e da cui si separò ufficialmente nel 1837."
    },
    12 : {
        title : "Olympe Pélissier",
        cardPic : "/assets/operas/rossini/characters/olympie_pélissier.png",
        role : "Seconda moglie di Rossini",
        description : "Modella del pittore Horace Vernet, è diventata la seconda moglie di Rossini nel 1846 ed è stata" +
            " al suo fianco durante gli ultimi anni della sua vita."
    },
    13 : {
        name : "Angelina",
        mainStrength : "bontà e innocenza",
        traits : "bellezza, generosità",
        weaknesses : "remissività, malinconia",
        goal : "partecipare al ballo e sposare Ramiro per amore",
        weapon : "semplicità",
        cardPic : "/assets/characters/angelina-card-picture.png",
        cardPicMini : "/assets/characters/angelina-card-picture-mini.png",
        profilePic : "/assets/characters/character--profile.png",
        voiceType : "",
    },
    14 : {
        name : "Don Magnifico",
        mainStrength : "autorità genitoriale",
        traits : "prepotenza, ottusità, megalomania, arroganza",
        weaknesses : "vanità, povertà, arrivismo",
        goal : "far sposare le figlie con un principe per liberarsi dai debiti",
        weapon : "vitalità",
        cardPic : "/assets/characters/don-magnifico-card-picture.png",
        cardPicMini : "/assets/characters/don-magnifico-card-picture-mini.png",
        profilePic : "/assets/characters/character--profile.png",
        voiceType : "",
    },
    15 : {
        name : "Clorinda e Tisbe",
        mainStrength : "posizione privilegiata in famiglia",
        traits : "prepotenza, superbia",
        weaknesses : "stoltezza, perfidia",
        goal : "sposare il principe per soddisfare la loro vanità",
        weapon : "appoggio del padre in ogni circostanza",
        cardPic : "/assets/characters/clorinda-tisbe-card-picture.png",
        cardPicMini : "/assets/characters/clorinda-tisbe-card-picture-mini.png",
        profilePic : "/assets/characters/character--profile.png",
        voiceType : "",
    },
    16 : {
        name : "Ramiro",
        mainStrength : "regalità",
        traits : "nobiltà d’animo, intraprendenza",
        weaknesses : "titolo di principe che rende difficile trovare il vero amore",
        goal : "trovare una moglie bella e saggia, che lo ami davvero",
        weapon : "arguzia, rispetto",
        cardPic : "/assets/characters/ramiro-card-picture.png",
        cardPicMini : "/assets/characters/ramiro-card-picture-mini.png",
        profilePic : "/assets/characters/character--profile.png",
        voiceType : "",
    },
    17 : {
        name : "Dandini",
        mainStrength : "fedeltà al principe",
        traits : "istrionico, esuberante, gaudente, sarcastico",
        weaknesses : "goffaggine, gola, vanità",
        goal : "aiutare il principe a trovare la giusta sposa",
        weapon : "trasformismo",
        cardPic : "/assets/characters/dandini-card-picture.png",
        cardPicMini : "/assets/characters/dandini-card-picture-mini.png",
        profilePic : "/assets/characters/character--profile.png",
        voiceType : "",
    },
    18 : {
        name : "Alidoro",
        mainStrength : "carisma",
        traits : "saggezza, razionalità",
        weaknesses : "eccessiva segretezza",
        goal : "mostrare a don Ramiro che Angelina-Cenerentola è la sposa giusta per lui",
        weapon : "trasformismo",
        cardPic : "/assets/characters/alidoro-card-picture.png",
        cardPicMini : "/assets/characters/alidoro-card-picture-mini.png",
        profilePic : "/assets/characters/character--profile.png",
        voiceType : "",
    }
}
