<script setup>
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {computed, ref} from "vue";
import AppDialog from "@/components/utilities/AppDialog.vue";
import SlidesCard from "@/components/utilities/SlidesCard.vue";

const props = defineProps({
    character : { type : Object , required : true},
})

const emits = defineEmits(['revealed'])

const UserProgression = useUserProgressionStore()
const isRevealed = computed(() => UserProgression.isCharacterRevealed(props.character.id));

const dialog = ref(null);

const cardBg = `url(${props.character.cardPic})`

function setRevealed() {
    UserProgression.revealCharacterById(props.character.id);
    dialog.value.openDialog()
    emits("revealed")
}

</script>

<template>
    <div
        class="rossini-card-trigger"
        :style="isRevealed ? `background-image: ${character.cardPic}` : ''"
        @click.self="setRevealed()"
        :class="isRevealed? 'revealed' : ''"
    >
        <!-- reveal trigger content -->
        <p class="rossini-card-trigger-text">{{character.title}}</p>
        <!-- dialog shown on trigger's click -->
        <AppDialog ref="dialog" class="h-144">
            <!-- card  -->
            <SlidesCard @close-card="dialog.closeDialog()" :controls-enabled="false">
                <template #slides>
                    <slot name="slides"></slot>
                </template>
                <template #title>{{character.title}}</template>
            </SlidesCard>
        </AppDialog>
    </div>
</template>

<style scoped>
.rossini-card-trigger {
    @apply bg-black text-gold w-full h-full rounded-2xl overflow-clip;

    aspect-ratio: 2/3;
    background-image: url("/assets/curtains/curtain-bg-left-new.png");
    background-size: cover;
    background-repeat: no-repeat;
}
.rossini-card-trigger.revealed {
    /* @apply bg-green ; */
    background-image: v-bind(cardBg);
    background-position: center;
    background-size: cover;
}

.rossini-card-trigger-text {
    @apply px-5 pt-5 font-bold text-sm uppercase max-w-44;
    /* font-size: 0.8rem; */
}
</style>
