<script setup>
import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
import {onMounted, ref} from "vue";

const props = defineProps({
    bottomNotes : {type : Boolean, required : false, default : false},
    backgroundSrc : {type : String, required : true},
    theme : {type : String, required : false, default : "warmLight"}
})

const themes = {
    warmLight : {
        background : "bg-warm-light"
    },
    greenish : {
        background: "bg-greenish"
    }
}

gsap.registerPlugin(Draggable)

const bottomNote = ref(null)
const bottomNoteContainer = ref(null)

onMounted(() => {
    Draggable.create(bottomNote.value, {
        type : "y",
        bounds : bottomNoteContainer.value,
        allowNativeTouchScrolling: false,
    })

})
</script>

<template>
<div class="slide">
    <img class="slide-background" :src="backgroundSrc" alt="card-slide-background">
    <slot name="content"></slot>
    <div v-if="bottomNotes" ref="bottomNoteContainer" class="bottom-note-container">
        <div ref="bottomNote" class="bottom-note" :class="themes[theme].background">
            <img class="mt-3 mb-1.5 w-11" src="/assets/icons/draggable-hint.png" alt="draggable element hint align-center">
            <h4 class="font-medium text-xs mb-2.5"><slot name="bottom-notes-title"></slot></h4>
            <div class="mx-5">
                <slot name="bottom-notes"></slot>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.slide{
    @apply absolute h-full w-full;
}
.slide-background{
    @apply w-full object-center object-cover absolute bottom-0 z-0;
}

.bottom-note-container{
    @apply absolute h-184 inset-x-10 -bottom-88;
}

.bottom-note{
    @apply absolute h-100 w-full bottom-0 rounded-t-3xl z-40 flex flex-col items-center;
}
</style>
