import { defineStore } from "pinia";

export const useAppStateStore = defineStore("appState", {
    state: () => ({
        currentOverlay: "none",
        currentTheme: "warmLight",
    }),
    actions: {
        /** UI */
        setPrimaryVariationTheme(operaTheme){
            this.currentTheme = operaTheme + "Home"
        },
        setPrimaryTheme(operaTheme) {
            this.currentTheme = operaTheme;
        },
        setSecondaryTheme() {
            this.currentTheme = "green";
        },
        /** OVERLAYS */
        closeOverlays() {
            this.currentOverlay = "none";
        },
        // menu
        openMenu() {
            this.currentOverlay = "menu";
        },
        toggleMenu() {
            this.currentOverlay === "menu"
                ? this.closeOverlays()
                : this.openMenu();
        },
        // notifications
        openNotifications() {
            this.currentOverlay = "notifications";
        },
        toggleNotifications() {
            this.currentOverlay === "notifications"
                ? this.closeOverlays()
                : this.openNotifications();
        },
    },
    persist: true,
});

export const useDeviceStateStore = defineStore("deviceState", {
    state: () => ({
        hasPointer: "none",
        isTouch: "none",
    }),
    actions: {
        updateState() {
            this.hasPointer = window.matchMedia("(pointer: fine)").matches;
            this.isTouch = window.matchMedia("(pointer: coarse)").matches;
        },
        getPointerState() {
            return this.hasPointer;
        },
        getTouchState() {
            return this.isTouch;
        },
    },
    persist: true,
});
