<script setup>
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {extractLevelRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {defineModel, onMounted, ref} from "vue";

const props = defineProps({
    chapterIndex : {type: Number, required: true},
})

const UserProgression = useUserProgressionStore()

const levelRouteInfo = extractLevelRouteInfo()

const operaIndex = levelRouteInfo.operaInfo.index;
const levelIndex = levelRouteInfo.levelInfo.index;

const readChapters = defineModel()

const canProceed = ref(false)

function onCompleted(){
    readChapters.value = props.chapterIndex + 1;
}

const isMinigameCompleted = () => localStorage.getItem('minigame-completed') === "true"

onMounted(() => {
    window.addEventListener('message', (event) => {
        if(event.data.source === "rof-minigame") {
            canProceed.value = event.data.content === "minigame-completed"
            UserProgression.setChapterAsRead(operaIndex, levelIndex, props.chapterIndex)
        }
    });
})
</script>

<template>
    <div class="minigame-container z-0 relative justify-center">
        <iframe src="/games/minigame/index.html?level=cen_2" class="grow"/>

        <div class="minigame-complete-button-container">
            <button v-if="canProceed" @click="onCompleted" class="minigame-complete-button">
                CONTINUA
            </button>
        </div>

    </div>
</template>

<style scoped>

</style>
