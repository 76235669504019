<script setup>
import {defineModel, defineProps, watch} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import Title from "@/components/content/ContentTitle.vue";
import CharacterVoicesList from "@/components/content/characters/CharacterVoicesList.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import CharacterVoiceCard from "@/components/content/characters/ContentCharacterVoiceCard.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import CharacterParagraph from "@/components/content/CharacterParagraph.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import Word from "@/components/glossary/GlossaryWordTooltip.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];

watch(UserProgression.glossaryWords, () => {
    if(!UserProgression.isAchievementUnlocked(15) &&
        UserProgression.allLevelGlossaryWordFound(operaIndex, levelIndex)){
        UserProgression.unlockAchievementById(15);
        UserNotifications.pushNotification("achievement", 15);
    }
})
</script>

<template>
    <Title>Quali sono i ruoli vocali dei personaggi più importanti della <i class="italic">Cenerentola</i> ?</Title>

    <CharacterVoicesList>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/operas/cenerentola/level-1/characters/ramiro-profile.png" alt="character profile picture">
            </template>
            <template #title>Ramiro</template>
            <template #subtitle><GlossaryWord :word-id="15">Tenore</GlossaryWord></template>
        </CharacterVoiceCard>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/operas/cenerentola/level-1/characters/dandini-profile.png" alt="character profile picture">
            </template>
            <template #title>Dandini</template>
            <template #subtitle><GlossaryWord :word-id="16">Baritono</GlossaryWord></template>
        </CharacterVoiceCard>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/operas/cenerentola/level-1/characters/don-magnifico-profile.png" alt="character profile picture">
            </template>
            <template #title>Don Magnifico</template>
            <template #subtitle><GlossaryWord :word-id="18">Basso</GlossaryWord></template>
        </CharacterVoiceCard>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/operas/cenerentola/level-1/characters/clorinda-profile.png" alt="character profile picture">
            </template>
            <template #title>Clorinda</template>
            <template #subtitle><GlossaryWord :word-id="19">Soprano</GlossaryWord></template>
        </CharacterVoiceCard>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/operas/cenerentola/level-1/characters/tisbe-profile.png" alt="character profile picture">
            </template>
            <template #title>Tisbe</template>
            <template #subtitle><GlossaryWord :word-id="17">Mezzosoprano</GlossaryWord></template>
        </CharacterVoiceCard>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/operas/cenerentola/level-1/characters/angelina-profile.png" alt="character profile picture">
            </template>
            <template #title>Angelina</template>
            <template #subtitle><GlossaryWord :word-id="17">Mezzosoprano</GlossaryWord></template>
        </CharacterVoiceCard>
        <CharacterVoiceCard>
            <template #profile-picture>
                <img src="/assets/operas/cenerentola/level-1/characters/alidoro-profile.png" alt="character profile picture">
            </template>
            <template #title>Alidoro</template>
            <template #subtitle><GlossaryWord :word-id="18">Basso</GlossaryWord></template>
        </CharacterVoiceCard>
    </CharacterVoicesList>

    <CharacterParagraph>
        <template #title>Don Ramiro</template>
        <template #image>
            <img src="/assets/operas/cenerentola/level-1/characters/ramiro-stand.png" alt="character picture"/>
        </template>
        <template #image-description>
            <p>
                Dopo l'esortazione del fido Alidoro a seguire i consigli del cuore, Ramiro affronta l'appassionata Aria
                tripartita “Sì, ritrovarla io giuro” , nel cui <i>Allegro</i> iniziale ribadisce la volontà di ritrovare l'amata.
            </p>
            <p>
                Segue un tenero e contemplativo <i>Andantino</i> e, infine, rapido e trionfante
                l’<i>Allegro vivace</i> culmina nello slancio di un alto virtuosismo.
            </p>
            <p>
                Lo stile dell’aria è elevato, come si conviene a un personaggio nobile. I tenori di opera comica
                (detti “mezzo carattere”) padroneggiavano sia lo stile buffo che quello serio, come, in questo caso, Ramiro.
            </p>
        </template>
    </CharacterParagraph>
    <CharacterParagraph>
        <template #title>Dandini</template>
        <template #image>
            <img src="/assets/operas/cenerentola/level-1/characters/dandini-stand.png" alt="character picture"/>
        </template>
        <template #image-description>
            <p>
                La <Word :word-id="79">Cavatina</Word> “Come un'ape ne' giorni d'aprile”, dal carattere tronfio e pomposo, annuncia splendidamente
                il personaggio, investito dal compito di recitare un ruolo più grande di lui.
            </p>
            <p>
                La successiva <Word :word-id="87">cabaletta</Word>, il Finale primo, il duetto con don Magnifico e il Sestetto,
                richiedono tutti una grande agilità.
            </p>
        </template>
    </CharacterParagraph>
    <CharacterParagraph>
        <template #title>Don Magnifico</template>
        <template #image>
            <img src="/assets/operas/cenerentola/level-1/characters/don-magnifico-stand.png" alt="character picture"/>
        </template>
        <template #image-description>
            <p>
                Il battibecco delle figlie risveglia don Magnifico, offrendo a <Word :word-id="3">Rossini</Word> l'occasione per comporre una
                superba <Word :word-id="79">cavatina</Word>, nella quale viene descritto un personaggio straripante cui si richiede
                una notevole prestazione vocale.
            </p>
            <p>
                Se le due <Word :word-id="21">arie</Word> di cui è protagonista vedono prevalere il “buffo” caricato,
                il Quintetto e il Sestetto presentano invece la sua faccia spietata e tracotante.
            </p>
        </template>
    </CharacterParagraph>
    <CharacterParagraph>
        <template #title>Clorinda e Tisbe</template>
        <template #image>
            <img src="/assets/operas/cenerentola/level-1/characters/clorinda-tisbe-stand.png" alt="character picture"/>
        </template>
        <template #image-description>
            <p>
                Con un dinamismo incalzante che evoca il meccanismo gestuale delle marionette,
                <Word :word-id="3">Rossini</Word> esprime musicalmente la vanità di Clorinda e Tisbe.
            </p>
            <p>
                Le due sorellastre trovano nelle nervose figurazioni di allegri, concertati e strette,
                meccanismi infallibili per la frenesia della loro stupidità.
            </p>
        </template>
    </CharacterParagraph>
    <CharacterParagraph>
        <template #title>Angelina</template>
        <template #image>
            <img src="/assets/operas/cenerentola/level-1/characters/angelina-stand.png" alt="character picture"/>
        </template>
        <template #image-description>
            <p>
                Il percorso vocale di Cenerentola parte da una semplice Canzonetta (“Una volta c'era un Re”),
                per arrivare a dignità principesca nel Cantabile del Sestetto “Ah, Signor”,
                celebrando il trionfo finale con straordinaria festosità.
            </p>
            <p>
                La <Word :word-id="87">cabaletta</Word> del <Word :word-id="84">rondò</Word> finale “Nacqui all’affanno e al pianto” rende un ennesimo omaggio alla bravura
                della primadonna, che alla prima fu la stessa interprete di Rosina nel Barbiere dell’anno precedente.
            </p>
        </template>
    </CharacterParagraph>
    <CharacterParagraph>
        <template #title>Alidoro</template>
        <template #image>
            <img src="/assets/operas/cenerentola/level-1/characters/alidoro-stand.png" alt="character picture"/>
        </template>
        <template #image-description>
            <p>
                Alidoro, personaggio chiave in equilibrio fra la razionalità del saggio e il carisma
                del mago, pretende le qualità vocali di un <i>basso cantante.</i>
            </p>
            <p>
                L'Aria “Là del ciel nell'arcano profondo” non fu eseguita alla prima, ma composta da Rossini per un
                basso molto migliore. Ha una tavolozza espressiva e delle proporzioni inconsuete per un comprimario
                ed è una delle più difficili per l’interprete: la vocalità si spinge ripetutamente nelle zone estreme,
                acuta e grave, senza molte occasioni per prender fiato.
            </p>
        </template>
    </CharacterParagraph>

    <FunFact>
        <template #title>Vecchie ruggini tra <Word :word-id="3">Rossini</Word> e <Word :word-id="25">Ferretti</Word>.</template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image><img src="/assets/operas/cenerentola/level-1/funfact-image-4-O2L0C2.png" alt=""/></template>
                <template #image-description>
                    <p>
                        Nelle <i>Memorie</i> , <Word theme="white" :word-id="25">Ferretti</Word> sottolinea che non aveva accettato a cuor leggero di scrivere
                        il <Word theme="white" :word-id="6">libretto</Word> per <Word theme="white" :word-id="89">Cartoni</Word>, perché fra il
                        poeta e <Word theme="white" :word-id="3">Rossini</Word> “vi era un po’ di ruggine, per un piccolo sopruso che ne aveva ricevuto”.
                    </p>
                    <p>
                        A quale torto fa riferimento?
                    </p>
                </template>
            </FunfactParagraph>
            <Textbox>
                L’anno precedente alla rappresentazione della <i>Cenerentola</i> , <Word theme="white" :word-id="3">Rossini</Word>
                in persona aveva scartato <Word theme="white" :word-id="25">Ferretti</Word> per la stesura del libretto del
                <i>Barbiere di Siviglia</i> , affidandolo a <Word theme="white" :word-id="4">Cesare Sterbini</Word>.
            </Textbox>
            <Textbox>
                Un cambio di programma che venne mal digerito dal poeta che,per un destino curioso, finì per
                soppiantare, a sua volta, nel 1817, Gaetano Rossi e la sua <i>Ninetta o Laurina</i> . Ferretti avrebbe
                poi scritto per Rossini anche <i>Matilde di Shabran</i> , una delle sue opere più complesse.
            </Textbox>
        </template>
    </FunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
