<script setup>
import {RouterLink} from "vue-router";
import IconAccordionExpand from "@/components/icons/IconAccordionExpand.vue";

const props = defineProps({
    to: {type: String, required: true},
    text: {type: String, required: true},
    disabled: {type: Boolean, required: false},
    theme : {type : String, required : true}
});

const themes = {
    warmLight : {
        fontColor : "text-green-light"
    },
    greenish : {
        fontColor: "text-celeste"
    }
}

const currentTheme = themes[props.theme] ?? themes["warmLight"]

</script>

<template>
    <RouterLink :class="disabled ? 'pointer-events-none' : ''" :to="to">
        <div class="capitolo-card">
            <div class="flex items-center">
                <div class="capitolo-card-icon">
                    <slot name="icon"></slot>
                </div>
                <h2
                    :class="disabled ? 'text-grey-light' : currentTheme.fontColor"
                    class="capitalize font-medium text-2xl"
                >
                    {{ text }}
                </h2>
            </div>
            <IconAccordionExpand
                v-show="!disabled"
                class="-rotate-90 text-grey-dark"
            />
        </div>
    </RouterLink>
</template>

<style scoped>
.capitolo-card {
    @apply px-4 py-2 mb-4 flex items-center justify-between bg-white rounded-3xl;
}

.capitolo-card:last-child {
    @apply mb-0;
}

.capitolo-card-icon {
    @apply mr-4 w-16 h-16;
}
</style>
