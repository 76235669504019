<script setup>

</script>

<template>
    <span>
        Al termine di questo percorso conoscerai la vita di Gioachino Rossini,
        i personaggi chiave della sua esistenza e le opere che ha composto!
    </span>
</template>

<style scoped>

</style>
