<script setup>
</script>

<template>
    <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Frame 218">
            <rect x="1.5" y="1.5" width="44" height="44" rx="5.5" stroke="currentColor" stroke-width="3"/>
            <path id="Vector 39" d="M15 8H22" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 44" d="M28 8H32" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 47" d="M28 37H35" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 45" d="M28 15H35" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 46" d="M28 30H38" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 42" d="M15 30H22" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 40" d="M9 15L22 15" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 43" d="M9 37L22 37" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
            <path id="Vector 41" d="M18 22L30 22" stroke="currentColor" stroke-width="3" stroke-linecap="round"/>
        </g>
    </svg>
</template>

<style scoped>

</style>
