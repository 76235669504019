import Opera0LevelDescription from "@/content/components/ilbarbieredisiviglia/Opera0LevelDescription.vue";
import Opera1LevelDescription from "@/content/components/rossini/Opera1LevelDescription.vue";
import BarbiereIcon from "@/components/icons/BarbiereIcon.vue";
import RossiniIcon from "@/components/icons/RossiniIcon.vue";
import Opera0End from "@/content/components/ilbarbieredisiviglia/Opera0End.vue";
import Opera1End from "@/content/components/rossini/Opera1End.vue";
import CenerentolaIcon from "@/components/CenerentolaIcon.vue";
import Opera2LevelDescription from "@/content/components/cenerentola/Opera2LevelDescription.vue";

export const operasMap = {
    1: {
        prettyName: "ilbarbieredisiviglia",
        title: "Il barbiere di Siviglia",
        code : "bds",
        selectorImg: BarbiereIcon,
        theme: "warmLight",
        selectorDecos: {
            mobile : [
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-02.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-01.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-00.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-03.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-04.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-05.png",
            ],
            desktop : [
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-00-desktop.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-01-desktop.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-02-desktop.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-03-desktop.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-04-desktop.png",
                "/assets/operas/barbieredisiviglia/decorations/barbiere-item-05-desktop.png",
            ]
        },
        levelDescriptionComponent : Opera0LevelDescription,
        levels: [1, 2, 3],
        epilogue : Opera0End
    },
    2 : {
        prettyName: "rossini",
        title: "Rossini",
        code : "ros",
        selectorImg: RossiniIcon,
        theme : "greenish",
        selectorDecos: {
            mobile: [
                "assets/operas/rossini/decorations/rossini-item-00.png",
                "assets/operas/rossini/decorations/rossini-item-01.png",
                "assets/operas/rossini/decorations/rossini-item-02.png",
                "assets/operas/rossini/decorations/rossini-item-03.png",
            ],
            desktop : [
                "assets/operas/rossini/decorations/rossini-item-00-desktop.png",
                "assets/operas/rossini/decorations/rossini-item-01-desktop.png",
                "assets/operas/rossini/decorations/rossini-item-02-desktop.png",
                "assets/operas/rossini/decorations/rossini-item-03-desktop.png",
            ]
        },
        levelDescriptionComponent: Opera1LevelDescription,
        levels: [4],
        epilogue: Opera1End
    },
    3: {
        prettyName: "cenerentola",
        title: "Cenerentola",
        code : "cen",
        selectorImg: CenerentolaIcon,
        theme : "blue",
        selectorDecos: {
            mobile: [
                "/assets/operas/cenerentola/decorations/cenerentola-item-1.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-2.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-0.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-3.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-4.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-5.png",
            ],
            desktop : [
                "/assets/operas/cenerentola/decorations/cenerentola-item-0-desktop.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-1-desktop.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-2-desktop.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-3-desktop.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-4-desktop.png",
                "/assets/operas/cenerentola/decorations/cenerentola-item-5-desktop.png",
            ]
        },
        levelDescriptionComponent: Opera2LevelDescription,
        levels: [5,6],
        epilogue: Opera1End
    },
}

