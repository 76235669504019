<script setup>
import {defineModel, defineProps, watch} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import Instrument from "@/components/content/music/InstrumentListItem.vue";
import Title from "@/components/content/ContentTitle.vue";
import DownloadableItem from "@/components/content/ContentDownloadableItem.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import ImageBox from "@/components/content/ContentImageBox.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import Word from "@/components/glossary/GlossaryWordTooltip.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;

watch(UserProgression.glossaryWords, () => {
    if(!UserProgression.isAchievementUnlocked(15) &&
        UserProgression.allLevelGlossaryWordFound(operaIndex, levelIndex)){
        UserProgression.unlockAchievementById(15);
        UserNotifications.pushNotification("achievement", 15);
    }
})

function schemeDownloaded(){
    if(!UserProgression.isAchievementUnlocked(14)){
        UserProgression.unlockAchievementById(14);
        UserNotifications.pushNotification("achievement", 14);
    }
}

</script>

<template>
    <Title>Qual è l’organico orchestrale della <i>Cenerentola</i> ?</Title>
    <div class="px-8 mt-2 max-w-screen-sm mx-auto">
        <Instrument>2 Flauti/Ottavini</Instrument>
        <Instrument>2 Oboi</Instrument>
        <Instrument>2 clarinetti</Instrument>
        <Instrument>2 fagotti</Instrument>
        <Instrument>2 corni</Instrument>
        <Instrument>2 trombe</Instrument>
        <Instrument>Trombone</Instrument>
        <Instrument>Archi</Instrument>
    </div>

    <ImageBox align="center">
        <img src="/assets/operas/cenerentola/level-1/orchestra-cenerentola.png" alt="" />
    </ImageBox>

    <Title>Scarica lo schema musicale della <i>Cenerentola</i> !</Title>
    <DownloadableItem @download="schemeDownloaded" src="https://io-bunny-cdn.b-cdn.net/ROF/documents/SchemaMusicale_Cenerentola.pdf" :n-pages="2">
        <template #title>Schema musicale della <i>Cenerentola</i></template>
    </DownloadableItem>
    <FunFact>
        <template #title>
            <Word :word-id="41">Geltrude Righetti-Giorgi</Word>: “<Word :word-id="3">Rossini</Word> amava lavorare nel caos”.
        </template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image-description>
                    <p>
                        In <i>Cenni di una donna già cantante sopra il Maestro Rossini</i> ,
                        <Word theme="white" :word-id="41">Geltrude Righetti-Giorgi</Word>, la prima
                        Cenerentola rossiniana, nonché la prima Rosina del <Word theme="white" :word-id="96">Barbiere di Siviglia</Word>,
                        racconta le abitudini lavorative del Maestro.
                    </p>
                </template>
                <template #image>
                    <img src="/assets/operas/cenerentola/level-1/funfact-image-5-O2L0C3.png" alt="" />
                </template>
            </FunfactParagraph>
            <Textbox>
                [non le conosco, mi giunge nuovo: io ho sempre letto di Rossini che scriveva ovunque, in carrozza come
                a cena], <Word theme="white" :word-id="41">Righetti-Giorgi</Word> scrive di aver visto il Maestro “comporre la Cenerentola in mezzo al
                più gran chiasso” e pregare gli amici di restare in sua compagnia dicendo:
                “Se ve ne andate [...] io manco di estro, e di ogni appoggio”.
            </Textbox>
        </template>
    </FunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
