<script setup>
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {computed, ref} from "vue";
import LivelloCard from "@/components/levels/LevelCard.vue";
import LivelloStats from "@/components/levels/LevelStats.vue";
import {extractOperaRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";

const themes = {
    warmLight : {
        operaTitle : "text-blue"
    },
    greenish : {
        operaTitle : "text-blue"
    },
    fucsia : {
        operaTitle : "text-yellow"
    },
    blue : {
        operaTitle : "text-warm-light"
    }
}

const UserProgression = useUserProgressionStore();

const operaRouteInfo = extractOperaRouteInfo()

const operaId = operaRouteInfo.id;
const operaIndex = operaRouteInfo.index;

const opera = computed(() => {
    return AppContent.operas[operaIndex]
})

const theme = computed(() => themes[opera.value.theme] ?? themes["warmLight"])

/**
 * fade container's sides if scrollable
 */

const levelUrl = (level) => `/app/${operaId}/${level.prettyName}`

const getLevelState = (levelIndex) => {
    const state = UserProgression.levelProgression(operaIndex, levelIndex).state
    if(state === "locked" || (state === "unlocked" && UserProgression.levelCompletionPerc(operaIndex, levelIndex) < 1)) {
        return state
    } else {
        return UserProgression.isLevelMastered(operaIndex, levelIndex) ? "mastered" : "completed"
    }
}

function convertScroll(e) {
    // add vertical scroll to horizontal scroll
    if (!e.deltaY) return;
    e.currentTarget.scrollLeft += e.deltaY + e.deltaX;
    e.preventDefault();
}
</script>

<template>
    <div class="levels-list">
        <header class="opera-title" :class="theme.operaTitle">
            <h1 class="text-3xl md:text-5xl">{{ opera.title }}</h1>
        </header>
        <div
            class="levels-scroller"
            @wheel="convertScroll"
        >
            <div class="scroller-inner">
                <LivelloCard
                    v-for="(item, index) in opera.levels"
                    :key="index"
                    :level="item"
                    :progression="getLevelState(index)"
                    :to="levelUrl(item)" :description-component="opera.levelDescriptionComponent">
                    <template #stats>
                        <LivelloStats
                            :data="item"
                            :progression="UserProgression.unlockedLevelAchievements(operaIndex, index)"
                            theme="levelCard"
                            :opera-theme="opera.theme"/>
                    </template>
                </LivelloCard>
                <div class="pe-4 md:hidden"></div>
            </div>
        </div>
    </div>
</template>

<!-- <style>
.list-enter-active,
.list-leave-active {
    transition: all 4.5s ease;
}
.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(300px);
}
</style> -->

<style scoped>

.levels-list{
    @apply max-w-screen-xl mx-auto
}

.opera-title {
    @apply mt-10 mb-8 px-8 mx-auto md:mb-14 md:mt-16 font-medium max-w-screen-lg ;
}

.levels-scroller {
    @apply h-auto w-full;
    @apply overflow-x-auto overflow-y-hidden;
    /* @apply flex flex-col justify-center; */
    scroll-snap-type: x mandatory;
    /* overscroll-behavior: none; */
}

.scroller-inner {
    @apply h-full flex px-4 md:justify-center;
}

.is--scrollable-right {
    mask-image: linear-gradient(
        to right,
        black calc(100% - 2em),
        transparent 100%
    );
}

.is--scrollable-both {
    mask-image: linear-gradient(
        to right,
        transparent 0%,
        black calc(0% + 2em),
        black calc(100% - 2em),
        transparent 100%
    );
}

.is--scrollable-left {
    mask-image: linear-gradient(
        to left,
        black calc(100% - 2em),
        transparent 100%
    );
}
</style>
