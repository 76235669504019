<script setup>
import {gsap} from "gsap";
import {Draggable} from "gsap/Draggable";
import {onMounted, ref} from "vue";
import SlidesCardSlide from "@/components/utilities/SlidesCardSlide.vue";

const props = defineProps({
    directionNotes : {type : Boolean, required : false, default : false},
    backgroundSrc : {type : String, required : true},
})

gsap.registerPlugin(Draggable)

const directionNote = ref(null)
const directionNoteContainer = ref(null)

onMounted(() => {

    Draggable.create(directionNote.value, {
        type : "y",
        bounds : directionNoteContainer.value,
        allowNativeTouchScrolling: false,
    })

})

</script>

<template>
    <SlidesCardSlide :bottom-notes="directionNotes" :background-src="backgroundSrc">
        <template #content>
            <div class="direction-slide-halftone top">
                <img class="w-full" src="/assets/halftones/halftone-dir-top.png" alt="decoration-halftone">
            </div>
            <div class="direction-slide-halftone bottom">
                <img class="w-full" src="/assets/halftones/halftone-dir-bot.png" alt="decoration-halftone">
            </div>
            <slot  name="role-tooltips"></slot>
        </template>
        <template #bottom-notes-title>NOTE DI REGIA</template>
        <template #bottom-notes>
            <slot name="direction-notes"></slot>
        </template>
    </SlidesCardSlide>
</template>

<style scoped>
    .slide-background{
        @apply w-full object-center object-cover absolute bottom-0 z-0;
    }

    .direction-slide-halftone{
        @apply absolute z-10 inset-x-0;
    }

    .direction-slide-halftone.top {
        @apply top-0;
    }

    .direction-slide-halftone.bottom{
        @apply bottom-0;
    }

    .bottom-note-container{
        @apply absolute h-184 inset-x-10 -bottom-88;
    }

    .bottom-note{
        @apply absolute h-100 w-full bottom-0 bg-warm-light rounded-t-3xl z-40 flex flex-col items-center;
    }
</style>
