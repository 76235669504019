<script setup>
import Title from "@/components/content/ContentTitle.vue";
import TextBox from "@/components/content/ContentTextbox.vue";
import AriaPlayer from "@/components/content/music/AriaPlayer.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import InstrumentList from "@/components/content/music/PlayableInstrumentsList.vue";
import {defineModel, defineProps} from "vue";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import ContentFunFact from "@/components/content/ContentFunFact.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import ContentFunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const readChapters = defineModel()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];

function setAriaPlayed(ariaId){
    UserProgression.setAriaAsListened(ariaId)
    if (!UserProgression.isAchievementUnlocked(3)
        && UserProgression.allAriasListened(operaIndex, levelIndex, chapterIndex)) {
        UserProgression.unlockAchievementById(3);
        UserNotifications.pushNotification("achievement", 3);
    }
}


</script>

<template>
    <!-- section 1 -->
    <Title>Quali sono i principali strumenti musicali del <i class="italic">Barbiere di Siviglia</i> ?</Title>
    <TextBox>Tocca gli strumenti per scoprirne il suono!</TextBox>
    <InstrumentList :instruments="currentChapter.instruments" :theme="AppContent.operas[operaIndex].theme"/>
    <!-- section 2 -->
    <Title>Ascolta alcuni dei brani più iconici del <i class="italic">Barbiere di Siviglia!</i></Title>

    <AriaPlayer v-for="aria in currentChapter.arias" :src="aria.audioSource" @play="setAriaPlayed(aria.id)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark">{{aria.name}}</h3>
            <p class="px-8 text-grey-dark">{{aria.shortDescription}}</p>
        </template>
        <template #description>
            <TextBox>
                {{aria.longDescription}}
            </TextBox>
        </template>
    </AriaPlayer>

    <ContentFunFact theme="green">
        <template #counter>CURIOSITÀ (3 su 3)</template>
        <template #title>1 musicista per 2 strumenti? </template>
        <template #funcfact-p>
            <ContentFunfactParagraph>
                <template #image>
                    <img src="/assets/operas/barbieredisiviglia/level-0/funfact-image-2-O0L0C2.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Stando a un documento che riepiloga le spese della stagione del Teatro Argentina
                        in cui andò in scena per la prima volta <i class="italic">Il barbiere di Siviglia</i>,
                        il musicista Luigi Biglioni ricevette un compenso superiore per essersi fatto carico di entrambe
                        le parti di flauto e di oboe, non essendo stato possibile all'impresario trovare altri strumentisti disponibili.
                    </p>
                </template>
            </ContentFunfactParagraph>
            <TextBox>
                Nel completare l'orchestrazione dell'opera, Rossini dovette tenerne conto.<br>
                Ecco perché nella partitura del <i class="italic">Barbiere</i>, quando sono presenti 2 flauti/ottavini o flauto e ottavino, mai figura l'oboe; e,
                viceversa, quando interviene l'oboe viene impiegato un solo flauto/ottavino.
            </TextBox>
            <TextBox>
                Non si sa come sia stato risolto il problema nella <GlossaryWord theme="white" :word-id="10">sinfonia</GlossaryWord>
                dove è prevista la presenza di 2 oboi e di 2 flauti/ottavini.
            </TextBox>
        </template>
    </ContentFunFact>

    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped></style>
