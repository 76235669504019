<script setup>
import {computed} from "vue";

/**
 * Button component
 * @ param {string} text - Button text
 * @ param {string} variant - Button variant -> 'text', 'icon', "iconText",
 * @ param {string} type - Button type for appropriate html attribute -> 'button', 'submit', 'reset'
 * @ param {string} theme - Button theme
 * @ param {boolean} disabled - Button disabled
 * #icon {slot} icon for "icon" and "iconText" variant
 */
const props = defineProps({
    text: { type: String},
    variant: { type: String, required: false, default: "text" },
    type: { type: String, required: false },
    theme: { type: String, required: false },
    disabled: { type: Boolean, required: false },
});
const type = props.type || "button";

const themes = {
    default: "px-6 py-3 bg-black text-white rounded-3xl font-medium text-2xl",
    circularRedIcon: "p-2 bg-warm text-white rounded-full font-medium text-2xl",
    transparentInherit: "bg-transparent text-inerith font-medium text-2xl",
    transparentGreen: "bg-transparent text-green font-medium text-2xl",
    transparentBlack: "bg-transparent text-black font-medium text-2xl",
    roundedGreenText: "px-6 py-2 bg-green text-warm-light rounded-3xl font-medium text-2xl",
    roundedRedText: "px-6 py-2 bg-warm text-white rounded-3xl font-medium text-2xl",
    roundedRedBg: "px-6 py-2 bg-warm text-warm-light rounded-3xl font-medium text-2xl",
    roundedRedBgAlt : "px-6 py-2 bg-warm text-white rounded-3xl font-medium text-2xl",
    roundedWarmBg : "px-6 py-2 bg-warm-light text-black rounded-3xl font-medium text-2xl",
    roundedBlueText: "px-6 py-2 bg-warm-light text-blue rounded-3xl font-medium text-2xl",
    roundedWhiteBg : "px-6 py-2 bg-white text-blue rounded-3xl font-medium text-2xl",
    roundedWhiteBgRedText : "px-6 py-2 bg-white text-warm rounded-3xl font-medium text-2xl",
    roundedGreenishText : "px-6 py-2 bg-celeste text-greenish rounded-3xl font-medium text-2xl",
    forgotPassword : "px-6 py-2 bg-warm-light text-grey-dark rounded-3xl text-base",
    backToLogin : "px-6 py-1 bg-blue text-warm-light rounded-3xl font-medium text-2xl",
    roundedFucsiaBg : "px-6 py-2 bg-warm-dark text-warm-light rounded-3xl font-medium text-2xl",
    roundedBlackBg : "px-6 py-2 bg-black text-warm-light rounded-3xl font-medium text-2xl",
};
const theme = computed(() => props.theme in themes ? props.theme : "default");
</script>

<template>
    <button
        :type="type"
        class="button"
        :class="themes[theme]"
        :disabled="props.disabled"
    >
        <slot
            name="icon"
            v-if="variant === 'icon' || variant === 'iconText'"
        ></slot>
        <span :hidden="variant === 'icon'">{{ text }}</span>
    </button>
</template>

<style scoped>
.button {
    @apply font-sans;
}
.button:disabled {
    opacity: 0.5;
}
</style>
