<script setup>
const props = defineProps({
    decos: { type: Array, required: true },
});
</script>

<template>
    <div class="opera-bg">
        <!-- limiter for large screens -->
        <div class="opera-bg-limiter">
            <!-- left half -->
            <div class="opera-decos-half">
                <template v-for="(item, index) in props.decos" :key="index">
                    <div
                        class="opera-deco"
                        v-if="index < Math.ceil(props.decos.length / 2)"
                        :style="`z-index : ${index}`"
                    >
                        <img class="opera-deco-img" :src="item"  alt=""/>
                    </div>
                </template>
            </div>
            <!-- right half -->
            <div class="opera-decos-half">
                <template v-for="(item, index) in props.decos" :key="index">
                    <div
                        class="opera-deco"
                        v-if="index >= Math.ceil(props.decos.length / 2)"
                        :style="`z-index : ${props.decos.length - index}`"
                    >
                        <img class="opera-deco-img"  :src="item"  alt=""/>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<style scoped>
.opera-bg {
    @apply absolute bottom-0 inset-x-0 h-[40vh]  -z-10;
}
.opera-bg-limiter {
    @apply flex-grow w-full h-full flex relative;
}
.opera-decos-half {
    @apply h-full w-full absolute inset-x-0;
}
.opera-deco {
    @apply w-full absolute bottom-0;
}
.opera-deco-img{
    @apply w-full
}
</style>
