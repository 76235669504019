export const autoimprestitiMap = {
    1:{
        sources : [1,2]
    },
    2: {
        sources : [3,4]
    },
    3: {
        sources : [5,6]
    },
}

export const autoimprestitiSourcesMap = {
    1: {
        poster: "/assets/operas/barbieredisiviglia/level-2/poster-sinfonia-barbiere.png",
        url: "https://vz-c01ac8fb-8ab.b-cdn.net/20a7b64c-2188-4ce9-b0f5-c3e4f6bed99c/original",
        tag: "sinfonia-barbiere",
        title : "<i class='italic'>Sinfonia</i>",
        subtitle : "dal <i class='italic'>Barbiere di Siviglia</i>",
    },
    2: {
        poster: "/assets/operas/barbieredisiviglia/level-2/poster-sinfonia-aureliano.png",
        url: "https://vz-c01ac8fb-8ab.b-cdn.net/e5bed1da-1f02-41d6-8e5e-034168cf66e8/original",
        tag: "sinfonia-aureliano",
        title : "<i class='italic'>Sinfonia</i>",
        subtitle : "da <i class='italic'>Aureliano in Palmira</i>",
    },
    3: {
        poster: "/assets/operas/barbieredisiviglia/level-2/poster-coro-piano-barbiere.png",
        url: "https://vz-c01ac8fb-8ab.b-cdn.net/7d93eb4a-fbb0-4775-ae57-dce43759d47f/original",
        tag: "piano-barbiere",
        title : "Coro <i class='italic'>Piano, Pianissimo, senza parlar</i>",
        subtitle : "dal <i class='italic'>Barbiere di Siviglia</i>",
    },
    4: {
        poster: "/assets/operas/barbieredisiviglia/level-2/poster-coro-piano-sigismondo.png",
        url: "https://vz-c01ac8fb-8ab.b-cdn.net/ab10c258-c95c-4ad8-bd4f-d0b2906da28a/original",
        tag: "piano-sigismondo",
        title : "Coro <i class='italic'>In segreto a che ci chiama?</i>",
        subtitle : "da <i class='italic'>Sigismondo</i>",
    },
    5: {
        poster: "/assets/operas/barbieredisiviglia/level-2/poster-cavatina-barbiere.png",
        url: "https://vz-c01ac8fb-8ab.b-cdn.net/c0bcad4d-a919-4195-97fc-193bec333502/original",
        tag: "cavatina-barbiere",
        title : "Cavatina del Conte <i class='italic'>Ecco ridente in cielo</i>",
        subtitle : "dal <i class='italic'>Barbiere di Siviglia</i>",
    },
    6: {
        poster: "/assets/operas/barbieredisiviglia/level-2/poster-cavatina-aureliano.png",
        url: "https://vz-c01ac8fb-8ab.b-cdn.net/036d33f6-c348-4927-ad90-4d4dfa0e8098/original",
        tag: "cavatina-aureliano",
        title : "<i class='italic'>Sposa del grande Osiride</i>",
        subtitle : "da <i class='italic'>Aureliano in Palmira</i>",
    },
}
