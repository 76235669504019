export const levelsMap = {
    1: {
        prettyName: "1",
        title: "Livello 1",
        description: "conoscenza generale",
        duration: "10'",
        selectorImg: {
            default: "/assets/operas/barbieredisiviglia/level-0/level-selector-O0L0.png",
            warm: "/assets/operas/barbieredisiviglia/level-0/level-selector-big-O0L0.png"
        },
        achievements: [1, 2, 3],
        chapters: [1, 2, 3, 4],
        glossaryWords: new Set([])
    },
    2: {
        prettyName: "2",
        title: "Livello 2",
        description: "conoscenza intermedia",
        duration: "15'",
        selectorImg: {
            default: "/assets/operas/barbieredisiviglia/level-1/level-selector-O0L1.png",
            warm: "/assets/operas/barbieredisiviglia/level-1/level-selector-big-O0L1.png"
        },
        achievements: [4, 5, 9],
        chapters: [5, 6, 7, 8],
        glossaryWords: new Set([53,31,11,12,23,24,27,13,14,29,30,26,28,25,15,16,17,18,19,20,21,22])
    },

    3: {
        prettyName: "3",
        title: "Livello 3",
        description: "conoscenza avanzata",
        duration: "20'",
        selectorImg: {
            default: "/assets/operas/barbieredisiviglia/level-2/level-selector-O0L2.png",
            warm: "/assets/operas/barbieredisiviglia/level-2/level-selector-big-O0L2.png"
        },
        achievements:[6, 7, 8],
        chapters: [9, 10, 11, 12],
        glossaryWords: new Set([])
    },
    4 : {
        prettyName: "1",
        title: "Rossini",
        description: "conoscenza avanzata",
        duration: "20'",
        selectorImg: {
            default: "/assets/operas/barbieredisiviglia/level-2/level-selector-O0L2.png",
            warm: "/assets/operas/barbieredisiviglia/level-2/level-selector-big-O0L2.png"
        },
        achievements:[10],
        chapters: [13,14,15,16],
        glossaryWords: new Set([])
    },
    5 : {
        prettyName: "1",
        title: "Livello 1",
        description: "conoscenza generale",
        duration: "20'",
        selectorImg: {
            default: "/assets/operas/cenerentola/level-0/level-selector-O2L0.png",
            warm: "/assets/operas/cenerentola/level-0/level-selector-O2L0.png"
        },
        achievements:[11, 12, 13],
        chapters: [17,18,19,20],
        glossaryWords: new Set([])
    },
    6 : {
        prettyName: "2",
        title: "Livello 2",
        description: "conoscenza intermedia",
        duration: "20'",
        selectorImg: {
            default: "/assets/operas/cenerentola/level-1/level-selector-O2L1.png",
            warm: "/assets/operas/cenerentola/level-1/level-selector-O2L1.png"
        },
        achievements:[14,15,16],
        chapters: [21,22,23,24],
        glossaryWords: new Set([3,96,89,26,2,31,6,90,25,97,98,41,15,16,18,19,17,79,87,21,84,4])
    },
    7 : {
        prettyName: "3",
        title: "Livello 3",
        description: "conoscenza avanzata",
        duration: "20'",
        selectorImg: {
            default: "/assets/operas/cenerentola/level-2/level-selector-O2L2.png",
            warm: "/assets/operas/cenerentola/level-2/level-selector-O2L2.png"
        },
        achievements:[],
        chapters: [25,26,27,28],
        glossaryWords: new Set([])
    }

}

