import StoryO0L0 from "@/content/components/ilbarbieredisiviglia/level0/Story.vue";
import CharactersO0L0 from "@/content/components/ilbarbieredisiviglia/level0/Characters.vue";
import MusicO0L0 from "@/content/components/ilbarbieredisiviglia/level0/Music.vue";
import MinigameO0LO from "@/content/components/ilbarbieredisiviglia/level0/Minigame.vue";
import CharactersO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Characters.vue";
import StoryO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Story.vue";
import MusicO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Music.vue";
import MinigameO0L1 from "@/content/components/ilbarbieredisiviglia/level1/Minigame.vue";
import StoryO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Story.vue";
import CharactersO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Characters.vue";
import MusicO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Music.vue";
import MinigameO0L2 from "@/content/components/ilbarbieredisiviglia/level2/Minigame.vue";
import Story01L0 from "@/content/components/rossini/level0/Story.vue";
import Characters01L0 from "@/content/components/rossini/level0/Characters.vue";
import Music01L0 from "@/content/components/rossini/level0/Music.vue";
import Minigame01L0 from "@/content/components/rossini/level0/Minigame.vue";
import Story02L0 from "@/content/components/cenerentola/level0/Story.vue";
import CharactersO2L0 from "@/content/components/cenerentola/level0/Characters.vue";
import Music02L0 from "@/content/components/cenerentola/level0/Music.vue";
import Minigame02L0 from "@/content/components/cenerentola/level0/Minigame.vue";
import StoryO2L1 from "@/content/components/cenerentola/level1/Story.vue"
import MusicO2L1 from "@/content/components/cenerentola/level1/Music.vue";
import Characters02L1 from "@/content/components/cenerentola/level1/Characters.vue";
import Minigame02L1 from "@/content/components/cenerentola/level1/Minigame.vue";

export const chaptersMap = {
    1: {
        prettyName: "storia",
        title: "Storia",
        component: StoryO0L0
    },
    2: {
        prettyName: "personaggi",
        title: "Personaggi",
        characters: [1, 2, 3, 4, 5, 6],
        component: CharactersO0L0
    },
    3: {
        prettyName: "musica",
        title: "Musica",
        instruments: [1, 2, 3, 4, 5, 6],
        arias: [1, 2, 3, 4, 5, 6],
        component: MusicO0L0
    },
    4: {
        prettyName: "minigame",
        title: "ROFplay",
        component: MinigameO0LO
    },
    5: {
        prettyName: "storia",
        title: "Storia",
        component: StoryO0L1
    },
    6: {
        prettyName: "personaggi",
        title: "Personaggi",
        component: CharactersO0L1
    },
    7: {
        prettyName: "musica",
        title: "Musica",
        component: MusicO0L1
    },
    8: {
        prettyName: "minigame",
        title: "ROFplay",
        component: MinigameO0L1
    },
    9: {
        prettyName: "storia",
        title: "Storia",
        component: StoryO0L2
    },
    10: {
        prettyName: "personaggi",
        title: "Personaggi",
        component: CharactersO0L2
    },
    11: {
        prettyName: "musica",
        title: "Musica",
        component: MusicO0L2,
        autoimprestiti: [1, 2, 3],
        arias: [7, 8, 9]
    },
    12: {
        prettyName: "minigame",
        title: "ROFplay",
        component: MinigameO0L2
    },
    13: {
        prettyName: "storia",
        title: "Storia",
        component: Story01L0
    },
    14: {
        prettyName: "personaggi",
        title: "Personaggi",
        component: Characters01L0,
        characters: [7, 8, 9, 10, 11, 12],
    },
    15: {
        prettyName: "musica",
        title: "Musica",
        component: Music01L0,
    },
    16: {
        prettyName: "minigame",
        title: "inOpera",
        component: Minigame01L0
    },
    17: {
        prettyName: "storia",
        title: "Storia",
        component: Story02L0
    },
    18: {
        prettyName: "personaggi",
        title: "Personaggi",
        component: CharactersO2L0,
        characters : [13,14,15,16,17,18]
    },
    19: {
        prettyName: "musica",
        title: "Musica",
        instruments: [7, 8, 9, 10, 11, 12],
        arias: [7, 8, 9, 10, 11, 12],
        component: Music02L0,
    },
    20: {
        prettyName: "minigame",
        title: "ROFplay",
        component: Minigame02L0
    },
    21: {
        prettyName: "storia",
        title: "Storia",
        component: StoryO2L1
    },
    22: {
        prettyName: "personaggi",
        title: "Personaggi",
        component: Characters02L1,
    },
    23: {
        prettyName: "musica",
        title: "Musica",
        component: MusicO2L1,
    },
    24: {
        prettyName: "minigame",
        title: "ROFplay",
        component: Minigame02L1
    },
    25: {
        prettyName: "storia",
        title: "Storia",
        component: Story01L0
    },
    26: {
        prettyName: "personaggi",
        title: "Personaggi",
        component: Characters01L0,
    },
    27: {
        prettyName: "musica",
        title: "Musica",
        component: Music01L0,
    },
    28: {
        prettyName: "minigame",
        title: "ROFplay",
        component: Minigame01L0
    },
}
