<script setup>
/**
 *  Accordion component
 *  @ param {string} ariaTitle - Accordion title for HTML attribute - MUST BE UNIQUE
 *  @ param {boolean} preExpanded - Accordion expanded
 *  @ param {string} theme - Accordion theme
 *  #trigger {slot} trigger
 *  #content {slot} content
 */
import IconAccordionCollapse from "@/components/icons/IconAccordionCollapse.vue";
import IconAccordionExpand from "@/components/icons/IconAccordionExpand.vue";
import { ref } from "vue";

const props = defineProps({
    ariaTitle: { type: String, required: true },
    preExpanded: { type: Boolean, required: false },
    theme: { type: String, required: false },
    disabled : { type : Boolean, required : false, default : false}
});

const emit = defineEmits(["open", "close"]);

/** LOCAL STORE */
const isExpanded = ref(props.preExpanded || false);
const accordionId = "accordion-" + props.ariaTitle.replace(/\s/g, "");

/** METHODS */
function openPanel() {
    isExpanded.value = true;
    emit("open");
}
function closePanel() {
    isExpanded.value = false;
    emit("close");
}
function togglePanel() {
    if (isExpanded.value === true) {
        closePanel();
    } else {
        openPanel();
    }
}

/** THEMES */
const themes = {
    default: {
        accordion: "text-black border mb-4",
        trigger: "text-xl p-4 justify-between",
        triggerArrows: "",
        content: "",
    },
    menuLink: {
        accordion: "text-green-light px-0 w-full mb-0",
        trigger: "text-xl p-0 justify-between",
        triggerArrows: "",
        content: "pl-7",
    },
    loginGreen: {
        accordion: "bg-green text-black rounded-3xl mb-4",
        trigger: "text-warm-light text-2xl text-center py-2 px-2 justify-center uppercase",
        triggerArrows: "hidden",
    },
    loginBlue: {
        accordion: "bg-blue text-warm-light rounded-3xl mb-4",
        trigger: "text-warm-light text-2xl text-center py-2 px-2 justify-center uppercase",
        triggerArrows: "hidden",
    },
    loginWarmLight: {
        accordion: "bg-white text-black rounded-3xl mb-4",
        trigger: "text-blue text-2xl text-center py-2 px-2 justify-center uppercase",
        triggerArrows: "hidden",
    },
    glossaryGroup: {
        accordion: "bg-warm-light text-grey-dark rounded-3xl mb-4",
        trigger: "py-4 px-8 justify-between md:pr-11",
        triggerArrows: "",
        content: "md:pr-11 md:pl-20"
    },
    operaAchievements: {
        accordion: "bg-warm-light text-black rounded-3xl mb-4",
        trigger: "text-black py-4 pl-5 pr-8 justify-between md:pr-11",
        triggerArrows: "",
    },
    glossaryItem: {
        accordion: "bg-warm-light text-green-light ml-8 mr-14 md:mx-0 mb-4",
        trigger: "text-green-light text-xl py-4 pl-2 pr-0 justify-between md:max-w-60",
        triggerArrows: "",
        content : "pl-8 md:pl-20 md:pr-0"
    },
    operasList:{
        accordion: "text-black mx-5 mb-4",
        trigger: "bg-greenish text-celeste text-2xl font-medium rounded-full py-3 px-5 mb-9 justify-between",
    }
};

const theme = props.theme in themes ? props.theme : "default";

/** EXPOSE */
defineExpose({
    accordionId,
    isExpanded,
    openPanel,
    closePanel,
    togglePanel,
});
</script>

<template>
    <!-- item -->
    <div class="accordion" :class="[themes[theme].accordion, disabled ? 'disabled' : '']" :id="accordionId">
        <!-- trigger -->
        <button
            :arial-controls="'accordion-content-' + ariaTitle"
            :aria-expanded="isExpanded"
            :id="'accordion-control-' + ariaTitle"
            @click.prevent="togglePanel"
            class="trigger"
            :class="themes[theme].trigger"
            :disabled="disabled"
        >
            <slot name="trigger"></slot>

            <div
                class="trigger-arrows"
                :class="themes[theme].triggerArrows"
                v-if="isExpanded"
            >
                <IconAccordionCollapse />
            </div>
            <div
                class="trigger-arrows"
                :class="themes[theme].triggerArrows"
                v-else
            >
                <IconAccordionExpand />
            </div>
        </button>
        <!-- content -->
        <div
            class="content"
            role="region"
            :aria-hidden="!isExpanded"
            :aria-labelledby="'accordion-control-' + ariaTitle"
            :id="'content-' + ariaTitle"
            :class="[isExpanded ? 'expanded' : '', themes[theme].content]"
            :hidden="isExpanded"
            :tabindex="isExpanded ? 0 : -1"
        >
            <div class="content-inner">
                <slot name="content"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>
.accordion {
    /* @apply mb-4; */
}
.accordion.disabled{
    opacity: 0.5;
}

.trigger {
    @apply w-full flex flex-row items-center;
}
.trigger-arrows {
    @apply w-4 h-4;
}
.content {
    @apply grid ;
    grid-template-rows: 0fr;
    transition: grid-template-rows 0.4s ease-out;
}
.content.expanded {
    grid-template-rows: 1fr;
}

.content-inner {
    @apply overflow-hidden;
}
</style>
