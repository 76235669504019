<script setup>
import { ref } from "vue";
import AppAudio from "@/components/utilities/AppAudio.vue";

const props = defineProps({
    img: { type: String, required: true },
    source: { type: String, required: true },
    title: { type: String, required: false },
    theme: {type: String, required: true },
});

const themes = {
    warmLight : {
        playingBg : "/assets/halftones/instrument-playing-halftone.png"
    },
    greenish : {
        playingBg : "/assets/halftones/instrument-playing-halftone.png"
    },
    blue : {
        playingBg : "/assets/halftones/cenerentola_instrument_halftone.png"
    }
}

const currentTheme = themes[props.theme]?? themes.warmLight;

const themedPlayingBg = `url(${currentTheme.playingBg})`


const audio = ref(null);

const playingClass = ref(null);

</script>

<template>
    <div class="instrument-item" :class="audio?.playing ? 'playing' : ''" @click="audio.playReset">
        <AppAudio
            ref="audio"
            :src="source"
        />
        <img class="rounded-3xl w-full h-full" :src="img" :alt="title"/>
        <div class="instrument-item-label">
            <p class="mb-0">{{title}}</p>
        </div>
    </div>
</template>

<style scoped>

.instrument-item-label{
    @apply absolute bottom-0 inset-x-0 mx-2 bg-warm-light rounded-t-full text-blue uppercase py-2 px-2 font-medium text-sm text-center;
}

.instrument-item {
    @apply bg-green-light rounded-3xl relative overflow-clip;
}
.instrument-item.playing {
    @apply bg-warm;
    background-image: v-bind(themedPlayingBg);
}
</style>
