<script setup>
import {defineModel, defineProps} from "vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import Title from "@/components/content/ContentTitle.vue";
import Textbox from "@/components/content/ContentTextbox.vue";
import ContentImageBox from "@/components/content/ContentImageBox.vue";
import GlossaryWord from "@/components/glossary/GlossaryWordTooltip.vue";
import ContentFunFact from "@/components/content/ContentFunFact.vue";
import ContentFunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const readChapters = defineModel()
</script>

<template>
    <Title>
        Una celebre fiaba con origini antichissime.
    </Title>
    <Textbox>
        Sicuramente anche tu conosci la fiaba di Cenerentola.
    </Textbox>
    <Textbox>
        Forse ti è stata raccontata dai genitori o dai nonni, hai visto un film,
        letto un libro, oppure ne hai solo sentito parlare.
    </Textbox>
    <Textbox>
        È una fiaba di origini antichissime, tramandata nei secoli, e di cui,
        proprio per questo motivo, esistono numerose versioni.
    </Textbox>
    <ContentImageBox>
        <img src="/assets/operas/cenerentola/level-0/content-image-0-O2L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>
    <Title><i>La Cenerentola</i> di Rossini.</Title>
    <Textbox>
        In ambito operistico, sono state realizzate varie “Cenerentole”; tra queste, spicca quella di Gioachino Rossini.
    </Textbox>
    <Textbox>
        Il titolo completo è <i>La Cenerentola, ossia La bontà in trionfo</i> ed è un <GlossaryWord :word-id="78">dramma giocoso</GlossaryWord>
        in due <GlossaryWord :word-id="1">atti</GlossaryWord> basato sul
        <GlossaryWord :word-id="6">libretto</GlossaryWord> di
        <GlossaryWord :word-id="25">Jacopo Ferretti</GlossaryWord>.
    </Textbox>
    <Textbox>
        L’opera, composta tra il dicembre 1816 e il gennaio 1817, è andata in scena
        per la prima volta al <GlossaryWord :word-id="26">Teatro Valle</GlossaryWord> di Roma il 25 gennaio 1817.
    </Textbox>
    <ContentImageBox align="right">
        <img src="/assets/operas/cenerentola/level-0/content-image-1-O2L0C0.png" alt="picture with a portrait" />
    </ContentImageBox>
    <Title>
        Un’opera diversa dalla fiaba tradizionale.
    </Title>
    <Textbox>
        Quando si pensa alla <i>Cenerentola</i> di Rossini, occorre dimenticarsi scarpette di cristallo, fate madrine e atmosfera fiabesca.
    </Textbox>
    <Textbox>
        L’opera di Rossini, infatti, mette da parte gli elementi magici, acquisendo un tono più riflessivo.
    </Textbox>
    <Textbox>
        La storia si concentra sulle qualità morali di Angelina (Cenerentola),
        sulla sciocca arroganza di patrigno e sorellastre, sull’amore del principe Ramiro.
    </Textbox>
    <Textbox>
        Ciò che permette a Cenerentola di trionfare non è la magia, ma la sua bontà d’animo.
    </Textbox>
    <ContentFunFact>
        <template #title>
            Ecco perché Ferretti scrisse una <i>Cenerentola</i> senza magia.
        </template>
        <template #funcfact-p>
            <ContentFunfactParagraph>
                <template #image>
                    <img src="/assets/operas/cenerentola/level-0/funfact-image-0-O2L0C0.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        “Il pubblico vuole a teatro qualcosa di diverso da quello che può divertirlo in una storiella accanto al fuoco.”
                    </p>
                    <p>
                        Così Jacopo Ferretti giustificò la natura dell’opera, molto diversa dalla versione
                        magica e fiabesca di <GlossaryWord :word-id="80" theme="white">Charles Perrault</GlossaryWord>,
                        pubblicata nel 1697, che rese celebre la vicenda.
                    </p>
                </template>
            </ContentFunfactParagraph>
            <Textbox>
                Oltre alle motivazioni citate, bisogna però anche considerare che Ferretti prese ispirazione
                da <GlossaryWord :word-id="82" theme="white"><i>Agatina o la virtù premiata</i></GlossaryWord>,
                libretto di Francesco Fiorini messo in musica da
                <GlossaryWord :word-id="81" theme="white">Stefano Pavesi</GlossaryWord>.
            </Textbox>
            <Textbox>
                Queste due versioni di Cenerentola presentano infatti numerosi punti di contatto a livello drammatico,
                ma è la composizione musicale a trasformare l’atmosfera e il significato della storia.
            </Textbox>
        </template>
    </ContentFunFact>
    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
