<script setup>
import {defineModel, defineProps} from "vue";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {useUserNotificationsStore} from "@/stores/userNotificationStore.js";
import {extractChapterRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {AppContent} from "@/content/data/content.js";
import Title from "@/components/content/ContentTitle.vue";
import TextBox from "@/components/content/ContentTextbox.vue";
import InstrumentList from "@/components/content/music/PlayableInstrumentsList.vue";
import AriaPlayer from "@/components/content/music/AriaPlayer.vue";
import NextChapterCard from "@/components/content/ContentNextChapterCard.vue";
import FunFact from "@/components/content/ContentFunFact.vue";
import FunfactParagraph from "@/components/content/ContentFunfactParagraph.vue";
import Word from "@/components/glossary/GlossaryWordTooltip.vue";

const props = defineProps({
    chapterIndex : {type : Number, required : true}
})

const UserProgression = useUserProgressionStore()
const UserNotifications = useUserNotificationsStore()

const readChapters = defineModel()

const routeInfo = extractChapterRouteInfo()

const operaIndex = routeInfo.operaInfo.index
const levelIndex = routeInfo.levelInfo.index;
const chapterIndex = routeInfo.chapterInfo.index;

const currentChapter = AppContent.operas[operaIndex].levels[levelIndex].chapters[chapterIndex];

function setAriaPlayed(ariaId){
    UserProgression.setAriaAsListened(ariaId)
    if (!UserProgression.isAchievementUnlocked(13)
        && UserProgression.allAriasListened(operaIndex, levelIndex, chapterIndex)) {
        UserProgression.unlockAchievementById(13);
        UserNotifications.pushNotification("achievement", 13);
    }
}
</script>

<template>
    <Title>Quali sono i principali strumenti musicali della <i>Cenerentola</i> ?</Title>
    <TextBox>Tocca gli strumenti per scoprirne il suono!</TextBox>
    <InstrumentList :instruments="currentChapter.instruments" :theme="AppContent.operas[operaIndex].theme"/>
    <Title>Ascolta alcuni dei brani più iconici della <i>Cenerentola </i>!</Title>
    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Cavatina_Dandini_Ape.mp3" @play="setAriaPlayed(7)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark">Cavatina di Dandini</h3>
            <p class="px-8 text-grey-dark">Come un'ape ne' giorni d'aprile</p>
        </template>
        <template #description>
            <TextBox>
                Con questa <Word :word-id="79">cavatina</Word>, Dandini, travestito da principe, si presenta paragonando sé stesso a un’ape
                che vola di fiore in fiore, sottolineando il suo fascino e il desiderio di corteggiare tutte le belle
                fanciulle che incontra, prendendosi gioco di Magnifico e delle sorellastre di Cenerentola.
            </TextBox>
        </template>
    </AriaPlayer>
    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Noi_Don_Magnifico.mp3" @play="setAriaPlayed(8)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark">Da Finale I</h3>
            <p class="px-8 text-grey-dark">Noi Don Magnifico</p>
        </template>
        <template #description>
            <TextBox>
                Don Magnifico ha dimostrato tanta competenza in fatto di vini (del resto è barone di Montefiascone!),
                bevendo per tre ore di seguito senza barcollare, che il finto principe Dandini l’ha nominato cantiniere.
            </TextBox>
            <TextBox>
                Nell’assolo con coro “Noi, Don Magnifico” il personaggio sfoggia la sua spocchia e,
                al tempo stesso, qualche effetto del vino bevuto…
            </TextBox>
        </template>
    </AriaPlayer>
    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Sia_Qualunque_Delle_Figlie.mp3" @play="setAriaPlayed(9)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark">Aria di Magnifico</h3>
            <p class="px-8 text-grey-dark">Sia qualunque delle figlie</p>
        </template>
        <template #description>
            <TextBox>
                Don Magnifico, in questa buffa <Word :word-id="21">aria</Word>, esprime le sue ambizioni e il desiderio di vedere una delle
                sue figlie sposare il principe, chiunque essa sia.
            </TextBox>
            <TextBox>
                Già sogna di avere, in seguito al matrimonio, una posizione di privilegio a palazzo, dimostrando
                una volta di più la sua natura cinica e opportunista.
            </TextBox>
        </template>
    </AriaPlayer>
    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/S%C3%AC_ritrovarla_io_giuro.mp3" @play="setAriaPlayed(10)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark">Aria di Ramiro</h3>
            <p class="px-8 text-grey-dark">Sì, ritrovarla io giuro</p>
        </template>
        <template #description>
            <TextBox>
                Il principe Ramiro, dopo aver visto Cenerentola alla festa, giura di ritrovarla a tutti i costi.
            </TextBox>
            <TextBox>
                In quest’<Word :word-id="21">aria</Word> manifesta la sua determinazione e il suo amore sincero.
            </TextBox>
            <TextBox>
                È deciso a seguire le tracce della fanciulla di cui si è innamorato, nonostante la sua misteriosa fuga.
            </TextBox>
        </template>
    </AriaPlayer>
    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Questo_un_nodo.mp3" @play="setAriaPlayed(11)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark">Da Sestetto</h3>
            <p class="px-8 text-grey-dark">Questo è un nodo avviluppato</p>
        </template>
        <template #description>
            <TextBox>
                Siamo in un momento di arresto dell’azione in cui tutti i personaggi, sbalorditi e frastornati in
                seguito al riconoscimento tra don Ramiro e Cenerentola, manifestano il loro turbamento
                e si chiedono come andrà a finire.
            </TextBox>
        </template>
    </AriaPlayer>
    <AriaPlayer src="https://io-bunny-cdn.b-cdn.net/ROF/arias/Nacqui_dall_affanno.mp3" @play="setAriaPlayed(12)">
        <template #player>
            <h3 class="font-medium text-2xl leading-none text-grey-dark">Rondò di Cenerentola</h3>
            <p class="px-8 text-grey-dark">Nacqui all'affanno, e al pianto</p>
        </template>
        <template #description>
            <TextBox>
                Nel momento di festeggiare il matrimonio col principe Ramiro, Cenerentola ripensa alla
                sua vita precedente, segnata dalla sofferenza e dalle umiliazioni.
            </TextBox>
            <TextBox>
                Tuttavia, nel corso di questa lunga <Word :word-id="21">aria</Word> (convenzionalmente chiamata
                <Word :word-id="84">Rondò</Word>), non può trattenere
                la felicità per il destino che l'ha portata inaspettatamente al trono.
            </TextBox>
            <TextBox>
                La sua dolcezza e il suo perdono nei confronti delle sorellastre sottolineano la sua nobiltà d'animo.
            </TextBox>
        </template>
    </AriaPlayer>
    <FunFact>
        <template #title>Rossini si avvalse di un collaboratore per musicare la Cenerentola.</template>
        <template #funcfact-p>
            <FunfactParagraph>
                <template #image>
                    <img src="/assets/operas/cenerentola/level-0/funfact-image-2-O2L0C2.png" alt="fun-fact related image"/>
                </template>
                <template #image-description>
                    <p>
                        Come in altri casi, anche per La Cenerentola <Word :word-id="3" theme="white">Rossini</Word> si avvalse di un collaboratore;
                        il librettista <Word :word-id="25" theme="white">Ferretti</Word> ci rivela il nome:
                        <Word :word-id="83" theme="white">Luca Agolini</Word>, detto “Luchetto lo Zoppo”.
                    </p>
                    <p>
                        <Word :word-id="83" theme="white">Agolini</Word> fu incaricato di comporre tre brani in
                        occasione della prima: l’<Word :word-id="21" theme="white">Aria</Word> di Alidoro
                        nel primo <Word :word-id="1" theme="white">atto</Word>, “Vasto teatro è il mondo”, il
                        Coro dei Cavalieri nel secondo atto, “Ah, della bella incognita”,
                        e l’<Word :word-id="21" theme="white">Aria</Word> di Clorinda, poco prima del Finale Secondo,
                        “Sventurata! mi credea”.
                    </p>
                </template>
            </FunfactParagraph>
            <TextBox>
                L’<Word :word-id="21" theme="white">Aria</Word> di Alidoro fu sostituita
                qualche anno dopo da <Word :word-id="3" theme="white">Rossini</Word> con un brano più impegnativo
                (“Là del ciel nell’arcano profondo”), ed è questa che oggi si esegue.
            </TextBox>
            <TextBox>
                Gli altri brani spesso non si ascoltano ma è un errore: anche se non sono di <Word :word-id="3" theme="white">Rossini</Word>,
                fanno infatti parte della drammaturgia dell’<Word :word-id="2" theme="white">opera</Word> voluta da lui stesso e dal librettista.
            </TextBox>
        </template>
    </FunFact>
    <NextChapterCard v-model="readChapters" :chapter-index="chapterIndex"/>
</template>

<style scoped>

</style>
