<script setup>
import ChapterCard from "@/components/chapters/ChapterCard.vue";
import IconStory from "@/components/icons/IconStory.vue";
import IconMusic from "@/components/icons/IconMusic.vue";
import IconCharacters from "@/components/icons/IconCharacters.vue";
import IconMinigame from "@/components/icons/IconMinigame.vue";
import {ref} from "vue";
import {extractLevelRouteInfo} from "@/libraries/routes/RoutesUtilities.js";
import {useUserProgressionStore} from "@/stores/userProgressionStore.js";
import {AppContent} from "@/content/data/content.js";

const icons = {
    storia: IconStory,
    personaggi: IconCharacters,
    musica: IconMusic,
    minigame: IconMinigame,
};

const UserProgression = useUserProgressionStore();

const levelRouteInfo = extractLevelRouteInfo()

const operaId = levelRouteInfo.operaInfo.id
const levelId = levelRouteInfo.levelInfo.id

const operaIndex = levelRouteInfo.operaInfo.index;
const levelIndex = levelRouteInfo.levelInfo.index;

const currentLevel = ref(AppContent.operas[operaIndex].levels[levelIndex]);

const getChapterProgression = (chapterIndex) => {
    return UserProgression.chapterProgression(operaIndex, levelIndex, chapterIndex)
}

const iconVariant = (chapterIndex) => {
    return UserProgression.isLevelMastered(operaIndex, levelIndex)
        ? "mastered"
        : getChapterProgression(chapterIndex).state
}


const chapterLink = (chapter) => `/app/${operaId}/${levelId}/${chapter.prettyName}`

</script>

<template>
    <ul>
        <li v-for="(item, index) in currentLevel.chapters" :key="index + getChapterProgression(index).state">
            <ChapterCard
                :disabled="getChapterProgression(index).state === 'locked'"
                :text="item.title"
                :to="chapterLink(item)"
                :theme="AppContent.operas[operaIndex].theme">
                <template #icon>
                    <component
                        :is="icons[item.prettyName]"
                        :variant="iconVariant(index)"
                        :theme="AppContent.operas[operaIndex].theme"
                        class="w-full h-full"
                    />
                </template>
            </ChapterCard>
        </li>
    </ul>
</template>

<style scoped>
li {
    @apply mb-4;
}
</style>
